import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { v4 as uuidv4 } from 'uuid';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import resellerFields from './reseller.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, isFieldValid, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { bytesToSize, isValidFileSize, trimObj } from 'src/Utils';
import FileUpload from 'src/components/common/fileUpload';
import axios from 'axios';
import ServerlessService from 'src/Service/ServerlessService';
import DirectFileUpload from '../common/directFileUpload';
import { CLOUDFRONT_ENDPOINT } from 'src/Service/config';


class CreateReseller extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(resellerFields, this.props.reseller ? {
            email: 'test@gmail.com',
            password: 'Send@1234',
            passwordConfirm: 'Send@1234',

            ...this.props.reseller
        } : {});
        this.state = {
            reseller: this.formFields.data,
            formValidations: this.formFields.formValidations,
            uploadedFilesInfo: this.props.reseller ? this.props.reseller.attachments || [] : []
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    isAdditionalValid = () => {

        let reseller = JSON.parse(JSON.stringify(this.state.reseller));
        let { formValidations } = this.state;
        // password and confirm password 
        let isValid = true;
        if (reseller.password != reseller.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }


    createReseller = async () => {
        let resellerData = trimObj(this.state.reseller);
        const formStatus = isFormValid(resellerFields, this.formFields.formValidations, resellerData);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }




        if (isCompleteFormValid) {


            if (this.state.uploadedFilesInfo && this.state.uploadedFilesInfo.length) {
                if (this.state.uploadedFilesInfo.some(a => !isValidFileSize(a.size))) {
                    this.toast.show({ severity: 'error', summary: 'Invalid Attachments', detail: 'Attachments can not be more than 20MB. Please remove', life: 3000 });
                    return;
                } else {

                }

            }


            let url = `/organization/save`
            if (this.props.reseller) {
                url = `/organization/update`
            }

            // console.log(resellerData, 'updatedata')


            this.setState({
                isLoading: true,
            });


            this.serverlessService.post(url, resellerData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.reseller ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.reseller ? 'Update' : 'Save'} Reseller`} className='sp-m-lr10'
                onClick={this.createReseller}
            />
        </div>)
    }



    _removefile = (fileInf) => {
        let uploadedFiles = [];
        this.state.uploadedFilesInfo.forEach((fileInfo) => {
            if (fileInfo.name !== fileInf.name) {
                uploadedFiles = [...uploadedFiles, fileInfo];
            }
        });

        this.setState({
            uploadedFilesInfo: uploadedFiles
        });
    }

    showAttachment = (attachment) => {
        if (attachment.path) {
            window.open(attachment.path);
        } else {
            let src = URL.createObjectURL(attachment);
            window.open(src);
        }
    }

    _onLogoRectSuccess = (fileName, data) => {
        this.addAttachment('logoRectangle', fileName, data);
        return
    }

    _onLogoSquareSuccess = (fileName, data) => {
        this.addAttachment('logoSquare', fileName, data);
        return;

    }
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    addAttachment = (fieldName, fileName, data) => {
        let reseller = {
            ...this.state.reseller,
            [fieldName]: CLOUDFRONT_ENDPOINT + data.Key,
        }
        let formValidations = isFieldValid(resellerFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, reseller);


        this.setState((prevState) => {
            return {
                reseller,
                formValidations
            }
        });
    }

    filesUploadedBindingInfo = (filesInfo = []) => {
        let temp;
        temp = filesInfo.map((fileInfo) => {
            return <div key={fileInfo.name}>
                <div className="p-grid upload" dir="ltr">

                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <div style={{ width: '30px', float: 'right' }}>
                            <span className="btn-remove-file sp-float-right"
                                onClick={() => this._removefile(fileInfo)}><i className='pi pi-times' /></span>

                        </div>
                        <div style={{ marginRight: '30px' }} >
                            <p onClick={() => { this.showAttachment(fileInfo) }} className={`filename ${isValidFileSize(fileInfo.size) ? 'valid-file' : "invalid-file"}`}>{fileInfo.name} <span className='sp-right'> {bytesToSize(fileInfo.size)}</span></p>
                        </div>
                    </div>


                </div>
            </div>
        });
        return temp;
    }

    _afterFilesUploaded = (files) => {
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
            }));
        }
    }



    render() {
        const { reseller, formValidations } = this.state;

        // console.log(this.state, 'state-appointment')
        return (<>
            <Dialog
                header={`${this.props.reseller ? 'Edit' : 'Create'} Reseller`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={false}
                dismissableMask={false}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Organization Name<span className='sp-required'>*</span></p>
                    <InputText className='sp-w100p p-inputtext-style1'
                        autoComplete="off" value={reseller.orgName}
                        onChange={(e) => { onTextChange(e.target.value, 'orgName', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['orgName'].isValid && <p className="p-error">{formValidations.fields['orgName'].errorMsg}</p>}
                    {
                        !this.props.reseller && <>
                            <p className='sp-label-s1 sp-mt10 sp-no-pm'>Email<span className='sp-required'>*</span></p>
                            <InputText value={reseller.email}
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onEmailChange(e.target.value, 'email', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                            {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                            <p className='sp-label-s1 sp-mt10 sp-no-pm'>Password<span className='sp-required'>*</span></p>
                            <InputText value={reseller.password}
                                type="password"
                                autoComplete="new-password"
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'password', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                            {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                            <p className='sp-label-s1 sp-mt10 sp-no-pm'>Confirm Password<span className='sp-required'>*</span></p>
                            <InputText value={reseller.passwordConfirm}
                                type="password"
                                autoComplete="new-password"
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'passwordConfirm', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                            {formValidations && !formValidations.fields['passwordConfirm'].isValid && <p className="p-error">{formValidations.fields['passwordConfirm'].errorMsg}</p>}
                        </>
                    }
                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Your name<span className='sp-required'>*</span></p>
                    <InputText value={reseller.name}
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'name', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}

                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Sub Domain<span className='sp-required'>*</span></p>
                    <InputText value={reseller.subDomain}
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'subDomain', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['subDomain'].isValid && <p className="p-error">{formValidations.fields['subDomain'].errorMsg}</p>}

                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Domain<span className='sp-required'>*</span></p>
                    <InputText value={reseller.domain}
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'domain', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['domain'].isValid && <p className="p-error">{formValidations.fields['domain'].errorMsg}</p>}

                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Contact Number 1<span className='sp-required'>*</span></p>
                    <InputText value={reseller.contactNumber1}
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onNumberChange(e.target.value, 'contactNumber1', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['contactNumber1'].isValid && <p className="p-error">{formValidations.fields['contactNumber1'].errorMsg}</p>}
                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Contact Number 2<span className='sp-required'>*</span></p>
                    <InputText value={reseller.contactNumber2}
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onNumberChange(e.target.value, 'contactNumber2', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['contactNumber2'].isValid && <p className="p-error">{formValidations.fields['contactNumber2'].errorMsg}</p>}

                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Contact Email<span className='sp-required'>*</span></p>
                    <InputText value={reseller.contactEmail}
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onEmailChange(e.target.value, 'contactEmail', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['contactEmail'].isValid && <p className="p-error">{formValidations.fields['contactEmail'].errorMsg}</p>}



                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Address<span className='sp-required'>*</span></p>
                    <InputText value={reseller.address}
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'address', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['address'].isValid && <p className="p-error">{formValidations.fields['address'].errorMsg}</p>}


                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>City<span className='sp-required'>*</span></p>
                    <InputText value={reseller.city}
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'city', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['city'].isValid && <p className="p-error">{formValidations.fields['city'].errorMsg}</p>}


                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>State<span className='sp-required'>*</span></p>
                    <InputText value={reseller.state}
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'state', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['state'].isValid && <p className="p-error">{formValidations.fields['state'].errorMsg}</p>}

                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Country<span className='sp-required'>*</span></p>
                    <InputText value={reseller.country}
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'country', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations') }} />
                    {formValidations && !formValidations.fields['country'].isValid && <p className="p-error">{formValidations.fields['country'].errorMsg}</p>}

                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Pincode<span className='sp-required'>*</span></p>
                    <InputText className='p-inputtext-style1 ma-w100p'
                        value={reseller.pincode}
                        onChange={(e) => {
                            onNumberChange(e.target.value, 'pincode', this, resellerFields, reseller, formValidations, 'reseller', 'formValidations')
                        }}
                    />
                    {formValidations && !formValidations.fields['pincode'].isValid && <p className="p-error">{formValidations.fields['pincode'].errorMsg}</p>}

                    {/* <p className='sp-label-s1 sp-mt10 sp-no-pm'>Logo Rectangle<span className='sp-required'>*</span></p>
                    <div className='custom-input2 file-attach'>
                        <DirectFileUpload id={"logorect"}
                            multiple={false}
                            onProgress={this._onProgress}
                            onSucess={this._onLogoRectSuccess}
                            //  Reset={this.state.Reset}
                            maxFileSize={307200}
                            onError={this._onFilsUploadError}
                            accept={'images'}
                            title={'Images'}
                            afterFilesUploaded={this._afterFilesUploaded}
                        >
                            <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                        </DirectFileUpload>
                        {
                            reseller.logoRectangle && <img src={reseller.logoRectangle}
                                className="rect-logo" />
                        }
                    </div>
                    <p className='field-alert'>(*Only JPEG/PNG formats are allowed with size below 300KB)</p>
                    {formValidations && !formValidations.fields['logoRectangle'].isValid && <p className="p-error">{formValidations.fields['logoRectangle'].errorMsg}</p>}
 */}

                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Logo<span className='sp-required'>*</span></p>
                    <div className='custom-input2 file-attach'>
                        <DirectFileUpload id={"logosquare"}
                            multiple={false}
                            onProgress={this._onProgress}
                            onSucess={this._onLogoSquareSuccess}
                            //  Reset={this.state.Reset}
                            maxFileSize={307200}
                            onError={this._onFilsUploadError}
                            accept={'images'}
                            title={'Images'}
                            afterFilesUploaded={this._afterFilesUploaded}
                        >
                            <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                        </DirectFileUpload>
                        {
                            reseller.logoSquare && <img src={reseller.logoSquare}
                                className="rect-logo" />
                        }
                    </div>
                    <p className='field-alert'>(*Only JPEG/PNG formats are allowed with size below 300KB)</p>
                    {formValidations && !formValidations.fields['logoSquare'].isValid && <p className="p-error">{formValidations.fields['logoSquare'].errorMsg}</p>}
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateReseller)));
