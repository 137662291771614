import { ACTIONS } from './../actionTypes';

const initialState = {
    isLoading: false,
    data: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_PERMISSIONS_REQUESTED:
            return {
                ...state,
                isLoading: true
            };

        case ACTIONS.GET_PERMISSIONS_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                data: action.data
            };

        case ACTIONS.GET_PERMISSIONS_FAILED:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};
