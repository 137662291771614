import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Authentication from '../session/index'
import { TabView, TabPanel } from 'primereact/tabview';
import './styles.scss'
import PatientLogin from './patientLogin';




class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logindata: {

            },
            activeTab: 1,
        }

    }




    render() {
        //  console.log(this.props, 'ty')
        return (<>
            <div className='login-form'>
                <h3 className='login-title sp-txt-center sp-pointer'>
                    <Link to="/" style={{ color: 'inherit' }}>{this.props.orgName}</Link>

                </h3>
                <PatientLogin />
            </div>
        </>)
    }
}








const mapStateToProps = state => ({

    orgName: state?.orgData?.orgName,
});


export default connect(mapStateToProps, {
})(Authentication(withRouter(Login)));
