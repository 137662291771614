import React, { Component } from 'react';
import { Menubar } from 'primereact/menubar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import Authentication from './../../session/index'
import { TieredMenu } from 'primereact/tieredmenu';
import LoadingComponent from 'src/LoadingComponent';
import './styles.scss'
import ServerlessService from 'src/Service/ServerlessService';
import { Link } from "react-router-dom";
import { SERVERLESS_URL } from 'src/Service/config';
import axios from 'axios';
import { saveUserSession } from 'src/store/actions';


const getThemes = () => {
    if (localStorage.getItem('themes')) {
        return JSON.parse(localStorage.getItem('themes'));
    } else {
        return [
            {
                name: "Light Blue",
                value: null,
                isSelected: true
            },
            {
                name: "Pink",
                value: 'pink',
                isSelected: false
            },
            {
                name: "Green",
                value: 'green',
                isSelected: false
            },
            {
                name: "Yellow",
                value: 'yellow',
                isSelected: false
            },
        ]
    }
}


class ClientHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            themes: getThemes(),
            isShowChangeRole: false,
            total: 0,
            credits: null
        }

        this.serverlessService = new ServerlessService();
    }




    onLogout() {
        localStorage.clear();
        this.props.history.push('/')
        window.location.reload();

    }



    getNavbarModules = () => {
        let ModuleMenuItems = [{
            label: 'Portal',
            className: `${(window.location.pathname == '/portal') ? 'sp-menu-active' : 'ddd'}`,
            command: () => {
                this.props.history.push('/portal')
            },
        },
        {
            label: 'Features',
            // className: `${(window.location.pathname == '/admin') ? 'sp-menu-active' : 'ddd'}`,
            items: [

                {
                    label: 'SMS',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/sms')
                    },
                    //  isPermRequired: true,
                    //   uId: '0761fb3e-2f31-45e5-a431-c3d2a745d1a3'
                    // icon: 'pi pi-fw pi-align-left'
                }
                ,
                {
                    label: 'Voice',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/voice')
                    },
                    //  isPermRequired: true,
                    //   uId: '0761fb3e-2f31-45e5-a431-c3d2a745d1a3'
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Email',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/email')
                    },
                    //     isPermRequired: true,
                    //      uId: 'c54bee0c-bf05-4739-b88c-eff7a1850008'
                    // icon: 'pi pi-fw pi-align-left'
                }]
            //isPermRequired: true,
            //uId: '8661adca-b5c5-49e0-abe1-67ea1f6839c4',
            // icon: 'pi pi-fw pi-align-left'

            // {
            //     label: 'Configuration',
            //     // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
            //     command: () => {
            //         this.props.history.push('/config')
            //     },
            //     isPermRequired: true,
            //     uId: 'a7ee7332-791a-4fd5-b457-1226b40186b3',
            //     // icon: 'pi pi-fw pi-align-left'
            // }

        },
        {
            label: 'Pricing',
            // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
            command: () => {
                this.props.history.push('/pricing')
            },
        },
        {
            label: 'About',
            // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
            command: () => {
                this.props.history.push('/about')
            },
        },
        {
            label: 'Contact',
            // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
            command: () => {
                this.props.history.push('/contact')
            },
        }
        ];

        let assignedM = [];
        // if (this.props.authenticated) {
        //     assignedM = ModuleMenuItems
        // }


        //  if (this.props?.user?.user?.isAdmin) {
        return ModuleMenuItems;
        // }

        // if (this.props.permissionIds && this.props.permissionIds.length) {


        //     assignedM = ModuleMenuItems.filter(item => {

        //         if (item.isPermRequired) {
        //             // 
        //             if (this.props.permissionIds.includes(item.uId)) {
        //                 if (item.items) {
        //                     item.items = item.items.filter(subItem => {
        //                         if (subItem.isPermRequired) {
        //                             //
        //                             if (subItem.items && subItem.items.length) {
        //                                 subItem.items = subItem.items.filter(subItem2 => {
        //                                     if (subItem2.isPermRequired) {
        //                                         if (this.props.permissionIds.includes(subItem2.uId)) {
        //                                             return subItem2;
        //                                         }
        //                                     } else {
        //                                         return subItem2;
        //                                     }
        //                                 });
        //                             }
        //                             return subItem;
        //                         } else {
        //                             if (subItem.items && subItem.items.length) {
        //                                 subItem.items = subItem.items.filter(subItem2 => {
        //                                     if (subItem2.isPermRequired) {
        //                                         if (this.props.permissionIds.includes(subItem2.uId)) {
        //                                             return subItem2;
        //                                         }
        //                                     } else {
        //                                         return subItem2;
        //                                     }
        //                                 });

        //                             }
        //                             return subItem;
        //                         }
        //                     });
        //                 }

        //                 if (item && item.items && item.items.length) {
        //                     return item
        //                 }
        //             }
        //         } else {
        //             if (item.items) {
        //                 item.items = item.items.filter(subItem => {
        //                     if (subItem.isPermRequired) {
        //                         if (this.props.permissionIds.includes(subItem.uId)) {
        //                             if (subItem.items && subItem.items.length) {
        //                                 subItem.items = subItem.items.filter(subItem2 => {
        //                                     if (subItem2.isPermRequired) {
        //                                         if (this.props.permissionIds.includes(subItem2.uId)) {
        //                                             return subItem2;
        //                                         }
        //                                     } else {
        //                                         return subItem2;
        //                                     }
        //                                 });
        //                             }
        //                             return subItem;
        //                         }
        //                     } else {
        //                         if (subItem.items && subItem.items.length) {
        //                             subItem.items = subItem.items.filter(subItem2 => {
        //                                 if (subItem2.isPermRequired) {
        //                                     if (this.props.permissionIds.includes(subItem2.uId)) {
        //                                         return subItem2;
        //                                     }
        //                                 } else {
        //                                     return subItem2;
        //                                 }
        //                             });

        //                         }
        //                         return subItem;
        //                     }
        //                 });
        //             } else {
        //                 return item;
        //             }
        //             if (item && item.items && item.items.length) {
        //                 return item
        //             }
        //         }
        //     });
        // }


        // assignedM = assignedM.filter(i => {
        //     if (i && i.items) {
        //         if (i.items.length) {
        //             i.items = i.items.filter(j => {
        //                 if (j && j.items) {
        //                     if (j.items.length) {
        //                         j.items = j.items.filter(k => {
        //                             if (k && k.items) {
        //                                 if (k.items.length) {
        //                                     return k;
        //                                 }
        //                             } else {
        //                                 if (k.command) {
        //                                     return k;
        //                                 }
        //                             }
        //                         })
        //                         if (j.items.length) {
        //                             return j;
        //                         }
        //                     }
        //                 } else {
        //                     if (j.command) {
        //                         return j;
        //                     }
        //                 }
        //             })
        //             if (i.items.length) {
        //                 return i;
        //             }
        //         }
        //     } else {
        //         if (i.command) {
        //             return i;
        //         }
        //     }
        // })



        // return assignedM;


    }

    changeTheme = (selectedTheme) => {

        const themes = this.state.themes.map((theme) => {
            if (theme.value == selectedTheme.value) {
                theme.isSelected = true;
            } else {
                theme.isSelected = false;

            }
            return theme;
        })
        this.setState({
            themes
        });



        localStorage.setItem('themes', JSON.stringify(themes));
        localStorage.setItem('data-theme', selectedTheme.value);
        document.documentElement.setAttribute('data-theme', localStorage.getItem('data-theme'));
        //  window.location.reload();
    }


    initiateGetSession = () => {
        let userType = localStorage.getItem('userType');


        if (userType) {


            const url = `${SERVERLESS_URL}/admin/session`;

            let config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
                }
            };
            axios.get(url, config)
                .then(res => {
                    if (res && res.data) {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.saveUserSession(res.data);
                        })

                    } else {
                        //   this.props.sessionsChecked();
                        this.setState({
                            isLoading: false
                        })
                    }
                }).catch((err) => {
                    this.setState({
                        isLoading: false
                    })


                });
        } else {
            //    this.props.sessionsChecked();
        }
    }
    componentDidMount() {

        // socket.on("smsLimitUpdated", (data) => {
        //     console.log(data)

        //     this.setState({
        //         total: data.smsLimit
        //     })
        // });

        //this.getCredits()


        if (!this.props.authenticated) {

            this.initiateGetSession();

            // retrieveUserFromLocalStorage(this.props.dispatch)
            //     .then((res) => {
            //         if (res) {
            //             this.props.authUser(res);
            //             this.props.getUserData();
            //         }
            //     })
            //     .catch((e) => {
            //         this.props.history.push('/login');
            //     });
        }

    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.authenticated !== this.props.authenticated) {
    //         if (!this.props.authenticated) {
    //             this.initiateGetSession();
    //         }
    //     }
    // }






    getThemes = () => {
        const { themes } = this.state;
        return <ul className='sp-p10'>{themes.map(theme => {
            return <li key={theme.name} className='sp-m10' >
                <RadioButton className="radio-inline" value={theme.value} inputId={theme.value} name="theme"
                    onChange={() => {
                        this.changeTheme(theme)
                    }}
                    checked={theme.isSelected}
                />
                <label className='sp-label-s1' htmlFor={theme.value}>{theme.name}</label>
            </li>
        })}  </ul>


    }



    render() {
        // console.log(this.props.pricings, 'price')
        const items = [
            // {
            //     label: 'Theme',
            //     icon: 'pi pi-slack',
            //     items: [
            //         {
            //             template: <>{this.getThemes()}</>
            //         }
            //     ]
            // },

            // {
            //     separator: true
            // },
            {
                label: 'Log Out',
                icon: 'pi pi-fw pi-power-off',
                command: () => { this.onLogout() }
            }
        ];

        //   console.log(this.props, 'user')
        return <div>
            <div className="sp-menubar-w sticky">

                <div className="p-grid sp-menubar container">
                    <div className="sp-nav-bar" >
                        {this.props.logoVisible && this.props.logoSquare && <img src={this.props.logoSquare} onClick={() => { this.props.history.push('/') }} alt="no"
                            class={`off-logo ${this.props.logoSize == 'large' ? 'off-logo-large' : ''}`} />
                        }
                        <h2 className={`app-title ${this.props.logoSize == 'large' ? 'app-title-large' : ''}`} onClick={() => { this.props.history.push('/') }} >
                            {this.props.brandNameVisible && this.props.orgName} </h2>

                        <Menubar model={this.getNavbarModules()}
                            className="m-menu-bar "
                        />
                        {
                            this.props.authenticated ? <>
                                <div className=" sp-right ">
                                    <div style={{ width: '40px', float: 'right' }}>
                                        <img src={this.props?.user?.user?.photo} style={{ height: '34px', borderRadius: '50%' }} />
                                    </div>
                                    <div style={{ marginRight: '50px' }} onClick={(event) => this.menu.toggle(event)}>
                                        <p style={{ textAlign: 'right' }} className='sp-profile'>
                                            {/* <span>SMS Credits: {this.props?.user?.user?.smsLimit} </span>
                                            <span>Email Credits:  {this.props?.user?.user?.emailLimit} </span> */}
                                            {/* {this.props?.user?.user?.orgName} -  */}
                                            {this.props?.user?.user?.name}</p>
                                    </div>
                                </div>
                            </> : <>
                                <div className='login-button-w'>
                                    <div class="login-button">
                                        <button type="button" class="btn-c btn-dark" onClick={() => { this.props.history.push('/login') }} >Register</button>
                                        <button type="button" class="btn-c btn-light" onClick={() => { this.props.history.push('/login') }}>Login</button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>

                <TieredMenu model={items} popup ref={el => this.menu = el} />

                {
                    this.props.isLoading && <LoadingComponent />
                }



            </div>
            <div className='body-container'>
                {this.props.children}
                {/* {React.cloneElement(this.props.children, { ...this.props })} */}
            </div>
            <footer className="text-center">
                <section className="">
                    <div className="container  text-md-start sp-pt10">
                        <div className="p-grid mt-3">
                            <div className="p-col-12 p-sm-6 p-md-3 p-lg-4 p-xl-4 mx-auto mb-4">
                                <h6 className="text-uppercase mb-4">
                                    <i className="fas fa-gem me-3"></i>{this.props.orgName}
                                </h6>
                                <p className='ss-txt'>
                                    {this.props.orgName} is a Bulk SMS/Email service provider that hepls you in communicating to massive sms,mails
                                    and
                                    voice sms.
                                </p>
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2 mx-auto mb-4">

                                <h6 className="text-uppercase fw-bold mb-4">
                                    Features
                                </h6>
                                <p>
                                    <Link to="/sms" className="text-reset">SMS Services</Link>
                                </p>
                                <p>
                                    <Link to="/voice" className="text-reset">Voice Services</Link>
                                </p>

                                <p>
                                    <Link to="/email" className="text-reset">Email Services</Link>
                                </p>

                            </div>

                            <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Useful links
                                </h6>
                                <p>
                                    <Link to="/pricing" className="text-reset">Pricing</Link>
                                </p>
                                {/* <p>
              <a href="./where-to-start.html" className="text-reset">Where to start</a> 
            </p> */}
                                <p>
                                    <Link to="/about" className="text-reset">About Us</Link>
                                </p>
                                <p>
                                    <Link to="/contact" className="text-reset">Contact Us</Link>
                                </p>
                                <p>
                                    <Link to="/terms-condition" className="text-reset">Terms and Conditions</Link>
                                </p>
                                <p>
                                    <Link to="/privacy-policy" className="text-reset">Privacy Policy</Link>
                                </p>
                                <p>
                                    <Link to="/trai-guidelines" className="text-reset">DLT & TRAI Rules</Link>
                                </p>
                                <p>
                                    <Link to="/case-studies" className="text-reset">Case studies</Link>
                                </p>
                                <p>
                                    <Link to="/faqs" className="text-reset">FAQs</Link>
                                </p>
                            </div>
                            <div className="p-col-12  p-sm-6 p-md-4 p-lg-3 p-xl-4 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                                <p className='sp-c-address'><i className="fa fa-home me-3 "></i>
                                    {' ' + this.props.address + ', ' + this.props.city + ', ' + this.props.state + ', ' + this.props.pincode + ", India"}

                                </p>
                                <p>
                                    <i className="fa fa-envelope me-3"></i>
                                    {' ' + this.props.contactEmail}
                                </p>
                                <p><i className="fa fa-phone me-3"></i> +91-{this.props.contactNumber1}</p>
                                <p><i className="fa fa-phone me-3"></i> +91-{this.props.contactNumber2}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="text-center footer-link" >
                    © 2023 Copyright:
                    <Link to="/#" >{window.location.hostname}</Link>
                </div>
            </footer>
        </div>
    }

}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    permissionIds: state?.user?.user?.permissionIds || [],
    logoSquare: state?.orgData?.logoSquare,
    orgName: state?.orgData?.orgName,
    isLoading: state?.orgData?.isLoading,
    isSuperOrg: state?.orgData?.isSuper,
    pricings: state?.orgData?.pricings,
    contactNumber1: state?.orgData?.contactNumber1,
    contactNumber2: state?.orgData?.contactNumber2,
    contactEmail: state?.orgData?.contactEmail,
    address: state?.orgData?.address,
    city: state?.orgData?.city,
    state: state?.orgData?.state,
    pincode: state?.orgData?.pincode,
    brandNameVisible: state?.orgData?.brandNameVisible,
    logoVisible: state?.orgData?.logoVisible,
    theme: state?.orgData?.theme,
    logoSize: state?.orgData?.logoSize,
});


export default connect(mapStateToProps, {
    saveUserSession,
})(withRouter(ClientHeader));

