import React, { Component } from 'react'
import Header from 'src/Header'
import SingleSMSAPIPost from './singleSMSAPIPost'
import APIKey from './apiKey'
import { Accordion, AccordionTab } from 'primereact/accordion';
import SendToMultipleAPI from './sendToMultipleAPI';
import GetStatusAPI from './getStatusOfMessage';
import GetSMSCredits from './getSMSCredits'
import Authorize, { PERMISSIONS } from 'src/session/authorize';
import GetTransactionByRef from './getTransactionsByRef';
export default class APIDocumentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAPIIndex: null
        }
    }


    render() {
        return (<Header >
            <div className='sp-main'>
                <Authorize permId={PERMISSIONS.SMS_API_DOC_VIEW_API_KEY} >
                    <APIKey />

                    <div className='sp-mt20'>

                        <Accordion activeIndex={this.state.currentAPIIndex}
                            onTabChange={(e) => this.setState({
                                currentAPIIndex: e.index
                            })}>

                            <AccordionTab header="Send SMS To Single Number">
                                <SingleSMSAPIPost />
                            </AccordionTab>
                            <AccordionTab header="Send SMS To Multiple Numbers">
                                <SendToMultipleAPI />
                            </AccordionTab>
                            <AccordionTab header="Get Delivery Report Of Message">
                                <GetStatusAPI />
                            </AccordionTab>
                            <AccordionTab header="Get SMS Credits">
                                <GetSMSCredits />
                            </AccordionTab>
                            <AccordionTab header="Get SMS Transactions By ID">
                                <GetTransactionByRef />
                            </AccordionTab>
                        </Accordion>



                    </div>

                </Authorize>
            </div>
        </Header >
        )
    }
}
