import React, { Component } from 'react'
import Header from 'src/Header'
import CreditPanel from 'src/HomePage/creditPanel'
import UserCreditsPanel from 'src/HomePage/userCreditsPanel'



export default class Dashboard extends Component {
    render() {
        return (
            <Header>

                <div className='sp-main'>
                    <h1>Welcome To SMS, Voice & Email Campaign</h1>
                    <div className='p-grid'>
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <CreditPanel />
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <UserCreditsPanel />
                        </div>
                    </div>
                </div>
            </Header>
        )
    }
}
