import React from 'react';
import XLSX from 'xlsx';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import LoadingComponent from './../../LoadingComponent';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import './styles.scss'
//import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Dialog } from 'primereact/dialog';
import { BasicLazyParams } from 'src/Utils/constants';
import Service from 'src/Service';

/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

const SampleBulkUplodData = [];

class NewUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            users: [],


            transactionId: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,


        };
        this.service = new Service();
    }


    getBulkUploadTransactionsData = () => {
        //    this.props.getBulkUploadTransactions({ userId: this.props.userId }, { ...this.state.lazyParams, globalSearch: this.state.globalSearch });
    };

    componentDidMount = () => {
        //     this.getBulkUploadTransactionsData();
    };

    componentWillUnmount() {
        //   this.props.clearBulkUserUploadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Failed to upload bulk users data', life: 5000 });
            }
        }

        if (prevProps.bulkUserUploadSaveResponse !== this.props.bulkUserUploadSaveResponse) {
            if (this.props.bulkUserUploadSaveResponse) {
                const transactionId = this.props.bulkUserUploadSaveResponse.transaction_id ? this.props.bulkUserUploadSaveResponse.transaction_id : '';
                this.toast.show({ severity: 'success', summary: 'Process is initiated', detail: `Bulk users upload process initiated, kindly refer the transaction ${transactionId} details in below table`, life: 10000 });
                this.getBulkUploadTransactionsData();
            }
        }
    }

    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }

            const users = newJsonSheet;

            // .map((record) => ({
            //     ...record,
            //     mobile: `+91${record.mobile}`
            // }));

            this.setState({ users });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };
    exportCSV = () => {
        this.dt.exportCSV();
    }
    exportCSV2 = () => {
        this.dt2.exportCSV();
    }

    uploadBulkUsers = () => {


        this.setState({
            isLoading: true,
        });

        let url = '/emailusers/bulkupload'

        this.service.post(url, { users: this.state.users }, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,

                }, () => {
                    this.props.onUpload(res.res.data.transactionId);
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    };

    render() {
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >Excel Sheet Data</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        value={this.state.globalFilter}
                        onChange={(e) => this.setState({
                            globalFilter: e.target.value
                        })} />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter sp-tbl-icon sp-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-fi" /> */}
                        {/* <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt sp-tbl-icon sp-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-sort" /> */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        const header2 = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >Sample Bulk Upload Sheet</h2>
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV2}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        return (<div>
            <div className=''>
                <Button label="Back" className='sp-mlr30'
                    icon="pi pi-arrow-left"
                    onClick={this.props.cancelUpload} />
                <div className="ask-a-doubt sp-main">
                    <p className='spread-title'>Excel Sheet</p>
                    <InputText
                        className='p-inputtext-style1'
                        value={this.state.fileSelected} disabled />
                    {/* <span className='file-attach-name'>{this.state.fileSelected} </span> */}
                    <label htmlFor="file" className='select-file'>Select File</label>
                    <input
                        type="file"
                        className="form-control"
                        id="file"
                        hidden={true}
                        accept={SheetJSFT}
                        onChange={this.handleChange}
                        disabled={this.state.isLoading}
                    />
                    <div className='sp-clearFix'></div>

                    <Button label="Upload data" className='sp-mt10'
                        disabled={!this.state.users.length}
                        onClick={() => {
                            if (this.state.users && this.state.users.length) {
                                this.uploadBulkUsers();
                            }
                        }}
                        icon="pi pi-cloud-upload"
                    />

                    <Button label="Sample Sheet"
                        className='p-button-outlined sp-mt10 sp-ml10 sp-bg-white'
                        disabled={this.state.isLoading}
                        onClick={() => {
                            this.setState({
                                isShowSampleFormat: true
                            })
                        }}
                    //  icon="pi pi-cloud-upload"
                    />
                </div>


                <div className="p-grid sp-toolbar">
                    <div className="card sp-datatable sp-m30">
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.users}
                            dataKey="_id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            header={header}>
                            <Column filterField='Name' field="Name" header="Name" />
                            <Column filterField='Email' field="Email" header="Email" />
                            <Column filterField='Address' field="Address" header="Address" />
                            <Column filterField='PhoneNumber' field="PhoneNumber" header="PhoneNumber" />
                            <Column filterField='Tags' field="Tags" header="Tags" />
                        </DataTable>
                    </div>
                </div>

            </div>

            {this.state.isLoading ? <LoadingComponent /> : null}
            <Toast ref={(el) => this.toast = el} position="bottom-right" />

            <Dialog
                draggable={false}
                closeOnEscape={true}
                className='sp-alert-box'
                blockScroll={true}
                dismissableMask={false}
                closable={true}

                style={{ minHeight: '90vh' }}
                visible={this.state.isShowSampleFormat}
                onHide={() => this.setState({ isShowSampleFormat: false })}>
                <div>
                    <div className="card sp-datatable sp-m30">
                        <DataTable ref={(el) => this.dt2 = el}
                            value={SampleBulkUplodData}
                            dataKey="_id"
                            header={header2}
                            emptyMessage=""
                        >
                            <Column field="Name" header="Name" />
                            <Column field="Email" header="Email" />
                            <Column field="Address" header="Address" />
                            <Column field="PhoneNumber" header="PhoneNumber" />
                            <Column field="Tags" header="Tags" />
                        </DataTable>
                    </div>
                </div>

            </Dialog>
        </div>
        );
    }
}



export default NewUpload
