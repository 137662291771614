import React, { Component } from 'react'
import ClientHeader from './clientHeader'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';



class Email extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <ClientHeader >
                    <div class="sp-container container ">
                        <div class="sp-container-fluid sms-intro">
                            <div class=" sp-plain-card">
                                <div class="col-md-12">
                                    <h2 class="main-heading">{this.props.orgName} Bulk EMail</h2>
                                    <p class="highlight-txt">Instant Delivery</p>
                                    <p class="highlight-txt">Unlimited Validity</p>
                                    <p class="highlight-txt">Reasonable Price</p>
                                    <p class="highlight-txt">Secured APIs</p>
                                    <p class="highlight-txt">Cloud based</p>
                                    <p class="sp-mt20">Let's make much more easier for your effective communication with email</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sp-container container sp-container-fluid sp-p15">

                        <div>
                            <h2 class="main-heading">Bulk Email</h2>
                            <h3 class="main-heading">(Faster, Economic, efficient)</h3>
                            <p class="sp-text1 sp-mt0">Bulk email, or mass email, is a type of email messaging sent to a large group of
                                people all at once.
                                Sending a message to all or some of your subscribers can be a quick and easy way to spread the word
                                about news or time-sensitive updates affecting your business and customers.</p>
                            <p class="sp-text1">Here are some common topics you might include in a your email</p>
                            <ul class="sp-points sp-text1 ">
                                <li>Company announcements</li>
                                <li>Product updates</li>
                                <li>Service outages</li>
                                <li>Terms of service updates</li>
                                <li>Coupons or promotions</li>
                                <li>Other marketing campaigns</li>
                            </ul>

                            <p class="sp-text1 ">Transactional emails are triggered by a specific user’s behavior and sent to just
                                that individual.
                                This could be an abandoned cart email or a receipt sharing the details of their most recent
                                purchase. Transactional emails are one-to-one emails, as they are sent from your business to one
                                sole recipient. Bulk email, on the other hand, is often called one-to-many email as it is sent from
                                your business to many recipients. </p>
                            <p class="sp-text1 ">It’s important to note that bulk email is different from junk mail, which is sent
                                without a
                                recipient’s permission. Mass email is legal as subscribers have opted-in to receive messages from
                                your brand and CAN-SPAM requirements are met. However, due to lack of email targeting and message
                                relevancy, most bulk mail is given another name: graymail. </p>

                            <p class="sp-text1 ">Graymail is email that your recipients have opted-in to receive, but don’t actually
                                want in their
                                inboxes. Graymail isn’t considered spam since your recipients have signed up to hear from your
                                business and your brand is a legitimate sender. But, odds are there will be a divide in how valuable
                                your recipients find your email content to be. For example, Recipient A might be elated to see your
                                business’ monthly newsletter in their inbox, while Recipient B, who subscribed only to get a coupon
                                code, will click delete without even opening it every month.</p>
                            <p class="sp-text1 ">
                                While it can be tempting to send an email blast to your entire database, it’s very rare that all of
                                your subscribers want to hear from you in the same capacity and at the same frequency. Instead of
                                sending messages to everyone in your database, use advanced segmentation to target your messages to
                                specific individuals on your list. This will help ensure only individuals interested in this type of
                                content receive your email, which can help boost engagement, reduce spam complaints, and, most
                                importantly, ensure your audience continues to find value in your communications.
                            </p>
                        </div>
                    </div>
                    <div class="sp-container  our-features sp-container-fluid sp-whitesmoke sp-p15">
                        <div class="sp-container-fluid container">
                            <h2 class="main-heading">Our <b>Best</b> Features</h2>
                            <div class="p-grid">
                                <div class="p-md-6">
                                    <h4 class="feature-title main-heading">AFFORDABLE PRICE</h4>
                                    <p class="sp-text2">Get lowest price for the best services we provide. High services = low price
                                        is a
                                        key-feature.
                                        Find a low price & we will match it.</p>
                                </div>
                                <div class="p-md-6">
                                    <h4 class="feature-title main-heading">RELIABLE SMS DELIVERY</h4>
                                    <p class="sp-text2">Our gateway is build upon dedicated servers joining multiple email
                                        gateways that provide
                                        lighting
                                        fast
                                        email delivery.</p>
                                </div>

                            </div>
                            <div class="p-grid">
                                <div class="p-md-6">
                                    <h4 class="feature-title main-heading">DYNAMIC EMAIL GATEWAY</h4>
                                    <p class="sp-text2">Our server conjoins multiple EMAIL gateways that are dynamically picked
                                        up to send EMAIL.
                                        This
                                        will
                                        help
                                        our server to reduce the load on a single gateway.</p>
                                </div>
                                <div class="p-md-6">
                                    <h4 class="feature-title main-heading">CUSTOMER SUPPORT</h4>
                                    <p class="sp-text2">We are absolutely ready to assist you and supports you all the time to
                                        strive your trouble.
                                    </p>
                                </div>

                            </div>




                        </div>
                    </div>
                    <div class="sp-container container sp-container-fluid sp-p15">
                        <div>

                            <h2 class="main-heading">The Best Practice for Sending Out Bulk Emails</h2>
                            <p class="sp-text1">While bulk emails are an effective and efficient way for sending out content to a
                                mailing list, it
                                can easily make your emails get flagged as spam or your physical mail regarded as junk if not done
                                properly. </p>
                            <p class="sp-text1">Here are some of the best practices to follow to optimize your next bulk email
                                campaign:</p>
                            <ul class="sp-text1">
                                <li>Provide all of your business’s contact information clearly on your material, so your audience
                                    can easily reach you</li>
                                <li>Provide recipients with a clear option to opt in and out of your mailing list</li>
                                <li>Monitor the amount of emails that go unopened, and see how you can improve your content from
                                    there</li>
                                <li>Add a touch of personalization to your emails, such as by including the name of the recipient
                                </li>

                            </ul>
                            <p class="sp-text1">Perhaps the most difficult factor of mass sending emails is the email not getting
                                read by the
                                recipient. Even worse, sometimes bulk emails get sent straight to a recipient’s trash folder. By
                                following these tips, you can create a bulk email campaign that resonates with your mailing list and
                                effectively secures new customers for your business.</p>
                        </div>
                    </div>
                    <div class="sp-container container sp-container-fluid sp-p15 sp-mb50">
                        <h2 class="main-heading">Types of Bulk Email</h2>
                        <p class="sp-text1">You can send emails to your complete email mailing list or target emails to a specific
                            subset of
                            subscribers. When you combine an eye-catching email template and subject line with Mailgun’s email
                            service focused on email delivery for developers, your email marketing campaigns become more
                            effective no matter what kind of email marketing you’re doing.
                        </p>
                        <ul class="sp-text1">
                            <li>Promotional emails are marketing emails designed to attract attention, promote a sale, and build
                                awareness. This email campaign can target both prospects and existing customers in your contact
                                list to inform them about other products and services that are appropriate for them.</li>
                            <li>Email newsletters keep your subscribers informed of what’s happening with your brand. This bulk
                                email should go beyond updates about your offerings. Provide how-to guides and other useful
                                insights that help customers effectively leverage your product or service.</li>
                            <li>Acquisition emails encourage prospects in your email list to convert into actual customers.
                                These email campaigns target readers on your contact list who have expressed interest but
                                haven’t made a purchase yet. You can share special offers and detailed information that helps
                                them see the value in your offering.</li>
                            <li>Retention emails boost customer loyalty and keep them coming back for purchases. Send this bulk
                                email to customers who aren’t opening your emails, with special offers designed to grab their
                                attention.</li>
                        </ul>

                        <p class="sp-text1">When you send emails, you want to make sure they’re delivered and get a response. Email
                            marketing
                            solutions from {this.props.orgName} provide bulk email services that give insight into see how mailbox
                            providers
                            like Google’s gmail are handling your marketing campaigns. It’s as easy to send marketing emails to
                            customers and prospects as it is to send transactional emails in response to a customer’s
                            interaction.
                        </p>
                        <p class="sp-text1 "> As an email service provider, {this.props.orgName} focuses on providing tools that support
                            marketing
                            automation
                            and deliverability. Partner with {this.props.orgName} to ensure your email marketing campaign results in
                            increased email delivery, a higher open rate, and more customers gained and retained.</p>
                    </div>
                </ClientHeader>
            </>
        )
    }
}




const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});


export default connect(mapStateToProps, {
})(withRouter(Email));

