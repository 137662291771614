import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Service from 'src/Service';
import { Button } from 'primereact/button';
import XLSX from 'xlsx';
import { SheetJSFT, make_cols } from 'src/Utils';
import FileUpload from 'src/components/common/fileUpload';
import APIService from 'src/Service/apiService';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import LoadingComponent from 'src/LoadingComponent';
import { CLOUDFRONT_ENDPOINT, LONG_RUNNING_PRESIGNED_URL, S3_PRESIGN_URL } from 'src/Service/config';


export default class UploadCampaignResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            uploadedFilesInfo: []
        }
        this.service = new Service();
        this.apiService = new APIService();

        this.fileUploadRef = React.createRef();
    }


    uploadFilesToS3 = async (files) => {
        let uploadedFiles = [];
        let thisObj = this;
        //  let files = e.target.files;
        for (let i = 0; i < files.length; i++) {



            if (!files[i].status) {


                let { data } = await axios.post(S3_PRESIGN_URL, {
                    "type": "insert",
                    "key": uuidv4() + files[i].name.replace(/\s/g, "")
                });
                let uploadStatus = await axios.put(data.data.presignedURL, files[i], {
                    headers: {
                        "Content-Type": "binary/octet-stream",
                        "Access-Control-Allow-Origin": "*",
                    }
                });
                uploadedFiles.push({
                    path: CLOUDFRONT_ENDPOINT + data.data.contentStorageKey,
                    key: data.data.contentStorageKey,
                    name: files[i].name,
                    type: files[i].type,
                    size: files[i].size,
                    status: uploadStatus.status == 200 ? true : false
                });
            } else {
                uploadedFiles.push(files[i]);
            }
        }

        return uploadedFiles;
        //  console.log(uploadedFiles, 'uploadedFiles')
    }

    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {

            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });
            // console.log(data, 'data-prepared')
            const newJsonSheet = [];
            // for (let i = 0; i < data.length; i++) {
            //     let excelRowData = {
            //         ...data[i],
            //         id_dummy: uuidv4(),
            //         __rowNum__: undefined
            //     };

            //     excelRowData = JSON.parse(JSON.stringify(excelRowData));

            //     newJsonSheet.push(excelRowData);
            // }

            const users = newJsonSheet;



            // .map((record) => ({
            //     ...record,
            //     mobile: `+91${record.mobile}`
            // }));


            let sheetColumns = [];


            if (users && users.length && Object.keys(users[0]).length) {
                Object.keys(users[0]).forEach(key => {
                    if (key != 'id_dummy') {
                        sheetColumns.push(key);
                    }
                })
            }


            this.setState({ users, sheetColumns });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({
                fileSelected: files[0]['name'],
                files: files, users: [], sheetColumn: [], selectedContacts: []
            });
            //  this.handleFile(files[0]);
            e.target.value = null;
        }
    };

    _afterFilesUploaded = (files) => {
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
            }));
        }
    }

    uploadResults = async () => {
        if (!this.state.uploadedFilesInfo || !this.state.uploadedFilesInfo.length) {
            this.toast.show({ severity: 'error', summary: 'Upload file', detail: 'Please upload file', life: 3000 });
            return;

        }

        this.setState({
            isLoading: true,
        });

        let uploadedFiles = await this.uploadFilesToS3(this.state.uploadedFilesInfo);
        let fileUploadRef = this.fileUploadRef;

        //console.log(uploadedFiles, 'a')
        const { campaign } = this.props;
        /// console.log(campaign, 'ss')
        const url = `${LONG_RUNNING_PRESIGNED_URL}/data-sheets/process-airtel-cdr-csv`;

        this.apiService.post(url, {
            campaignId: campaign.campaignId,
            key: uploadedFiles[0].key,
            voiceLength: Number(campaign.voiceLength),
            ivrValues: campaign.ivrValues,
            campaignType: campaign.campaignType,
            ivrMappings: campaign.ivrMappings
        }, true).then((res) => {
            if (res && res.status && res.res.status) {
                fileUploadRef.current.value = null;
                this.setState({
                    isLoading: false,
                    uploadedFilesInfo: [],
                }, this.props.onHide);
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })



    }
    render() {
        const { campaign } = this.props;
        // console.log(campaign, 'ss')

        return (
            <Dialog header={'Upload Result'}
                blockScroll={true} draggable={false}
                closeOnEscape={true}
                dismissableMask={true} visible={true}
                closable={true}
                style={{ width: '50%', height: '80vh' }}
                onHide={this.props.onHide}>
                <div>
                    <div className="bulk-upload">
                        <p className='spread-title'>Excel Sheet</p>
                        {/* <InputText
                            className='p-inputtext-style1'
                            value={this.state.fileSelected} disabled />
                        <label htmlFor="file" className='select-file'>Select File</label>
                        <input
                            type="file"
                            className="form-control"
                            id="file"
                            hidden={true}
                            accept={SheetJSFT}
                            onChange={this.handleChange}
                            disabled={this.state.isLoading}
                        /> */}
                        <div className='file-upload-w'>
                            <FileUpload id="returnWindow"
                                multiple={true}
                                afterFilesUploaded={this._afterFilesUploaded}
                                inputRef={this.fileUploadRef}
                            />
                        </div>
                        <div className='sp-clearFix'></div>

                        <Button label="Upload data" className='sp-mt10'
                            disabled={!this.state.uploadedFilesInfo.length}
                            onClick={() => {
                                if (this.state.uploadedFilesInfo && this.state.uploadedFilesInfo.length) {
                                    this.uploadResults();
                                }
                            }}
                            icon="pi pi-cloud-upload"
                        />


                    </div>

                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </Dialog >
        )
    }
}
