import React, { Component } from 'react'
import { OPEN_API_ENDPOINT } from 'src/Service/config'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


const paramsData = [
    {
        param: 'apikey',
        paramName: 'API Key',
        description: 'API Key is generated from portal.'
    },
    {
        param: 'peid',
        paramName: 'PE ID',
        description: 'PE ID is assigned by DLT Provider.'
    },
    {
        param: 'senderid',
        paramName: 'Sender ID',
        description: 'Sender Id is displayed as from address. Pass sender Id as registered on DLT Platform.'
    },
    {
        param: 'templateid',
        paramName: 'Template ID',
        description: 'Template ID as registered in DLT platform.'
    },
    {
        param: 'phonenumber',
        paramName: 'Phone Number',
        description: 'Pass Phone number to deliver the sms.'
    },
    {
        param: 'message',
        paramName: 'Messsge',
        description: 'Pass the message to be sent.'
    },

    {
        param: 'messagetype',
        paramName: 'Messsge Type',
        description: 'Pass the message type as "serviceimplicit" or "promotional"'
    }
]



export default class ApiDoc extends Component {


    render() {
        return (
            <div className='sp-m20 api-details'>
                <h2>API Details (http request)</h2>

                <div className='sp-m10 p-card sp-p20'>
                    <p className='config-txt config-url sp-txt-overflow'><b>URL:</b> <span> {OPEN_API_ENDPOINT + '/sms/send'}</span></p>
                    <p className='config-txt'><b>Request Type:</b> POST</p>
                    <p className='config-txt'><b>Payload (Body):</b></p>
                    <pre>
                        {
                            JSON.stringify({
                                "apikey": "0998877665544332211",
                                "peid": "1122334455667788990",
                                "senderid": "ABCDEF",
                                "templateid": "0987654321012345678",
                                "phonenumber": "9876543210",
                                "message": "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                                "messagetype": "serviceimplicit"
                            }, undefined, 4)
                        }
                    </pre>
                </div>

                <h3 style={{ textAliign: 'center' }} className='sp-m10'>Or</h3>

                <div className='sp-m10 p-card sp-p20'>
                    <p className='config-txt config-url sp-txt-overflow'><b>URL:</b> <span>{OPEN_API_ENDPOINT + '/sms/send?apikey=0998877665544332211&peid=1122334455667788990&senderid=ABCDEF&templateid=0987654321012345678&phonenumber=9876543210&message=ABCDEFGHIJKLMNOPQRSTUVWXYZ&messagetype=serviceimplicit'}</span> </p>
                    <p className='config-txt'><b>Request Type:</b> GET</p>
                </div>


                <h2>Mandatory Params</h2>
                <DataTable value={paramsData} responsiveLayout="scroll">
                    <Column field="param" header="Parameter"></Column>
                    <Column field="paramName" header="Name"></Column>
                    <Column field="description" header="Description"></Column>
                </DataTable>


            </div>
        )
    }
}
