import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Service from 'src/Service';
import cloneDeep from 'lodash/cloneDeep';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import { BasicLazyParams } from 'src/Utils/constants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';
import { getTimeSlotByKey, warningDailogInit } from 'src/Utils';
import { Specialization } from 'src/Utils/constants';
// import AppointmentInfo from './AppointmentInfo';
import CreateCampaign from './createCampaign';
import Header from './../Header';
import CampaignView from './campaignView';
import Authorize, { PERMISSIONS } from 'src/session/authorize';
import ServerlessService from 'src/Service/ServerlessService';
import LoadingComponent from 'src/LoadingComponent';
import UploadCampaignResult from './uploadCampaignResult';
import CampaignReports from './campaignReports';
import './styles.scss'

class BulkVoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddCampaign: false,
            appointments: [],
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            globalSearch: '',
            isShowCampaign: false,
            appointment: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
        }

        this.serverlessService = new ServerlessService();
    }


    showBookAnAppointment = () => {
        this.setState({ isShowAddCampaign: true, editCampaign: false });
        // this.props.history.push('/appointment-book')

    }


    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {

                currentFilter: currentFilter,
                lazyParams: BasicLazyParams
            }
        }, this.getVoiceCampigns);
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getVoiceCampigns);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getVoiceCampigns);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getVoiceCampigns);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getVoiceCampigns);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    getVoiceCampigns = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/voice-bulk/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, { status: this.state.currentFilter }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    appointments: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Campaigns fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }

    showCampaign = (campaign) => {

        this.setState({
            isShowCampaign: true,
            campaign: campaign
        })
    }


    editCampaign = (campaign) => {
        this.setState({
            isShowAddCampaign: true,
            editCampaign: campaign
        })
    }

    deleteCampaign = (rowData) => {
        let data = { ...rowData }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You want delete the campaign, it can't be undone once deleted`
            },
            editCampaign: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        const url = `/voice-bulk/delete`;
        this.serverlessService.post(url, { _id: this.state.editCampaign._id }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    editCampaign: null,
                    isLoading: false
                }, () => {
                    this.getVoiceCampigns();
                })


                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Campaigns deleted successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }



    actionsTemplate = (rowData) => {
        return (<>
            <div className="p-d-flex sp-mlr10 user-edit-t">
                {/* <Authorize permId={PERMISSIONS.SIGNATURES_VIEW} >
                    <span data-pr-tooltip="View Campaign" data-pr-position="bottom" className={`sp-ml10 anal${rowData._id.substr(2)}`} onClick={() => this.showCampaign(rowData)} >
                        <i className='pi pi-eye' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.anal${rowData._id.substr(2)}`} />
                </Authorize> */}

                {
                    rowData.campaignSource == 'backend' &&
                    <Authorize permId={PERMISSIONS.SIGNATURES_EDIT} >
                        <span data-pr-tooltip="Edit Campaign" data-pr-position="bottom" className={`sp-ml10 edit${rowData._id.substr(2)}`} onClick={() => { this.editCampaign(rowData) }}>
                            <i className='pi pi-pencil' />
                        </span>
                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(2)}`} />
                    </Authorize>
                }

                {/* 
                <Authorize permId={PERMISSIONS.SIGNATURES_DELETE} >
                    <span data-pr-tooltip="Delete Campaign" data-pr-position="bottom" className={`sp-ml10 del${rowData._id.substr(2)}`} onClick={() => { this.deleteCampaign(rowData) }}>
                        <i className='pi pi-trash' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.del${rowData._id.substr(2)}`} />
                </Authorize>
              
                 */}
                <Authorize permId={PERMISSIONS.SIGNATURES_DELETE} >
                    <span data-pr-tooltip="Upload Result" data-pr-position="bottom" className={`sp-ml10 ded${rowData._id.substr(2)}`} onClick={() => {
                        this.setState({
                            isShowUploadResult: true,
                            selectedCampaign: rowData
                        })
                    }}>
                        <i className='pi pi-upload' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.ded${rowData._id.substr(2)}`} />
                </Authorize>
                <Authorize permId={PERMISSIONS.SIGNATURES_DELETE} >
                    <span data-pr-tooltip="View Result" data-pr-position="bottom" className={`sp-ml10 res${rowData._id.substr(2)}`} onClick={() => {
                        this.setState({
                            isShowResult: true,
                            selectedCampaign: rowData
                        })
                    }}>
                        <i className='pi pi-chart-bar' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.res${rowData._id.substr(2)}`} />
                </Authorize>
            </div>
        </>
        );
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    onSaveCampaign = (isEdit) => {
        this.toast.show({ severity: 'success', summary: `${isEdit ? 'Added' : 'Modified'}`, detail: `Campaign ${isEdit ? 'Edited' : 'Created'} Succesfully.`, life: 3000 });


        this.setState({
            isShowAddCampaign: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getVoiceCampigns();
        })

    }


    componentDidMount() {


        this.getVoiceCampigns();
    }



    render() {
        const { currentFilter } = this.state;

        // console.log(this.state.appointments)
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >All Voice Campaigns</h2>
                    {/* 
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 1 ? 'p-button-outlined' : ''}`} label={'Booked'} onClick={() => this.onChangeStatusType(1)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 2 ? 'p-button-outlined' : ''}`} label={'Visisted'} onClick={() => this.onChangeStatusType(2)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 3 ? 'p-button-outlined' : ''}`} label={'Cancelled'} onClick={() => this.onChangeStatusType(3)} /> */}

                    {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <Authorize permId={PERMISSIONS.SIGNATURES_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        </Authorize>
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );


        return (<div>
            <Header>
                <div className='sp-main'>
                    {
                        this.state.isShowResult ? <>
                            <CampaignReports
                                selectedCampaign={this.state.selectedCampaign}
                                onBack={() => {
                                    this.setState({
                                        isShowResult: false
                                    })
                                }}
                            />
                        </> : <>




                            <Authorize permId={PERMISSIONS.SIGNATURES_ADD} >
                                <Button label="+ Add Campaign" className='sp-m-lr10'
                                    onClick={this.showBookAnAppointment} />
                            </Authorize>
                            {
                                this.state.isShowAddCampaign && <CreateCampaign
                                    campaign={this.state.editCampaign} onHide={() => {

                                        this.setState({
                                            isShowAddCampaign: false
                                        })
                                    }}

                                    onSave={this.onSaveCampaign}
                                />
                            }
                            <Authorize permId={PERMISSIONS.SIGNATURES_LIST} >
                                <div className="card sp-datatable  sp-m30">
                                    {/* <LazyTable ></LazyTable> */}
                                    <DataTable ref={(el) => this.dt = el}
                                        //  lazy
                                        scrollable
                                        responsiveLayout="scroll"
                                        //  autoLayout={true}
                                        rowHover
                                        value={this.state.appointments}
                                        // selection={this.state.selectedProducts}
                                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                        dataKey="_id"
                                        //</div>={true}
                                        lazy
                                        onSort={this.onSort}
                                        onPage={this.onPage}
                                        onFilter={this.onFilter}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}
                                        paginator
                                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        globalFilter={this.state.globalFilter}
                                        header={header}

                                    >
                                        <Column field="campaignName" header="Campaign Name" />
                                        {/* <Column field="campaign" header="Campaign" body={(rowData) => {
                                return <div dangerouslySetInnerHTML={{ __html: rowData.campaign }}></div>
                            }} /> */}

                                        <Column
                                            // headerClassName='option-2'
                                            //headerStyle={{ width: "520px",  }}
                                            //headerStyle={{ display: 'inline-block   ' }}
                                            sortabl body={this.actionsTemplate} header="Actions" ></Column>
                                    </DataTable>

                                </div>
                            </Authorize>

                            {
                                this.state.isShowCampaign && <>
                                    <CampaignView
                                        onHide={() => {
                                            this.setState({
                                                isShowCampaign: false
                                            })
                                        }}
                                        campaign={this.state.campaign}
                                    />
                                </>
                            }

                        </>
                    }
                </div>

                {
                    this.state.isShowUploadResult && <>
                        <UploadCampaignResult onHide={() => {
                            this.setState({
                                isShowUploadResult: false
                            })
                        }}
                            campaign={this.state.selectedCampaign}

                        />

                    </>
                }


                < Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editCampaign: null }) }} />
                            <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editCampaign: null })}>
                    <p className='sp-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='sp-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog >

                {
                    this.state.isLoading && <LoadingComponent />
                }
            </Header >
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </div >
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(BulkVoice)));
