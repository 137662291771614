import React, { Component } from "react";
import ClientHeader from "./clientHeader";

export default class Trai extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <ClientHeader>
          <div class="sp-container container ">
            <div class="sp-container-fluid  ">
              <div class="row ">
                <div class="col-md-12">
                  <h2 class="text-leftr text-info">DLT Guidelines</h2>
                  <p>
                    Distributed Ledger Technology(Dlt) Is A Blockchain-based
                    Registration System. This System Keeps Track Of All The
                    Records Of Promotional & Transitional Messages Sent By The
                    Senders Where All The Telemarketers And Entities/Enterprises
                    Have To Be Registered With Operators And All The Customers
                    And Retailers Are Connected To The Operators Through
                    Telemarketers. Header Guidelines On DLT As Per New
                    Guidelines From TRAI, Every Individual/Enterprise Has To
                    Register On Dlt Platform If They Want To Use Bulk Sms/Voice
                    Services then every header needs to be approved and
                    registered with the Telecom Operators. Has Made Dlt
                    Mandatory For Everyone From 1 June 2020. It Means For All
                    Your Senders/Headers & Sms Templates You Need To Register As
                    An Entity To The Dlt Blockchain Platform.
                    <br />
                    If Senders Are Not Active On Dlt Platform Message Will Not
                    Be Delivered.
                    <b>
                      Here, we explain the different type of Headers with
                      examples
                    </b>
                  </p>
                  <h4> PROMOTIONAL:</h4>
                  <p>
                    Headers that can be used for sending Promotional messages
                    fall under PROMOTIONAL type. Promotional message means
                    commercial communication that can be sent to a mobile
                    subscriber whose preferences are not set (not on DND). Or,
                    any commercial communication that an enterprise can send to
                    an intended recipient after taking consent to send such
                    messages.
                    <br />
                    Header (6 numeric): 565690, 787878, 987123, 555555, etc.
                  </p>
                  <p>
                    <b>
                      Promotional Headers should start with the number that
                      matches with the category (as per DND Preferences).
                    </b>
                  </p>
                  <ul class="sp-points">
                    <li>
                      1. Banking/Insurance/Financial products/ credit cards.
                      <br />
                      Ex: 123890, 154987
                    </li>
                    <li>
                      {" "}
                      2.Real Estate
                      <br />
                      Ex: 234567, 264789
                    </li>
                    <li>
                      3. Education
                      <br />
                      Ex: 397134, 321456
                    </li>
                    <li>
                      4. Health
                      <br />
                      Ex: 467931, 444654
                    </li>
                    <li>
                      5. Consumer goods and automobiles
                      <br />
                      Ex: 582974, 564123
                    </li>
                    <li>
                      6. Communication/Broadcasting / Entertainment/IT
                      <br />
                      Ex: 631469, 693147
                    </li>
                    <li>
                      7. Tourism and Leisure
                      <br />
                      Ex: 789456, 714369
                    </li>
                    <li>
                      8. Food and Beverages
                      <br />
                      Ex: 896321, 816934
                    </li>
                    <li>
                      0 (Zero) Others – Category that doesn’t appear in 1-8
                      <br />
                      Ex: 012389, 098654
                    </li>
                  </ul>
                  <h4>OTHER</h4>
                  <p>
                    <b>
                      Headers that can be used for sending Transactional,
                      Service Implicit and Service Explicit messages fall under
                      OTHER types. The same Header can be used against all three
                      types of messages. All These Headers are Case Sensitive.
                    </b>
                  </p>
                  <h4>
                    1.Header (6-alpha): TRUBLQ, TRUblq, TRubLQ, trublq, KLMNOP,
                    DLTdlt, etc.
                  </h4>
                  <p>
                    Transactional: Message which contains One Time Password
                    (OTP) and requires to complete a banking transaction
                    initiated by the bank customer. This is applicable to all
                    banks including national/ scheduled/ private/ Govt. and MNC
                    banks. Ex: TRHDFC, DCBmsg, MkotaK
                  </p>
                  <p>
                    2.Service Implicit: Messages arising out of customer’s
                    actions or his relationship with the Sender, that is not
                    promotional, and is not in the interest of the customer to
                    block such communications. These messages may or may not be
                    triggered by a subscriber-initiated transaction and will not
                    be blocked for subscribers who have otherwise blocked
                    service messages also.
                  </p>
                  <ul class="sp-points">
                    <li>
                      Confirmation messages of net banking or a credit/debit
                      transaction.
                    </li>
                    <li>Product purchase confirmation.</li>
                    <li>Delivery status of a parcel.</li>
                    <li>
                      OTP required for e-commerce websites, app login, social
                      media apps, KYC, etc.
                    </li>
                    <li>
                      Messages from schools regarding attendance/transport.
                    </li>
                    <li>
                      Messages from hospitals/clinics regarding
                      appointment/discharge reports.
                    </li>
                    <li>
                      Govt./TRAI/DoT mandated messages, advisories, messages
                      from state Govt., LEAs, local authorities, traffic
                      advisories, election commission, disaster management
                      advisories.
                    </li>
                    <li>
                      Service messages from car workshops, gadget service
                      centers.
                    </li>
                    <li>
                      Day-end/ month-end settlement alerts to securities/Demat
                      account holders. Ex: ABCDE, AFioma, uniFco.
                    </li>
                  </ul>
                  <p>
                    <b>service Explicit:</b> Any service message which doesn’t
                    fall under the category of service message (Implicit) will
                    be sent only against service explicit, digitally
                    verified/verifiable consent that has been taken from the
                    subscriber by the respective enterprise. Ex: NUTUKI, PRizem,
                    semRTA
                  </p>
                  <p>
                    <b>OTHER – Govt</b>Only Govt. entities can register these
                    Headers that can be used for sending Transactional, Service
                    Implicit and Service Explicit messages. Header (3 to 9
                    numeric and should start with 1): 1444, 19898, 15555,
                    19090909, etc.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h2 className="text-leftr text-info">TRAI Regulations</h2>
                  <p>
                    Telecom Regulatory Authority of India (TRAI) The Telecom
                    Regulatory Authority of India (TRAI) has directed telecom
                    operators to re-activate SMS checking filters beginning
                    April 1 and block traffic that does not comply with the
                    regulatory standards. The TRAI in a letter to the
                    telemarketers as seen by ET wrote, “As sufficient
                    opportunity has been given to the principal entities to
                    comply with the regulatory requirements, the consumers
                    cannot be deprived of the benefits of the regulatory
                    provisions. Accordingly, it has been decided that from April
                    1, 2021, any message failing in the scrubbing due to
                    non-compliance of regulatory requirements will be rejected.”
                  </p>
                  <ul>
                    <li>
                      TRAI has issued the Telecom Commercial Communications
                      Customer Preference Regulations, 2018 ("TCCCPR, 2018) on
                      19th July, 2018, to curb the menace of Unsolicited
                      Commercial Communications (UCC), which put in place a
                      framework for controlling UCC. The regulations entirely
                      came into force w.e.f. 28.02.2019. Since, then TRAI has
                      been through, Telecom Service Providers (TSP),
                      communicating with the Principal Entities to fulfill the
                      regulatory requirements.
                    </li>
                    <li>
                      As per the provisions of the regulation and Code(s) of
                      Practices published by the Access Providers, senders (OTP,
                      Transactional messages, Service messages or commercial
                      messages) are required to fulfil regulatory requirements
                      for sending bulk communication. The regulatory provisions
                      not only help in preventing spam but also help in
                      preventing fraudulent messages purporting to originate
                      from banks, financial institutions, or other trusted
                      sources. It also helps the Principal Entities to enhance
                      it reach by registering the consent of the customers.
                    </li>
                    <li>
                      In compliance of provisions of the regulations, when SMS
                      scrubbing was activated by the Access Providers w.e.f. 8th
                      March, 2021, some failure of A2P SMS traffic was observed.
                      It was observed that some of the principal entities have
                      not fulfilled the requirements as envisaged TCCCPR, 2018,
                      even after two years, despite being fully aware of the
                      regulations and the consequences. In order toprotect the
                      interest of consumers, TRAI had to request TSPs to
                      temporarily suspend the scrubbing of SMS for seven days to
                      enable the principal entities to register the template of
                      SMS so that no inconvenience is faced by the customers.{" "}
                    </li>
                    <li>
                      Thereafter, since 17th March 2021, Access Providers has
                      activated the content template scrubbing. However, to
                      avoid inconvenience to the public, trafic is being allowed
                      to pass for the time being, even if, it is not fulfilling
                      the regulatory requirements. Major reasons observed for
                      not meeting the regulatory compliance are content template
                      not registered, content Id missing, mismatch in template
                      registered and message sent etc. Unfortunately, despite of
                      repeated communication, all major banks and big
                      telemarketers sending SMS have failed to fulfill
                      regulatory requirements. All are being notified
                      individually also. TRAI has called for further reports
                      from TSPs.{" "}
                    </li>
                    <li>
                      TRAI hereby, once again requests all the Entities who are
                      using the telecom resources to send bulk messages to the
                      consumers, to fulfill the regulatory requirements
                      immediately so that there would not be any disruption in
                      the communication to the customers.
                    </li>
                    <li>
                      In case of any clarification, Shri Asit Kadayan, Advisor
                      (Quality of Services) may be contacted at 011-23230404 or
                      email advqos@trai.gov.in.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h2 className="text-leftr text-info">
                    Register for DLT Compliance
                  </h2>
                  <p>
                    The TRAI DLT (Telecom Regulatory Authority of India
                    Distributed Ledger Technology) registration refers to a
                    mandatory registration process implemented by the Telecom
                    Regulatory Authority of India (TRAI) for entities involved
                    in sending commercial communication messages to consumers in
                    India. This initiative is designed to curb unsolicited and
                    fraudulent telemarketing messages, making it a part of
                    India's efforts to improve consumer privacy and reduce spam
                    communications.
                  </p>
                  <ul>
                    <li>
                      <b>Purpose:</b> The primary purpose of TRAI DLT
                      registration is to create a transparent and traceable
                      system for commercial communications in India. This
                      ensures that legitimate telemarketers comply with
                      regulatory requirements while preventing the abuse of
                      telemarketing services for unsolicited or fraudulent
                      messages.
                    </li>
                    <li>
                      <b>Registration Process:</b> To send commercial
                      communications, telemarketers and businesses need to
                      register on the DLT platforms approved by TRAI. These DLT
                      platforms act as intermediaries for managing and
                      monitoring commercial communications.
                    </li>
                    <li>
                      <b>Template Registration:</b> Telemarketers are required
                      to submit message templates for review and approval. These
                      templates define the content and format of SMS or voice
                      calls used for commercial communications.
                    </li>
                  </ul>

                  <p>
                    DLT registration is a crucial step for businesses and
                    telemarketers looking to engage in legitimate and compliant
                    commercial communications in certain regions, such as India.
                    To initiate this process, individuals or entities are
                    typically required to visit the designated DLT registration
                    portal. Once on the portal, they must complete the signup
                    procedure, which often involves providing necessary
                    information and agreeing to compliance terms. It's important
                    to note that DLT registration may come with associated
                    costs, with fees that could range up to 5000 units of the
                    local currency, depending on the specific regulations and
                    DLT platform being used. In return for this registration,
                    businesses gain access to valuable tools and resources that
                    facilitate compliance with regulatory requirements. Among
                    these resources are the abilities to register and manage
                    message headers and templates. The registration of headers
                    and templates is a critical aspect of DLT compliance,
                    ensuring that commercial communications are standardized,
                    transparent, and compliant with relevant regulations. By
                    adhering to these protocols, businesses can build trust with
                    consumers and contribute to the overall reduction of spam
                    and unsolicited messages, ultimately benefiting both the
                    industry and the end-users.
                  </p>
                  <p>Click below to register for DLT compliance:</p>
                  <ul>
                    <li>
                      <a href="https://dltconnect.airtel.in/" target="_blank">
                        Register for Airtel DLT
                      </a>
                    </li>
                    <li>
                      <a href="https://www.ucc-bsnl.co.in/" target="_blank">
                        Register for BSNL DLT
                      </a>
                    </li>
                    <li>
                      <a href="https://trueconnect.jio.com/#/" target="_blank">
                        Register for Jio DLT{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ClientHeader>
      </>
    );
  }
}
