import React, { Component } from "react";
import ClientHeader from "./clientHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class SMS extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <ClientHeader>
          <div class="sp-container container ">
            <div class="sp-container-fluid sms-intro">
              <div class="row sp-plain-card">
                <div class="col-md-12">
                  <h2>{this.props.orgName} Bulk SMS</h2>
                  <p class="highlight-txt">Instant Delivery</p>
                  <p class="highlight-txt">Unlimited Validity</p>
                  <p class="highlight-txt">Reasonable Price</p>
                  <p class="highlight-txt">Secured APIs</p>
                  <p class="highlight-txt">Cloud based</p>
                  <p class="sp-mt20 sp-text1">
                    Let's make much more easier for your effective communication
                    with sms
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="sp-container container  sp-p15">
            <div class="sp-container-fluid">
              <h2 class="main-heading">Bulk sms</h2>
              <h3 class="main-heading">(Faster, Economic, efficient)</h3>
              <p class="sp-text1 sp-mt0">
                Bulk SMS service or bulk message means building SMS to one or
                more recipients via software/API/web platform as send shortly
                came forward to provide platform to send online bulk SMS in
                India. generally bulk SMS are of two kinds transactional bulk
                SMS and promotional bulk SMS .Bulk SMS can be sent like
                receiving an OTP, notification on transaction. If any online or
                offline Businesses want to create, schedule and send SMS
                campaigns for marketing or notification then all those
                activities can be done with from our bulk SMS software This
                widely meet help for various national companies, schools,
                organization, developers, NGOs & government agencies for
                promotion, communication, transactional alerts notification,
                remainders, OTP and feedback. Move ahead to start using bulk
                SMS.
              </p>
              <ul class="sp-points sp-text1">
                <li>
                  <b>Marketing and Promotion:</b> Many businesses use bulk SMS
                  to promote their products, services, or special offers to
                  potential and existing customers. It can be an effective and
                  cost-efficient way to reach a large audience quickly.
                </li>
                <li>
                  <b>Transactional Alerts: </b>Bulk SMS is also used for sending
                  transactional messages, such as order confirmations,
                  appointment reminders, delivery notifications, and account
                  balance updates. These messages are usually sent to
                  individuals based on specific interactions or events.
                </li>
              </ul>

              <h2 class="ma-mt50 main-heading">
                Better Bulk SMS communications
              </h2>
              <div class="p-grid">
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Personalize your bulk sms</h4>
                    <p class="sp-mt0">
                      Personalize your bulk SMS by including names, IDs and
                      other information via merge fields.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Attach files to your bulk sms</h4>
                    <p className="sp-mt0">
                      you can Send images, PDFs and other files as attachment
                      links to your bulk SMS campaigns and grab your customers
                      attention.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Custom sender names</h4>
                    <p className="sp-mt0">
                      Send bulk SMS from your custom 6-character/6-digit sender
                      IDs or sender names and enhance brand identity.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Delivery Reports</h4>
                    <p className="sp-mt0">
                      Many bulk SMS services provide delivery reports and
                      analytics, allowing businesses to track the success of
                      their SMS campaigns, including delivery rates and
                      recipient responses.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">API Integration</h4>
                    <p className="sp-mt0">
                      Businesses often integrate bulk SMS services into their
                      systems or applications through APIs (Application
                      Programming Interfaces) to automate message sending and
                      receive data for tracking and analysis.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Opt-In and Opt-Out:</h4>
                    <p className="sp-mt0">
                      It's important to follow regulations and obtain consent
                      from recipients (opt-in) before sending them bulk SMS.
                      Additionally, recipients should have the option to opt out
                      of receiving further messages if they choose to do so.
                    </p>
                  </div>
                </div>
              </div>
              <div class="sp-container-fluid">
                <h2 class="main-heading">Promotional sms</h2>
                <p class="sp-text1">
                  The basic purpose of promotional SMS is advertise a business
                  and market its products and services. As TRAI regulations ,
                  promotional SMS can be sent only non-DND number within a time
                  frame 9 AM to 9 PM .
                </p>
                <ul class="sp-points sp-text1">
                  <li>
                    Bulk SMS service marketing is effective than traditional
                    marketing . Bulk SMS marketing is Reasonable and affordable
                    medium to promote your products and services. Whereas
                    implementing traditional marketing can be a costly affair.
                  </li>
                  <li>
                    You can absolutely promote your product , remind customers
                    about upcoming sales etc.
                  </li>
                  <li>
                    Through our service you can send message to non-DND & DND
                    numbers and opt –in numbers with a six digit sender ID
                  </li>
                  <span>
                    Example: Dear user, signup now & send bulk SMS in India
                    using send shortly
                  </span>
                  <li>
                    Manage templates and schedule campaigns easily. Consolidate
                    excel contacts through plug-in.
                  </li>
                </ul>
                <h2 class="ma-mt50 main-heading">Transactional sms</h2>
                <p class="sp-text1">
                  Transactional SMS concerned on sending out important updates
                  prospective customers in the form of bulk SMS . It aims to
                  convey certain urgent and necessary information like when
                  message comes from a bank informing you about the amount
                  credited or debited by any mode of payment . Similarly when
                  you make UPI payment , its details like VPA gmail linked to
                  customer bank account number , delivery status are being
                  conveyed to you via an SMS
                </p>
                <p class="sp-text1">
                  Example: Dear customer Rs. 10 transferred from A/c….1234 to
                  UPI/1234567894 available balance in your account is Rs. 1000.
                </p>
                <ul class="sp-points sp-text1">
                  <li>Used for OTP , API , alerts ,notification</li>
                  <li>
                    Transactional SMS can be sent any time , 24/7 (no time
                    limit)
                  </li>
                  <li>SMS will be deliverd to DND + non-DND numbers</li>
                  <li>
                    A six character alphabetic sender ID will be provided with
                    first two letters of your business or brand name in any
                    language , round the clock Now it must be crystal clear
                    regarding the difference between promotional and
                    transactional BULK SMS.
                  </li>
                </ul>
              </div>
              <div class="sms-news-w">
                <p class="sp-text1">
                  In addition to the initial cost of advertisement in the
                  newspaper, if you want to add something new you have to pay to
                  them again. But in bulk SMS marketing the control is in your
                  hands. You can edit, change, and modify your SMS as per your
                  wish. There is no guarantee that customers will read your
                  advertisement or not in the newspaper. But in case of bulk SMS
                  the chances of reading the text increases to a great extent.
                  As people are glued to their mobiles there are more chances
                  for an SMS to be read rather than an advertisement in a
                  newspaper. You can measure the effectiveness of your campaign
                  as the results in bulk SMS marketing can be easily tracked.
                  But this advantage is not there in the traditional marketing.
                </p>
              </div>
            </div>
          </div>
          <div class="sp-container  why-is-bulksms-w">
            <div class="sp-container-fluid container why-is-bulksms sp-p15">
              <h2 class="main-heading">Why is Bulk sms service</h2>
              <p class="sp-text1">
                Bulk SMS can be used to send reminders, alerts, update clients
                about new offers, deals, and discounts. These days it is widely
                used for promotion and advertising of products and services.
                There is no limit to the use of bulk SMS. Its uses are endless.
                Bulk SMS can be deployed wherever we need to build an instant
                connection and convey urgent information.
              </p>
              <ul class="sp-text1">
                <li>
                  SMS conveys information in a short, concise and clear cut
                  manner which is preferred by customers.
                </li>
                <li>
                  SMS is the most personalized way to deliver information. There
                  is no other channel as fast and efficient as an SMS.
                </li>
                <li>
                  The results of bulk SMS marketing are easily trackable. You
                  can measure whether your SMS campaign is effective or not and
                  take suitable measures to achieve the desired results.
                </li>

                <li>
                  SMS has a high open rate than any other marketing tool. SMS
                  has a direct reach to the inbox of receiver's mobile. It
                  doesn't go to other filters like spam.
                </li>
                <li>
                  SMS marketing is a highly affordable medium of communicating
                  vital information. The other forms of advertising like
                  commercial, newspaper advertisement incur huge expenses.
                </li>
                <li>
                  Internet connection is not required to receive an SMS. This
                  makes the reach of SMS wide and accessible.
                </li>
              </ul>
              <p class="sp-text1">
                <b>
                  Bulk SMS empowers businesses to present customers with high
                  quality and reliable information and the brand.
                </b>
              </p>
            </div>
          </div>
          <div class="sp-container container our-features sp-p15">
            <div class="sp-container-fluid">
              <h2 class="main-heading">
                Our <b>Best</b> Features
              </h2>
              <div class="p-grid">
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">AFFORDABLE PRICE</h4>
                  <p class="sp-text2">
                    Get lowest price for the best services we provide. High
                    services = low price is a key-feature. Find a low price & we
                    will match it.
                  </p>
                </div>
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">RELIABLE SMS DELIVERY</h4>
                  <p class="sp-text2">
                    Our gateway is build upon dedicated servers joining multiple
                    sms gateways that provide lighting fast sms delivery.
                    Delivery will be as per the DLT & TRAI guidelines
                  </p>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">DYNAMIC SMS GATEWAY</h4>
                  <p class="sp-text2">
                    Our server conjoins multiple SMS gateways that are
                    dynamically picked up to send SMS. This will help our server
                    to reduce the load on a single gateway.
                  </p>
                </div>
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">CUSTOMER SUPPORT</h4>
                  <p class="sp-text2">
                    We are absolutely ready to assist you and supports you all
                    the time to strive your trouble.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="sp-container container sp-container-fluid sp-p15">
            <h2 class="main-heading">SMS API</h2>
            <p class="sp-text2">
              Bulk SMS gateway is a way to integrate your existing setup with
              SMS APIs. It’s important to choose the right bulk sms gateway that
              provides high quality, real time delivery reports and
              transparency. We are reliable bulk SMS gateway providers who work
              to contribute to your long term success. Our SMS gateway easily
              integrates with any system to communicate with customers. our SMS
              gateway helps you to improve customer base, engagement levels,
              sales, audience’s loyalty.
            </p>

            <ul class="sp-points sp-text2 sp-mb50">
              <li>
                SMS API enables your application or website to send an SMS and
                receive SMS messages at any trigger point reset now your system
                could automatically send a reminder for you or alert when it
                isn’t running the way it should be integration is really easy
              </li>
              <li>
                Automatic messages can be send to a large group of people at
                once . API SMS is used by a lot of E-commerce portals these days
                as it is the most secured way to reach our customers. API SMS
                can also be used to deliver bulk OTP SMS
              </li>
              <li>
                Use flexible APIs to send SMS from a website in just a few
                seconds . Avoid multiple vendors or steps
              </li>
              <li>
                Get things done faster by using our SMS APIs to sync in existing
                CRM systems to ensure delivery to all contacts
              </li>
              <li>
                Include API in your apps and fulfil your SMS marketing needs
                right from your android application & devices.
              </li>
              <li>
                Quick Delivery Report : Get quick insights & keep track of all
                SMS sent
              </li>
            </ul>
          </div>
        </ClientHeader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orgName: state?.orgData?.orgName,
});

export default connect(mapStateToProps, {})(withRouter(SMS));
