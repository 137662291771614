import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import Login from './Login';
//import Appointments from './Appointments';
import 'primeflex/primeflex.css';
// import './theme.scss';
import { getOrgData } from './store/actions';
import './fonts/fonts.css';
import './App.scss'
import HomePage from './HomePage';
import EmailEditorComponent from './EmailEditor';
import EmailSignature from './EmailSignature';
import EmailTemplate from './EmailTemplate';
import EmailUsers from './EmailUsers';
import BulkVoice from './BulkVoice';
import BulkEmails from './BulkEmails';
import BulkSms from './components/sms/bulkSms';
import BulkEmailUsersUpload from './EmailUsers/bulkUpload';
import EmailConfig from './EmailConfig';
import AdminUser from './AdminManagement/AdminUser';
import Roles from './AdminManagement/Roles';
import ComposeEmail from './BulkEmails/composeEmail';
import PeManagement from './components/sms/peManagement';
import SMSHeader from './components/sms/senderIds';
import SMSTemlates from './components/sms/templates';
import ComposeSms from './components/sms/bulkSms/composeSms';
import io from 'socket.io-client';
import { EMAIL_SERVER_URL } from './Service/config';
import APIDocumentation from './components/sms/apiDocumentation';
import smsReports from './components/sms/smsReports';
import URLShortener from './components/sms/urlShortener'
import ClientHomepage from './components/client/clientHomepage';
import Homepage from "./HomePage";
import Dashboard from './components/portal/Dashboard';
import Pricing from './components/client/pricing';
import ContactUs from './components/client/contact';
import AboutUs from './components/client/aboutUs';
import { connect } from 'react-redux';
import LoadingComponent from './LoadingComponent';
import Reseller from './components/reseller';
import Entities from './components/entities';
import SMS from './components/client/sms';
import Voice from './components/client/voice';
import Email from './components/client/email';
import Terms from './components/client/terms';
import PrivacyPolicy from './components/client/privacy-policy';
import Trai from './components/client/trai';
import CaseStudies from './components/client/caseStudies';
import Faq from './components/client/faq';
import CustomPricing from './components/reseller/customPricing';
import PortalManage from './components/reseller/portalManage';
import PeIds from './components/sms/peIds';
import AllReportInSinglePage from './BulkVoice/allReportInSinglePage';

const ENDPOINT = EMAIL_SERVER_URL;
// export const socket = io(ENDPOINT);

// localStorage.setItem('data-theme', 'main-theme');
// document.documentElement.setAttribute('data-theme', 'main-theme');


// localStorage.setItem('data-theme', 'pink');
// document.documentElement.setAttribute('data-theme', 'pink');


// localStorage.setItem('data-theme', 'yellow');
// document.documentElement.setAttribute('data-theme', 'yellow');


// localStorage.setItem('data-theme', 'green');
// document.documentElement.setAttribute('data-theme', 'green');



// localStorage.setItem('data-theme', 'lightblue-3');
// document.documentElement.setAttribute('data-theme', 'lightblue-3');


class App extends Component {
  constructor(props) {
    super(props);
  }




  componentDidMount() {
    if (!this.props.assignedModules) {
      this.props.getOrgData();
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps.orgName != this.props.orgName) {
      document.title = this.props.orgName;
    }


    if (prevProps.theme != this.props.theme) {
      if (this.props.theme == 'default') {
        localStorage.setItem('data-theme', null);
        document.documentElement.setAttribute('data-theme', null);
      } else {
        localStorage.setItem('data-theme', this.props.theme);
        document.documentElement.setAttribute('data-theme', this.props.theme);
      }
    }
  }


  render() {
    //console.log(this.props.orgData, 'orgData')

    return (
      <div>
        <BrowserRouter>
          <div className="App">
            <Route exact path='/' component={ClientHomepage} />
            <Route exact path='/dashboard' component={Dashboard} />
            <Route exact path='/portal' component={Homepage} />
            <Route exact path='/login' component={Login} />

            {/* <Route exact path='/appointments' component={Appointments} /> */}
            {/* <Route exact path='/appointment-book' component={BookAnAppointment} /> */}
            {/* <Route exact path='/email' component={EmailEditorComponent} /> */}
            <Route exact path='/signature' component={EmailSignature} />
            <Route exact path='/template' component={EmailTemplate} />
            <Route exact path='/emailusers' component={EmailUsers} />
            <Route exact path='/bulk-emailusers' component={BulkEmailUsersUpload} />
            <Route exact path='/bulk-emails' component={BulkEmails} />
            <Route exact path='/compose' component={ComposeEmail} />
            <Route exact path='/config' component={EmailConfig} />
            <Route exact path='/admin' component={AdminUser} />
            <Route exact path='/roles' component={Roles} />
            <Route exact path='/pe-management' component={PeManagement} />
            <Route exact path='/principal-entities' component={PeIds} />
            <Route exact path='/sms-senderids' component={SMSHeader} />
            <Route exact path='/sms-templates' component={SMSTemlates} />
            <Route exact path='/sms-reports' component={smsReports} />
            <Route exact path='/api-documentation' component={PeManagement} />
            <Route exact path='/sms-campaign' component={PeManagement} />
            <Route exact path='/send-sms' component={ComposeSms} />
            <Route exact path='/bulk-sms' component={BulkSms} />
            <Route exact path='/api-doc' component={APIDocumentation} />
            <Route exact path='/url-shortener' component={URLShortener} />
            <Route exact path='/pricing' component={Pricing} />
            <Route exact path='/contact' component={ContactUs} />
            <Route exact path='/about' component={AboutUs} />
            <Route exact path='/sms' component={SMS} />
            <Route exact path='/voice' component={Voice} />
            <Route exact path='/email' component={Email} />
            <Route exact path='/terms-condition' component={Terms} />

            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact path='/trai-guidelines' component={Trai} />
            <Route exact path='/case-studies' component={CaseStudies} />
            <Route exact path='/faqs' component={Faq} />


            <Route exact path='/reseller' component={Reseller} />
            <Route exact path='/clients' component={Entities} />
            <Route exact path='/pricing-manage' component={CustomPricing} />
            <Route exact path='/portal-manage' component={PortalManage} />
            <Route exact path='/bulk-voice' component={BulkVoice} />
            <Route exact path='/all-reports' component={AllReportInSinglePage} />

            {/* <Route exact path='/appointmentSuccess' component={AppointmentSuccess} />
          <Route exact path='/appointmentCancel' component={AppointmentCancel} />
          <Route exact path='/appointmentView' component={ViewAppointments} />
          <Route exact path='/docAppointmentCancel' component={DocAppointmentCancel} />
          <Route exact path='/patAppointmentView' component={ViewPatientAppointments} />
          <Route exact path='/feedback' component={Feedback} />
          <Route exact path='/feedbackSuccess' component={FeedbackSuccess} /> */}
          </div>
        </BrowserRouter>
        {
          this.props.isLoading &&
          <LoadingComponent />
        }
      </div>
    )
  }
}




const mapStateToProps = (state) => ({
  orgName: state?.orgData?.orgName,
  orgData: state.orgData,
  isLoading: state?.orgData?.isLoading,
  theme: state?.orgData?.theme

});

export default connect(mapStateToProps, { getOrgData })(App);

