export const selectPermissions = (state, nonBaseOrg) => {
    return state.permissions.data.filter((item) => {
        item.permissions = item.permissions.filter((rec) => rec.status === 'Active');

        if (nonBaseOrg) {
            return !item.isSuper ? item : null;
        } else {
            return item;
        }

    });
};

export const selectAllRoles = (state) => {
    return state.roles.data
        .filter((rec) => rec !== undefined);
}

export const selectActiveRoles = (state) => {
    return state.roles.data
        .map((item) => {
            if (item.status === 'Active') {
                return {
                    roleId: item._id,
                    roleName: item.roleName,
                    roleType: item.roleType
                };
            }
        })
        .filter((rec) => rec !== undefined);
};
