import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import adminUserFields from './adminUser.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onChipsChange, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { trimObj } from 'src/Utils';
import ServerlessService from 'src/Service/ServerlessService';
import { Chips } from 'primereact/chips';

class CreateAdminUser extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(adminUserFields, this.props.adminUser ? { password: 'Test@123', passwordConfirm: 'Test@123', ...this.props.adminUser } : {});
        this.state = {
            adminUser: this.formFields.data,
            formValidations: this.formFields.formValidations,
            rolesList: []
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    isAdditionalValid = () => {

        let adminUser = JSON.parse(JSON.stringify(this.state.adminUser));
        let { formValidations } = this.state;
        // password and confirm password 
        let isValid = true;
        if (adminUser.password != adminUser.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }

    saveAdminUser = () => {


        let isCompleteFormValid = true;
        let adminUserData = trimObj(this.state.adminUser)
        const formStatus = isFormValid(adminUserFields, this.formFields.formValidations, adminUserData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }



        if (isCompleteFormValid) {
            let url = `/admin/save`
            if (this.props.adminUser) {
                url = `/admin/update`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, adminUserData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.adminUser ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }


    getRoles = () => {
        this.setState({
            isLoading: true,
        });

        let url = `/authorize/getRolesNamesids`

        this.serverlessService.get(url, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    rolesList: res.res.data,
                    isLoading: false,
                }, () => {


                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

        this.getRoles()

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.adminUser ? 'Update' : 'Save'} Admin User`} className='sp-m-lr10'
                onClick={this.saveAdminUser}
            />
        </div>)
    }


    render() {
        const { adminUser, rolesList, formValidations } = this.state;

        //console.log(this.state, 'state-appointment')
        return (<>
            <Dialog header={`${this.props.adminUser ? 'Edit' : 'Create'} User`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid sp-mt20">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Email<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            {/* <InputText
                                value={adminUser.email}
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'email', this, adminUserFields, adminUser, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>} */}
                            <InputText
                                disabled={this.props.adminUser}
                                value={adminUser.email}
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onEmailChange(e.target.value, 'email', this, adminUserFields, adminUser, formValidations, 'adminUser', 'formValidations') }} />
                            {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                        </div>
                    </div>

                    {
                        !this.props.adminUser && <>
                            <div className="p-grid">
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Password<span className='sp-required'>*</span></p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <InputText value={adminUser.password}
                                        type="password"
                                        autoComplete="new-password"
                                        className='sp-w100p  p-inputtext-style1'
                                        onChange={(e) => { onTextChange(e.target.value, 'password', this, adminUserFields, adminUser, formValidations, 'adminUser', 'formValidations') }} />
                                    {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="p-grid ">
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Confirm Password<span className='sp-required'>*</span></p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <InputText value={adminUser.passwordConfirm}
                                        type="password"
                                        autoComplete="new-password"
                                        className='sp-w100p  p-inputtext-style1'
                                        onChange={(e) => { onTextChange(e.target.value, 'passwordConfirm', this, adminUserFields, adminUser, formValidations, 'adminUser', 'formValidations') }} />
                                    {formValidations && !formValidations.fields['passwordConfirm'].isValid && <p className="p-error">{formValidations.fields['passwordConfirm'].errorMsg}</p>}
                                </div>
                            </div>
                        </>
                    }
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Name<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={adminUser.name}
                                disabled={this.props.adminUser}
                                autoComplete="new-password"
                                className='sp-w100p  p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'name', this, adminUserFields, adminUser, formValidations, 'adminUser', 'formValidations') }} />
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Role<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <Dropdown value={adminUser.roles}
                                className='sp-w200'
                                options={rolesList}
                                optionLabel="roleName"
                                optionValue='_id'
                                // onChange={(e) => this.onSelectRole(e.value, 'roles')}
                                onChange={(e) => { onTextChange(e.value, 'roles', this, adminUserFields, adminUser, formValidations, 'adminUser', 'formValidations') }}
                                placeholder="Select role" />
                            {formValidations && !formValidations.fields['roles'].isValid && <p className="p-error">{formValidations.fields['roles'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-2 p-lg-2">
                            <p className='text-label'>Campaign Ids</p>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-10 sp-mt2">
                            <Chips
                                className='sp-w100p cc'
                                value={adminUser.campaignIds}
                                addOnBlur={true}
                                onChange={(e) => onChipsChange(e.value, 'campaignIds', this, adminUserFields, adminUser, formValidations, 'adminUser', 'formValidations')}
                                separator=','
                            ></Chips>

                            {formValidations && !formValidations.fields['campaignIds'].isValid && <p className="p-error">{formValidations.fields['campaignIds'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateAdminUser)));
