import React, { Component } from 'react'

import { Dialog } from 'primereact/dialog';


export default class SignatureView extends Component {
    constructor(props) {
        super(props);

    }





    render() {
        const { campaign } = this.props;
        return (
            <Dialog header={'Campaign Details'}
                blockScroll={true} draggable={false}
                closeOnEscape={true}
                dismissableMask={true} visible={true}
                closable={true}
                style={{ width: '50%' }}
                onHide={this.props.onHide}>
                <div className='appointment-info'>
                    <div>
                        <span className='label-l1'> Campaign Name: </span>
                        <span className='label-v1'> {campaign.campaignName}</span>
                    </div>
                    <div>
                        <span className='label-l1'> Campaign Id: </span>
                        <span className='label-v1'> {campaign.campaignId}</span>
                    </div>
                    <div>
                        <span className='label-l1'> Campaign Type: </span>
                        <span className='label-v1'> {campaign.campaignType}</span>
                    </div>
                    {
                        campaign.campaignType == 'ivr' && <div>
                            <span className='label-l1'> Campaign IVR: </span>
                            <span className='label-v1'> {campaign.ivrValues.join(',')}</span>
                        </div>
                    }
                </div>
            </Dialog >
        )
    }
}
