import React, { Component } from 'react'
import _ from 'lodash';
import { Checkbox } from 'primereact/checkbox';
export default class PermissionsTab extends Component {
    constructor(props) {
        super(props);

    }


    handleCheckBoxChange = (groupId, permissionId) => (event) => {

        this.props.handleCheckBoxClick(groupId, permissionId, event.target.checked);
    };

    isChecked = (id, permissions) => {
        return Object.keys(permissions)
            .reduce((acc, curr) => {
                acc = acc.concat(permissions[curr]);
                return acc;
            }, [])
            .includes(id);
    };

    render() {
        const { classes, name, details, id, handleChange, expanded, selectedPermissions } = this.props;
        const data = _.chain(this.props.details)
            .groupBy('category')
            .map((value, key) => ({ category: key, checkboxes: value }))
            .value();

        return (
            <div className='permission-tab'>
                {data.map((groupedItem, itr) => (<>
                    <div className="p-grid" key={`${groupedItem}-${itr}`}>
                        <p className='group'>
                            {groupedItem.category}
                        </p>
                    </div>

                    <div className="p-grid sp-mlr10">
                        {groupedItem.checkboxes.map((item, index) => (
                            <div className="p-col-12 p-md-3" id={item.id} key={`check-${index}`}>
                                <Checkbox inputId={item.id}
                                    checked={this.isChecked(item.id, selectedPermissions)}
                                    value={item.id}
                                    onChange={this.handleCheckBoxChange(this.props.id, item.id)}
                                ></Checkbox>
                                <label htmlFor={item.id} className="p-checkbox-label p-name">{item.name}</label>
                            </div>
                        ))}
                    </div>
                </>

                ))
                }

            </div>
        )
    }
}
