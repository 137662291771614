import React, { Component } from 'react'

import EmailEditor from 'react-email-editor';

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import ReactQuill from 'react-quill';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



export default class EmailEditorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            text: ''
        }
        this.emailEditorRef = React.createRef();

    }



    exportHtml = () => {
        this.emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            console.log('exportHtml', html);
        });
    };

    onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    onReady = () => {
        // editor is ready
        console.log('onReady');
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleChange = (value) => {
        this.setState({ text: value })
    }



    render() {
        return (<>
            {/* <div>
                <button onClick={this.exportHtml}>Export HTML</button>
            </div>

            <EmailEditor ref={this.emailEditorRef} onLoad={this.onLoad} onReady={this.onReady} />
 */}


            <Editor
                editorState={this.state.editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={this.onEditorStateChange}
            />
            {/* 
            <ReactQuill value={this.state.text}
                onChange={this.handleChange} /> */}


            <CKEditor
                editor={ClassicEditor}
                data="<p>Hello from CKEditor 5!</p>"
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                }}

                onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                }}
            />
        </>



        )
    }
}
