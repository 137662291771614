import React, { Component } from "react";
import ClientHeader from "./clientHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Voice extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <ClientHeader>
          <div class="sp-container container ">
            <div class="sp-container-fluid sms-intro">
              <div class="row sp-plain-card">
                <div class="col-md-12">
                  <h2>{this.props.orgName} Bulk Voice Calls</h2>
                  <p class="highlight-txt">Instant Delivery</p>
                  <p class="highlight-txt">Unlimited Validity</p>
                  <p class="highlight-txt">Reasonable Price</p>
                  <p class="highlight-txt">Secured APIs</p>
                  <p class="highlight-txt">Cloud based</p>
                  <p class="sp-mt20 sp-text1">
                    Let's make much more easier for your effective communication
                    with Voice calls
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="sp-container container  sp-p15">
            <div class="sp-container-fluid">
              <h2 class="main-heading">Bulk Voice Calls</h2>
              <h3 class="main-heading">(Faster, Economic, efficient)</h3>
              <p class="sp-text1 sp-mt0">
                In today's fast-paced world, effective communication is crucial
                for businesses, organizations, and governments. Reaching out to
                a large audience quickly and efficiently is a priority for many.
                This is where bulk voice call services come into play. These
                services enable you to broadcast pre-recorded messages to
                thousands or even millions of recipients simultaneously. Whether
                you want to inform your customers about a special promotion,
                alert citizens about an emergency, or keep your members updated,
                bulk voice calls offer an ideal solution. {this.props.orgName} also offers you to track the user inputs.
              </p>

              <h2 class="ma-mt50 main-heading">
                Better Bulk Voice communications
              </h2>
              <div class="p-grid">
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Cost-Efficiency</h4>
                    <p class="sp-mt0">
                      Bulk voice calls are a cost-effective way to disseminate
                      information compared to traditional methods like manual
                      calling.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Instant Reach</h4>
                    <p className="sp-mt0">
                      With bulk voice calls, you can reach a large audience
                      within minutes, making it ideal for time-sensitive
                      announcements and updates.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Custom sender names</h4>
                    <p className="sp-mt0">
                      Send bulk SMS from your custom 6-character/6-digit sender
                      IDs or sender names and enhance brand identity.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Detailed Reporting</h4>
                    <p className="sp-mt0">
                      Get detailed reports on the status of your calls,
                      including delivery, unanswered calls, and more.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Scheduling</h4>
                    <p className="sp-mt0">
                      Schedule voice calls in advance to ensure your messages
                      are delivered at the most convenient times for your
                      recipients, optimizing response rates.
                    </p>
                  </div>
                </div>
                <div class="p-md-4">
                  <div class="card sp-p20 sp-m20 speciality-card">
                    <h4 class="main-heading">Multi-language Support</h4>
                    <p className="sp-mt0">
                      Reach a diverse audience by delivering messages in
                      multiple languages, making your communication more
                      inclusive.
                    </p>
                  </div>
                </div>
              </div>
              <div class="sp-container-fluid">
                <h2 class="main-heading">Promotional Voice Calls</h2>
                <p class="sp-text1">
                  In the ever-evolving landscape of marketing, innovative
                  strategies are crucial to capture the attention of your target
                  audience. One such strategy that's gaining popularity is
                  promotional voice calls. These calls are an effective and
                  engaging way to promote your products, services, or special
                  offers to a wide audience, creating a lasting impact. In this
                  text, we'll explore how promotional voice calls can transform
                  your marketing efforts.
                </p>
                <ul class="sp-points sp-text1">
                  <li>
                    <b>High Impact:</b> A well-crafted promotional voice call
                    can capture your audience's attention, ensuring that your
                    message is heard loud and clear.
                  </li>
                  <li>
                    <b>Personalization:</b> Make your customers feel valued by
                    addressing them by name and tailoring your offers to their
                    preferences.
                  </li>
                  <li>
                    <b> Instant Action :</b> Encourage immediate action with a
                    compelling call to action, such as "Press 1 to avail the
                    offer."
                  </li>
                  <li>
                    <b>Cost-Effective:</b> Compared to traditional marketing
                    channels, promotional voice calls offer cost-effective ways
                    to reach a large audience.
                  </li>
                </ul>
                <h2 class="ma-mt50 main-heading">Transactional Voice Calls </h2>
                <p class="sp-text1">
                  In the modern world of business and services, communication is
                  key to maintaining strong and trustworthy relationships with
                  customers. Transactional voice calls offer a direct and
                  efficient way to keep your customers informed about important,
                  time-sensitive updates and transactions. This text explores
                  the power and utility of transactional voice calls in
                  enhancing customer engagement and service efficiency.
                </p>

                <ul class="sp-points sp-text1">
                  <li>
                    <b>Real-time Notifications:</b> Transactional voice calls
                    deliver real-time updates, ensuring that critical
                    information reaches your customers promptly.
                  </li>
                  <li>
                    <b>Security:</b> These calls are a secure way to convey
                    sensitive information, such as account balances, one-time
                    passwords, or order confirmations.
                  </li>
                  <li>
                    <b>Reliability:</b> Transactional voice calls offer a
                    dependable means of communication, reducing the risk of
                    messages going unnoticed.
                  </li>
                </ul>
              </div>
              <div class="sms-news-w">
                <p class="sp-text1">
                  In addition to the initial cost of advertisement in the
                  newspaper, if you want to add something new you have to pay to
                  them again. But in bulk Voice calls marketing the control is
                  in your hands. You can edit, change, and modify your voice
                  message as per your wish. There is no guarantee that customers
                  will read your advertisement or not in the newspaper. But in
                  case of bulk voice calls the chances of hearing the message
                  increases to a great extent. As people are glued to their
                  mobiles there are more chances for an voice to be hear rather
                  than an advertisement in a newspaper. You can measure the
                  effectiveness of your campaign as the results in bulk voice
                  calls marketing can be easily tracked. But this advantage is
                  not there in the traditional marketing.
                </p>
              </div>
            </div>
          </div>
          <div class="sp-container  why-is-bulksms-w">
            <div class="sp-container-fluid container why-is-bulksms sp-p15">
              <h2 class="main-heading">Why is Bulk voice service</h2>
              <p class="sp-text1">
                Bulk voice service, also known as voice broadcasting or
                robocalling, is a powerful communication tool that allows
                organizations to efficiently deliver pre-recorded messages to a
                large audience simultaneously. It offers instant outreach, high
                open rates, scalability, and real-time engagement, making it
                ideal for marketing campaigns, emergency alerts, community
                engagement, and more, while providing detailed reporting and
                cost-effective communication.
              </p>
              <ul class="sp-text1">
                <li>
                  <b>Efficiency:</b> Bulk voice service allows you to send
                  pre-recorded messages to a large number of recipients
                  simultaneously, saving time and effort compared to making
                  individual calls or sending texts.
                </li>
                <li>
                  <b> Instant Outreach:</b> It provides a quick and direct way
                  to reach a wide audience within minutes, making it ideal for
                  time-sensitive announcements, promotions, or emergency alerts.
                </li>
                <li>
                  <b> Personalization:</b> Many bulk voice services offer
                  personalization options, allowing you to address recipients by
                  name or include other customized information, creating a more
                  engaging experience.
                </li>

                <li>
                  <b> Cost-Effective:</b> Bulk voice calls are often more
                  cost-effective than traditional methods of communication, such
                  as manual calling or direct mail.
                </li>
                <li>
                  <b>Detailed Reporting:</b> Bulk voice services offer detailed
                  reports on the status of your calls, allowing you to track
                  delivery rates, unanswered calls, and more, which can inform
                  future campaigns.
                </li>
                <li>
                  <b>Wide Reach: </b>Bulk voice calls can reach people across
                  different geographic locations without the limitations of
                  traditional communication methods.
                </li>
                <li>
                  <b>Political Campaigns:</b> Politicians and political
                  organizations use bulk voice service to engage with voters,
                  spread their message, and mobilize their base during
                  elections.
                </li>
                <li>
                  <b>Marketing Campaigns:</b> Bulk voice service is effective
                  for promoting products, services, or special offers, and it's
                  an excellent way to track responses to your marketing efforts.
                </li>
                <li>
                  <b>Interactive Voice Response (IVR):</b> Some bulk voice
                  services offer interactive voice menus, allowing recipients to
                  interact with the message by pressing keys on their phone's
                  keypad.
                </li>
                <li>
                  <b>Automated Reminders:</b> It's perfect for sending
                  appointment reminders, bill payment reminders, and event
                  notifications, reducing no-shows and missed deadlines.
                </li>
              </ul>
              <p class="sp-text1">
                <b>
                  Bulk Voice Calls empowers businesses to present customers with
                  high quality and reliable information and the brand.
                </b>
              </p>
            </div>
          </div>
          <div class="sp-container container our-features sp-p15">
            <div class="sp-container-fluid">
              <h2 class="main-heading">
                Our <b>Best</b> Features
              </h2>
              <div class="p-grid">
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">
                    Voice Broadcasting and Live Reports
                  </h4>
                  <p class="sp-text2">
                    <b>Our platform offers two powerful features:</b> voice
                    broadcasting and live reports. With voice broadcasting, you
                    can efficiently reach a large audience by sending
                    pre-recorded voice messages to your contacts. Meanwhile, our
                    live reports feature provides real-time insights into the
                    performance of your campaigns, allowing you to monitor call
                    progress, response rates, and other key metrics. These tools
                    work together to streamline your communication strategies
                    and ensure you stay informed about the effectiveness of your
                    outreach efforts.
                  </p>
                </div>
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">
                    {" "}
                    Interactive Dashboards and Widgets
                  </h4>
                  <p class="sp-text2">
                    Our interactive dashboards and widgets are designed to make
                    data visualization and analysis a breeze. These
                    user-friendly interfaces provide a visual overview of key
                    performance indicators and campaign metrics. The widgets,
                    including charts, graphs, and tables, present data in an
                    easily digestible format, enabling you to track and
                    interpret information in real time. These features empower
                    you to make data-driven decisions and optimize your
                    strategies with ease.
                  </p>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">
                    Downloadable Reports and Clear Logs
                  </h4>
                  <p class="sp-text2">
                    For in-depth analysis and record-keeping, our platform
                    offers downloadable reports that provide detailed insights
                    into your campaigns. These reports can be easily saved and
                    shared for further review. In addition, our system maintains
                    clear logs of all interactions and activities, ensuring
                    transparency and accountability. You can access historical
                    data and track the progress of your communication
                    initiatives over time.
                  </p>
                </div>
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">
                    IVR Surveys and DTMF Capturing
                  </h4>
                  <p class="sp-text2">
                    Our platform facilitates interactive voice response (IVR)
                    surveys, which are essential for gathering valuable feedback
                    and conducting market research. Users can engage with
                    automated surveys and provide responses using touch-tone
                    keypads. Simultaneously, our DTMF capturing feature ensures
                    that user input is accurately recorded, enhancing the
                    effectiveness of your IVR campaigns. These services enable
                    you to collect data, monitor user feedback, and improve your
                    decision-making processes.
                  </p>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1"> Missed Call Services</h4>
                  <p class="sp-text2">
                    Missed call services are a cost-effective and versatile way
                    to engage with your audience. Users can simply give a missed
                    call to a designated number, and in return, they receive
                    information, services, or participate in various activities.
                    This approach is ideal for lead generation, verification,
                    content subscriptions, polls, and more. It offers a unique
                    way to connect with your audience, making it accessible to a
                    wide range of users.
                  </p>
                </div>
                <div class="p-md-6">
                  <h4 class="feature-title sp-color1">
                    28 Seconds audio for Voice Broadcasting and 23 Seconds audio
                    for IVR which runs under 30 seconds pulse:
                  </h4>
                  <p class="sp-text2">
                    Our platform offers tailored solutions for voice
                    broadcasting and interactive voice response (IVR). For voice
                    broadcasting, you can make a 28
                    seconds of audio content can be efficiently delivered to
                    your audience. This feature is perfect for conveying
                    important messages, promotional content, or announcements
                    effectively. On the other hand, our IVR service allows a
                    23-second audio, where the first 23 seconds are dedicated to
                    providing information or prompts to the caller, and the
                    remaining 7 seconds are reserved for capturing user input.
                    This balanced approach streamlines IVR interactions,
                    ensuring that users receive clear information and have ample
                    time to respond, making your campaigns more engaging and
                    user-friendly. These services provide the flexibility needed
                    to cater to your voice communication needs effectively,
                    whether for broadcasting messages or conducting interactive
                    surveys and data collection.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="sp-container  sp-container-fluid voice-sms-w ">
            <div class="sp-p15 container">
              <h2 class="main-heading">Voice SMS</h2>
              <p class="sp-text1">
                Everyone knows the importance of transactional SMS for any kind
                of business. So many companies are tending to avail this service
                to make a healthy and relations with their clients. These SMS
                provides the entire details to the customers regarding their
                status, account balance, services availed etc. in this way a
                client can avail the cheapest and efficient SMS services. Mostly
                banks, telecom sector, insurance companies, E-commerce companies
                are the user of these type of SMS services. Here are some
                advantages of voice SMS.
              </p>
              <ul class="sp-points sp-text2">
                <li>Broadcast your voice message instantly.</li>
                <li>
                  Get instant response using interactive features (User pressing
                  key for response).
                </li>
                <li>
                  Real-time reporting to measure the response of the campaign.
                </li>
                <li>This service features 24*7 SMS delivery facility.</li>
                <li>
                  Schedule your Voice campaigns at predefined date & time.
                </li>
                <li>
                  Some small-scale companies can also enhance and promote their
                  business.
                </li>
              </ul>
              <p class="sp-text2">
                Voice SMS brings business and hence are being used as an
                effective marketing tool these days. If the message is conveyed
                in local language, it attracts more visitors. Outbound Dialers
                are therefore the right choice for your all business needs. Our
                voice SMS solutions are designed to make communication
                personalized and effective.
              </p>
            </div>
          </div>
          <div class="sp-container container sp-container-fluid sp-p15">
            <h2 class="main-heading">Interactive Voice response(IVR)</h2>
            <div class="p-grid">
              <div class="p-md-8">
                <p class="sp-text2">
                  IVR systems use pre-recorded or synthesized voice messages to
                  guide users through a menu of options. These prompts can be
                  customized to provide information, request input, or offer
                  choices.
                </p>

                <p class="sp-text2">
                  IVR utilizes a combination of voice input and dial pad tone
                  selection to trigger appropriate responses, like voice,
                  automated messaging, call-back, emails, and other media.
                </p>
                <ul class="sp-points sp-text2">
                  <li>
                    <b>Keypad Inputs:</b> Users respond to IVR prompts by
                    pressing keys on their telephone's keypad. These inputs help
                    users navigate menus, select options, and provide
                    information like account numbers or PINs.
                  </li>
                  <li>
                    <b>Database Integration:</b> IVR systems often connect to
                    databases and customer relationship management (CRM)
                    systems. This allows them to access and update customer
                    information, account details, and transaction histories in
                    real-time.
                  </li>
                  <li>
                    <b>Call Routing:</b> IVRs can route calls to the appropriate
                    department or agent based on user inputs or specific
                    criteria. For instance, a caller can select "1" for sales,
                    "2" for support, and so on
                  </li>
                  <li>
                    <b>Personalization:</b> IVR systems can provide a
                    personalized experience by identifying callers using caller
                    ID or by asking for authentication through account numbers
                    and PINs. This ensures tailored responses and quicker issue
                    resolution.
                  </li>
                </ul>
                <h4 class="feature-title sp-color1">Benefits of IVR Systems</h4>
                <ul class="sp-points sp-text2">
                  <li>
                    <b>24/7 Availability:</b> IVR systems can handle incoming
                    calls around the clock, providing customers with the ability
                    to get information or perform certain tasks even outside of
                    regular business hours.
                  </li>
                  <li>
                    <b>Efficiency:</b> IVRs can quickly direct calls to the
                    appropriate department or agent, reducing call waiting times
                    and improving overall service efficiency.
                  </li>
                  <li>
                    <b>Consistency:</b> IVR systems provide a consistent and
                    standardized experience to callers, ensuring that all
                    customers receive the same level of service and information.
                  </li>
                  <li>
                    <b>Data Collection:</b> IVR systems can collect and store
                    data about customer interactions, helping businesses improve
                    their services and gain insights into customer behavior.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="sp-container container sp-container-fluid sp-p15">
            <h2 class="main-heading">Live Reports </h2>
            <div class="p-grid">
              <div class="p-md-8">
                <p class="sp-text2">
                  Live reports on bulk voice calls provide real-time insights
                  and information about the progress, results, and key metrics
                  of a bulk voice call campaign. These reports are essential for
                  businesses and organizations that use bulk voice calls as a
                  means of communication with a large audience for various
                  purposes, such as marketing, notifications, reminders,
                  surveys, and more.
                </p>

                <ul class="sp-points sp-text2">
                  <li>
                    <b> Call Progress:</b> Live reports display the progress of
                    the bulk voice call campaign, indicating the number of calls
                    initiated, the number of calls answered, and the number of
                    calls still in progress. This information allows campaign
                    managers to gauge the effectiveness of the campaign in real
                    time.
                  </li>
                  <li>
                    <b>Call Status:</b> These reports provide detailed
                    information about the status of each call, such as whether
                    the call was answered, went to voicemail, was busy, or
                    encountered other issues. This data helps in understanding
                    call completion rates and call quality.
                  </li>
                  <li>
                    <b> Delivery Rate:</b> Live reports may show the delivery
                    rate, which is the percentage of calls that successfully
                    reached their intended recipients. This metric is crucial
                    for assessing the campaign's reach and impact.
                  </li>
                  <li>
                    <b>Response Rate:</b> For interactive voice campaigns that
                    require user input (e.g., press 1 for more information),
                    live reports can display the response rate, showing how many
                    recipients engaged with the call and took action as per the
                    campaign's instructions.
                  </li>
                  <li>
                    <b>Geographic Data:</b> Some live reports offer geographic
                    insights, indicating where calls were answered or providing
                    a breakdown of call performance by region. This can be
                    valuable for targeting and campaign optimization.
                  </li>
                  <li>
                    <b>User-Friendly Dashboards:</b> Live reports are typically
                    presented in user-friendly dashboards that provide a visual
                    overview of the campaign's performance, making it easy for
                    users to track and analyze data.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="sp-container container sp-container-fluid sp-p15">
            <h2 class="main-heading">IVR Surveys</h2>
            <div class="p-grid">
              <div class="p-md-8">
                <p class="sp-text2">
                  IVR (Interactive Voice Response) surveys are an invaluable
                  tool for collecting feedback and conducting market research
                  using automated telephone interactions. When integrated with
                  live reports, they become a powerful system for real-time data
                  analysis and decision-making.
                </p>

                <ul class="sp-points sp-text2">
                  <li>
                    <b> Automated Surveys:</b> IVR surveys use automated voice
                    prompts to ask respondents questions, which they answer
                    using their telephone keypad.
                  </li>
                  <li>
                    <b>High Response Rates</b> People tend to be more willing to
                    participate in IVR surveys due to their convenience and
                    anonymity.
                  </li>
                  <li>
                    <b> Data Collection:</b> IVR surveys collect responses in
                    real time, which can be stored for later analysis.
                  </li>
                  <li>
                    <b>Real-Time Monitoring</b> Live reports provide real-time
                    monitoring of the IVR survey progress, offering insights
                    into how many surveys have been completed, how many are in
                    progress, and the number of attempts.
                  </li>
                  <li>
                    <b>Graphical Representation:</b>Live reports use various
                    graphical elements like charts, graphs, and heatmaps to
                    visualize survey data, making it easier to identify trends
                    and patterns.
                  </li>
                  <li>
                    <b>Dashboard Integration:</b> Live reports often feature
                    customizable dashboards where you can track the progress and
                    results of multiple IVR survey campaigns in one place.
                  </li>
                  <li>
                    <b>Immediate Feedback:</b>IVR surveys, coupled with live
                    reports, allow organizations to receive feedback and
                    insights in real time. This enables prompt action in
                    response to emerging trends or issues.
                  </li>
                  <li>
                    <b>Improved Decision-Making:</b>Decision-makers can access
                    up-to-the-minute survey results and respond to changing
                    circumstances or customer sentiment swiftly.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="sp-container container sp-container-fluid sp-p15">
            <h2 class="main-heading">Missed Call Services</h2>
            <div class="p-grid">
              <div class="p-md-8">
                <p class="sp-text2">
                  missed call services are a unique and cost-effective
                  communication strategy commonly used in various parts of the
                  world, especially in emerging markets. This approach involves
                  using a specific phone number for people to give a missed
                  call, and in return, they receive valuable information or
                  services
                </p>

                <ul class="sp-points sp-text2">
                  <li>
                    <b>The Missed Call:</b> Users dial a designated phone number
                    and hang up before the call is answered. The call is
                    registered as a missed call on the service provider's
                    system.
                  </li>
                  <li>
                    <b>Response: </b>Upon receiving the missed call, the service
                    provider's system processes it and triggers an automated
                    response. This response can take various forms, depending on
                    the purpose of the service.
                  </li>
                  <li>
                    <b>Information or Service:</b> The automated response can
                    include information or services such as news updates,
                    weather forecasts, exam results, job alerts, product
                    promotions, or access to certain content or applications.
                  </li>
                  <li>
                    <b> Polls and Surveys:</b> Missed call numbers are also used
                    for conducting polls and surveys, allowing organizations to
                    gather data and feedback from a large number of respondents.
                  </li>
                  <li>
                    <b>Charity and Social Causes:</b>Non-profit organizations
                    use missed call numbers for donation campaigns. People can
                    give a missed call to contribute to a charitable cause or
                    express support for a social issue.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div class="sp-container container sp-container-fluid sp-p15">
            <h2 class="main-heading">SMS API</h2>
            <p class="sp-text2">
              Bulk SMS gateway is a way to integrate your existing setup with
              SMS APIs. It’s important to choose the right bulk sms gateway that
              provides high quality, real time delivery reports and
              transparency. We are reliable bulk SMS gateway providers who work
              to contribute to your long term success. Our SMS gateway easily
              integrates with any system to communicate with customers. our SMS
              gateway helps you to improve customer base, engagement levels,
              sales, audience’s loyalty.
            </p>

            <ul class="sp-points sp-text2 sp-mb50">
              <li>
                SMS API enables your application or website to send an SMS and
                receive SMS messages at any trigger point reset now your system
                could automatically send a reminder for you or alert when it
                isn’t running the way it should be integration is really easy
              </li>
              <li>
                Automatic messages can be send to a large group of people at
                once . API SMS is used by a lot of E-commerce portals these days
                as it is the most secured way to reach our customers. API SMS
                can also be used to deliver bulk OTP SMS
              </li>
              <li>
                Use flexible APIs to send SMS from a website in just a few
                seconds . Avoid multiple vendors or steps
              </li>
              <li>
                Get things done faster by using our SMS APIs to sync in existing
                CRM systems to ensure delivery to all contacts
              </li>
              <li>
                Include API in your apps and fulfil your SMS marketing needs
                right from your android application & devices.
              </li>
              <li>
                Quick Delivery Report : Get quick insights & keep track of all
                SMS sent
              </li>
            </ul>
          </div> */}
        </ClientHeader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orgName: state?.orgData?.orgName,
});

export default connect(mapStateToProps, {})(withRouter(Voice));
