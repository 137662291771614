import React, { Component } from 'react'
import ClientHeader from './clientHeader'

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';


class AboutUs extends Component {



    componentDidMount() {
        window.scrollTo(0, 0)
    }




    render() {
        return (
            <>
                <ClientHeader >
                    <div class="sp-container container">
                        <div class="sp-container-fluid ">
                            <div class="row">
                                <div class="col-md-6">
                                    <h2 class="sp-color1">Welcome to</h2>
                                    <h1 class="sp-color1">{this.props.orgName}</h1>
                                    <p class="ma-mt0 sp-text1">Bulk SMS / Email service provider hepls you in communicating to
                                        massive sms, mails and voice sms.
                                    </p>
                                </div>
                                <div class="col-md-6"></div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="sp-text2">We help companies to implement SMS services to increase revenue, improve
                                        branding,
                                        optimize business performance, and develop public services through our serices. we came
                                        forward to strive your trouble. we provide services like Transactional, Promotinal SMS, DND
                                        open route,voice sms,bulk email.
                                    </p>

                                    <ul class="sp-points sp-text2">
                                        <li>We are cloud based service providers with 100% uptime and security</li>
                                        <li>We use smart algorithm to deliver your SMS/Email</li>
                                        <li>We assure to deliver at 99.99%</li>
                                        <li>We Provide Read Reciepts of EMail and Delivery Reports of SMS.</li>
                                    </ul>
                                </div>

                            </div>

                            <div class="row ma-mt50">
                                <div class="col-md-12">
                                    <h2 class="sp-color1 ">Why Us?</h2>
                                    <p class="sp-text1">Our vision is to be a reliable to your requirements through our service and
                                        stand out in the
                                        industry.
                                        Furtherly, we want to offer excellent services with the strength of our whole ability with
                                        innovative
                                        thing.
                                    </p>
                                    <br />
                                    <ul class="sp-points sp-text2">
                                        <li>Powerfull Campaign System</li>
                                        <li>Real time reports</li>
                                        <li>Quick, Flexible & Scalable APIs</li>
                                        <li>Best delivery report</li>
                                        <li>Real time reports</li>
                                        <li>Afordable price</li>
                                        <li>Easy access</li>
                                        <li>Great Support</li>
                                        <li>Safe and secure service provider</li>
                                    </ul>
                                </div>

                                <div class="col-md-6"></div>
                            </div>

                            <div class="row ma-mt50">
                                <div class="col-md-12">
                                    <h2 class="sp-color1 ">Our Best Class services</h2>
                                    <p class="sp-text2">We are offering the below services. </p>
                                    <br />
                                    <ul class="sp-points sp-text2">
                                        <li>Promotional SMS</li>
                                        <li>Transactional SMS</li>
                                        <li>Bulk EMail</li>
                                        <li>Voice Services</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </ClientHeader>
            </>
        )
    }
}




const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});


export default connect(mapStateToProps, {
})(withRouter(AboutUs));


