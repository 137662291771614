import React, { Component } from 'react'
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Authentication from './../../../session/authorize'
import { Toast } from 'primereact/toast';
import { getFormFields, isFormValid, onTextChange } from 'src/Utils/formHelper';
import LoadingComponent from 'src/LoadingComponent';
import Service from 'src/Service';
import { trimObj } from 'src/Utils';
import Header from 'src/Header';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import './styles.scss'
var formFields = require('./pe.json');

class EmailConfig extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, {});


        this.state = {
            peConfig: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isEdit: false,
            isConfigured: true,
            isVerified: false,
            orgId: this.props.orgId,
            isNotAdded: false

        }
        this.service = new Service();
    }

    componentDidMount() {
        this.getConfig(this.props.orgId);

    }


    componentDidUpdate(prevProps) {
        if (prevProps.orgId != this.props.orgId)
            this.getConfig(this.props.orgId);

    }



    getConfig = (orgId) => {
        this.setState({
            isLoading: true
        })
        const url = `/admin/get-pe-details`;
        this.service.get(url, true).then((res) => {


            if (res && res.res && res.res.status) {

                if (res.res.data.peId) {
                    this.setState({
                        isLoading: false,
                        peConfig: res.res.data
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        isNotAdded: true
                    })
                }



            } else {


                //  this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });


            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });


    }



    onSubmitClick = () => {
        const { currentStep, peConfig } = this.state;
        let submitData = trimObj(peConfig)
        const formStatus = isFormValid(formFields, this.formFields.formValidations, submitData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });
        } else {
            this.setState({
                isLoading: true
            });

            const url = `/admin/add-pe-details`;
            this.service.post(url, submitData, true).then((res) => {

                if (res && res.res && res.res.status) {
                    this.setState({
                        isLoading: false,
                        peConfig: res.res.data,
                        isEdit: false,
                        isNotAdded: false

                    })
                } else {

                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }


            }).catch(e => {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                this.setState({
                    isLoading: false
                });
            });
        }
    }

    render() {
        const { peConfig, formValidations, isEdit, isNotAdded } = this.state;
        //   console.log(this.state.peConfig, '');
        return (

            <Header >
                <div className='sp-main sp-card sp-p20 sp-m20 pe-config'>
                    {isEdit ? <>
                        <div className='p-grid ma'>
                            <div className='p-col-12 p-md-4'>
                                <div>
                                    <p className='sp-label-s1 sp-m2'>Prinicipal Entity<span className='sp-required'>*</span></p>
                                    <InputText value={peConfig.peId}
                                        onChange={(e) => { onTextChange(e.target.value, 'peId', this, formFields, peConfig, formValidations, 'peConfig', 'formValidations') }}
                                        className='p-inputtext-style1 sp-w100p' />
                                    {formValidations && !formValidations.fields['peId'].isValid && <p className="p-error">{formValidations.fields['peId'].errorMsg}</p>}
                                </div>
                                <div className='sp-mt20' >
                                    <p className='sp-label-s1 sp-m2'>PE expire date<span className='sp-required'>*</span></p>
                                    <Calendar
                                        showIcon={true}
                                        placeholder='Start Date'
                                        value={(peConfig.peExpireDate && peConfig.peExpireDate.length) ? new Date(peConfig.peExpireDate) : null}
                                        name='peExpireDate'
                                        id='fromDates'
                                        minDate={new Date()}
                                        className='sp-w100p'
                                        onChange={(e) => { onTextChange(e.value.toString(), 'peExpireDate', this, formFields, peConfig, formValidations, 'peConfig', 'formValidations') }}
                                    //className='sp-w100p'
                                    ></Calendar>
                                    {formValidations && !formValidations.fields['peExpireDate'].isValid && <p className="p-error">{formValidations.fields['peExpireDate'].errorMsg}</p>}
                                </div>

                                <Button label={'Save'} className='sp-mt10 ' onClick={this.onSubmitClick} />
                                <Button label={'Cancel'} className='sp-mt10 sp-mlr10 p-button-outlined' onClick={() => {
                                    this.setState({
                                        isEdit: false
                                    })
                                }} />

                            </div>
                        </div>
                        <p className='upload-note sp-mt20'><span>Note:</span> Please enter entity Id as registered in DLT. Otherwise messages will not be delivered</p>
                    </> : <>

                        {
                            isNotAdded ? <> <p className='no-config-txt'>No Configuration Added. Please add</p>
                                <Button label={'Add'} className='sp-mt10' onClick={() => {
                                    this.setState({
                                        isEdit: true
                                    })
                                }} />
                            </> : <div className='p-grid'>
                                <div className='p-col-6 p-md-3'>
                                    <p className='config-txt'><b>Prinicipal Entity : </b>{peConfig.peId}
                                        <Button onClick={() => {
                                            this.setState({
                                                isEdit: true
                                            })
                                        }} icon="pi pi-pencil" className="sp-right p-button-rounded p-button-secondary p-button-text" aria-label="Edit" /></p>
                                    <p className='config-txt'><b>PE expire date : </b>{moment(peConfig.peExpireDate).format('LL')}</p>
                                </div>

                            </div>
                        }

                    </>}
                    {
                        this.state.isLoading && <LoadingComponent />
                    }
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />

                </div>
            </Header>
        )
    }
}



// const mapStateToProps = (state) => ({
//     // peConfig: state.orgData.peConfig,
// });


// export default connect(mapStateToProps, {
// })(Authentication(EmailConfig));



export default EmailConfig;