import * as React from "react";


import AWS from 'aws-sdk';
import { S3_UPLOAD_CONFIG } from "src/Utils/constants";

const s3Bucket = new AWS.S3({
    accessKeyId: S3_UPLOAD_CONFIG.accessKeyId,
    secretAccessKey: S3_UPLOAD_CONFIG.secretAccessKey,
    region: S3_UPLOAD_CONFIG.region,

});

class DirectFileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadedFileInfo: [],
            uploadedFilesInfo: [],
            accept: this.props.accept,
            maxFileSize: this.props.maxFileSize ? this.props.maxFileSize : 1073741824
        }

    }

    uploadfile = (fileName, file, folderName, onSucess) => {
        const params = {
            Bucket: S3_UPLOAD_CONFIG.bucketName,
            Key: folderName + fileName,
            Body: file,
            ContentType: file.type
        };
        return s3Bucket.upload(params, function (err, data) {

            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            onSucess(file.name, data);
            return true;
        });
    }


    onSucess = (fileName, data) => {
        this.props.onSucess(fileName, data)
    }


    onProgress = (some) => {
        this.props.onProgress(some);
    }

    onChangeHandler = (event, onSucess, onProgress) => {
        let canUpload = true;
        let errMsg = '';
        if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                /// 300mb == 314572800 
                /// 200mb == 209715200
                if (event.target.files[i].size > this.state.maxFileSize) {
                    canUpload = false;
                    errMsg = `Only ${this.state.maxFileSize / 1048576}MB files are allowed.`
                }
                if (!this.getAcceptString(this.props.accept).includes(event.target.files[i].type)) {
                    canUpload = false;
                    errMsg = `Only ${this.props.accept} are allowed`;
                }
            }
            // if (event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0) {
            if (canUpload) {
                this.props.afterFilesUploaded(event.target.files);
                for (let i = 0; i < event.target.files.length; i++) {
                    let needToUpload = true;
                    if (this.state.uploadedFileInfo.length > 0) {
                        for (let j = 0; j < this.state.uploadedFileInfo.length; j++) {
                            if (event.target.files[i].name === this.state.uploadedFileInfo[j].data.originalName) {
                                needToUpload = false;
                            }
                        }
                    }
                    if (needToUpload) {
                        let file = event.target.files[i];
                        let newFileName = new Date().getTime() + file.name;

                        let fileUpload = {
                            id: "",
                            name: file.name,
                            newFileName: newFileName,
                            size: file.size,
                            type: file.type,
                            timeReference: 'Unknown',
                            progressStatus: 0,
                            displayName: file.name,
                            status: 'Uploading..',
                        }


                        this.uploadfile(newFileName, file, S3_UPLOAD_CONFIG.dirName, onSucess)
                            .on('httpUploadProgress', function (progress) {
                                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                                if (progressPercentage < 100) {
                                    fileUpload.progressStatus = progressPercentage;

                                } else if (progressPercentage == 100) {
                                    fileUpload.progressStatus = progressPercentage;

                                    fileUpload.status = "Uploaded";

                                }
                                onProgress(fileUpload);
                            })
                    }
                }
            } else {
                this.props.onError(errMsg);
            }
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.Reset === true) {
            this.setState({
                uploadedFileInfo: [],
                uploadedFilesInfo: []
            });
        }
    }

    getAcceptString(accept) {
        // if (accept) {
        //     if (accept == "images") {
        //        // return 'image/*'
        //        return ['image/jpeg', 'image/png', 'image/jpg'];
        //     } else if (accept == "audios") {
        //         return ['audio/mpeg'];
        //     } else if (accept == "videos") {
        //         return ['video/mp4', 'video/quicktime'];
        //     } else if (accept == "pdfs") {
        //         return ['application/pdf']
        //     } else {

        //         return 'audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint';
        //     }
        // } else {
        //     return 'audio/*,video/*,image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint';
        // }
        if (accept) {
            if (accept == "images") {
                //return 'image';
                return ['image/jpeg', 'image/png', 'image/jpg'];
            } else if (accept == "audios") {
                return ['audio/mpeg'];
            } else if (accept == "videos") {
                return ['video/mp4', 'video/quicktime'];
            } else if (accept == "pdfs") {
                return ['application/pdf'];
            } else if (accept == "imagevideo") {
                return ['video/mp4', 'video/quicktime', 'image/jpg', 'image/jpeg', 'image/png']
            } else {
                return [];
            }
        } else {
            return []
        }
    }

    render() {
        return (
            <>
                <label htmlFor={this.props.id} title={this.props.title ? this.props.title : 'add'} >
                    {this.props.children}
                </label>
                <input type="file" disabled={this.props.disabled} hidden={true} accept={this.getAcceptString(this.props.accept)} name="files" id={this.props.id} multiple={this.props.multiple} className="ma-file" onChange={(event) => this.onChangeHandler(event, this.onSucess, this.onProgress)} />
            </>
        );
    }
}

export default DirectFileUpload;
