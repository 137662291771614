import React, { Component } from 'react'
import ClientHeader from './clientHeader'
import { Accordion, AccordionTab } from 'primereact/accordion';


export default class Faq extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <ClientHeader >
                    <div class="sp-container container ">
                        <div class="sp-container-fluid ">
                            <div class="row ">
                                <div class="col-md-12">
                                    <h2>COMMON FAQ's</h2>
                                    <Accordion >
                                        <AccordionTab header="What is the purpose of bullk sms service?">
                                            <p>Its uses are endless. Bulk SMS can be deployed wherever we need to build an instant connection and
                                                convey urgent information.</p>
                                        </AccordionTab>
                                        <AccordionTab header="How many messages i can send per Campaign">
                                            <p>There’s no limit on the number of messages you can send per campaign.</p>
                                        </AccordionTab>
                                        <AccordionTab header="Does you provide API's ?">
                                            <p>yes, you will get access to APIs once you signup.</p>
                                        </AccordionTab>
                                        <AccordionTab header="What is the use of sending BULK SMS's ?">
                                            <p>Bulk SMS is the fastest mode of communication to send the data to the interested
                                                client on a specific number. Bulk SMS are the rapid way to connect with the user.
                                                Business is all about creating contacts and providing affordable services to the
                                                contacts. Be it an individual or any organisation, Bulk SMS makes it simple for the
                                                user to stay in touch.</p>
                                        </AccordionTab>
                                        <AccordionTab header="What is the percentage of Inbox delivery?">
                                            <p>Inbox delivery depends on multiple factors.</p>
                                            <p>ISPs such as (i.e. Gmail, Yahoo etc) will consider many things for your Inbox
                                                delivery such as your domain/IPs/server reputation, your email content, your
                                                email
                                                list, sending volumes per hour/ per day towards their user’s email IDs, Users
                                                reactions towards your emails etc.
                                            </p>
                                            <p>
                                                We also seen that for different users it works differently means if for one user
                                                it
                                                will delivery into the Inbox, so for other users it could be delivered to
                                                promotion
                                                or spam folder.
                                            </p>
                                            <p>
                                                It will totally depend on that particular user’s past behaviors towards such
                                                emails)
                                                ISPs will react based on users behaviors as well.
                                            </p>
                                            <p>
                                                Initially, it could have happened that your email will delivery to the spam
                                                folder
                                                since your domain/server/IP will be a new setup and ISPs will take time to
                                                recognize
                                                your domain/IPs as an email sender.
                                            </p>
                                            You have to follow IP warm up the process in order to get good delivery with a new
                                            setup server. So after warm up, you can reach to ISP’s Inbox but at the same the
                                            time you also have to work on your email content and contact list.
                                            <p>
                                                Our customer is getting from 5 to 80% open-rates (we can measure the delivery
                                                based
                                                on the number of open/read emails ).
                                            </p>
                                            <p>
                                                This variation in delivery rate is completely based on the above-mentioned
                                                factors.
                                            </p>
                                        </AccordionTab>
                                    </Accordion>

                                </div>
                            </div>
                        </div>
                    </div>
                </ClientHeader>
            </>
        )
    }
}
