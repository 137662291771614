import { Button } from 'primereact/button'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar'
import React, { Component } from 'react'
import Service from 'src/Service';
import { warningDailogInit } from 'src/Utils';
import { BasicLazyParams } from 'src/Utils/constants';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import ServerlessService from 'src/Service/ServerlessService';


export default class AssignEmail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allEmails: [],
            lazyParams: { ...BasicLazyParams, sortField: '-createdAt', sortOrder: 1 },
            totalRecords: 0,
            warningDialog: warningDailogInit,
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    componentDidMount() {
        this.getAppointments()
    }
    getAppointments = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/config/emails?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, {}, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    allEmails: res.res.data,
                    totalRecords: res.res.totalRecords
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'AdminUsers fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }

    assign = (rowData) => {

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You want assign the ${rowData.email}. Please confirm`
            },
            assigneEmail: rowData
        });

    }

    onConfirmChange = () => {
        this.setState({
            isLoading: true,
            warningDialog: warningDailogInit,
        });
        const url = `/admin/assign-mail`;
        this.serverlessService.post(url, { userId: this.props.editAdminUser._id, emailId: this.state.assigneEmail._id }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                }, this.props.onNewlyAssign)

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'AdminUsers fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }


    actionsTemplate = (rowData) => {
        return (<>
            <div className="p-d-flex sp-mlr10 user-edit-t">

                {/* <Authorize permId={PERMISSIONS.CONFIGURATIONS_EDIT} > */}
                <span data-pr-tooltip="Assign" data-pr-position="bottom" className={`sp-ml10 edit${rowData._id.substr(2)}`} onClick={() => { this.assign(rowData) }}>
                    <i className='pi pi-check' />
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(2)}`} />
                {/* </Authorize> */}

            </div>
        </>
        );
    }
    render() {


        const header2 = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >All Emails</h2>
                    {/* 
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 1 ? 'p-button-outlined' : ''}`} label={'Booked'} onClick={() => this.onChangeStatusType(1)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 2 ? 'p-button-outlined' : ''}`} label={'Visisted'} onClick={() => this.onChangeStatusType(2)} />
                    <Button className={`sp-ml10 cont-btn ${currentFilter != 3 ? 'p-button-outlined' : ''}`} label={'Cancelled'} onClick={() => this.onChangeStatusType(3)} /> */}

                    {/* {this.state.showSearch && <InputText placeholder="Search" className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />} */}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        {/* <Authorize permId={PERMISSIONS.ADMIN_USER_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        </Authorize> */}
                        {/* <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" /> */}
                    </ul>
                </div>
            </div>
        );

        return (<div>
            <div>







                <div className="card sp-datatable sp-m30 ">
                    {/* <LazyTable ></LazyTable> */}
                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        scrollable
                        responsiveLayout="scroll"
                        //  autoLayout={true}
                        rowHover
                        value={this.state.allEmails}
                        // selection={this.state.selectedProducts}
                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                        dataKey="_id"
                        //</div>={true}
                        // lazy
                        // onSort={this.onSort}
                        // onPage={this.onPage}
                        // onFilter={this.onFilter}
                        // first={this.state.lazyParams.first}
                        // last={this.state.totalRecords}
                        // rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        paginator
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        // globalFilter={this.state.globalFilter}
                        header={header2}

                    >
                        <Column field="email" header="Email" />

                        <Column
                            // headerClassName='option-2'
                            //headerStyle={{ width: "520px",  }}
                            //headerStyle={{ display: 'inline-block   ' }}
                            body={this.actionsTemplate}
                            header="Actions" >


                        </Column>
                    </DataTable>

                </div>
                <Toolbar className="sp-toolbar"
                    right={() => {
                        return (
                            // <Authorizer permId={PERMISSIONS.EMAIL_COMPAIGN_REFRESH} >
                            <Button label="Cancel" className='p-button-outlined sp-mlr10'
                                onClick={this.props.onCancel} />
                            // </Authorizer>
                        )
                    }}



                ></Toolbar>


                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='sp-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, assigneEmail: null }) }} />
                            <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, assigneEmail: null })}>
                    <div>
                        {/* 
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 382.865 348.356">
                            <g id="warning" transform="translate(0 -20.359)">
                                <path id="Path_3387" data-name="Path 3387" d="M378.275,314.472,222.781,38.439c-13.259-24.107-49.421-24.107-62.68,0L4.606,314.472c-13.259,24.107,3.616,54.242,31.34,54.242H345.73C374.659,367.509,391.534,338.58,378.275,314.472Z" transform="translate(0 0)" fill="#e24c4b" />
                                <path id="Path_3388" data-name="Path 3388" d="M197.907,63.025,353.4,339.058H42.413L197.907,63.025Z" transform="translate(-6.466 -6.505)" fill="#fff" />
                                <g id="Group_1556" data-name="Group 1556" transform="translate(166.037 154.157)">
                                    <path id="Path_3389" data-name="Path 3389" d="M196,207.155l9.643,63.885A15.681,15.681,0,0,0,221.31,284.3h0c7.232,0,14.465-6.027,15.67-13.259l9.643-63.885c2.41-15.67-9.643-28.929-25.313-28.929h0C205.639,178.225,194.79,191.484,196,207.155Z" transform="translate(-195.905 -178.226)" fill="#3f4448" />
                                    <circle id="Ellipse_49" data-name="Ellipse 49" cx="14.465" cy="14.465" r="14.465" transform="translate(10.939 119.332)" fill="#3f4448" />
                                </g>
                            </g>
                        </svg> */}

                    </div>
                    <p className='sp-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='sp-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
            </div>
        </div>
        )
    }
}
