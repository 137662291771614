import React, { Component } from 'react'
import { OPEN_API_ENDPOINT } from 'src/Service/config'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


const paramsData = [
    {
        param: 'apikey',
        paramName: 'API Key',
        description: 'API Key is generated from portal.'
    },
    {
        param: 'messageid',
        paramName: 'message ID',
        description: 'Pass message Id recieved on sent API.'
    },

]


const optionalParamsData = [

]

export default class GetStatusAPI extends Component {


    render() {
        return (
            <div className='sp-m20 api-details'>
                <h2>API Details (http request)</h2>

                <div className='sp-m10 p-card sp-p20'>
                    <p className='config-txt  config-url sp-txt-overflow'><b>URL:</b><span>  {OPEN_API_ENDPOINT + '/sms/status'}</span></p>
                    <p className='config-txt'><b>Request Type:</b> POST</p>
                    <p className='config-txt'><b>Payload (Body):</b></p>
                    <pre>
                        {
                            JSON.stringify({
                                "apikey": "0998877665544332211",
                                "messageid": "632a3c61b3ac6265f0c219ff"
                            }, undefined, 4)
                        }
                    </pre>
                </div>

                <h3 style={{ textAliign: 'center' }} className='sp-m10'>Or</h3>

                <div className='sp-m10 p-card sp-p20'>
                    <p className='config-txt config-url sp-txt-overflow'><b>URL:</b> <span>{OPEN_API_ENDPOINT + '/sms/send?apikey=0998877665544332211&messageid=632a3c61b3ac6265f0c219ff'}</span> </p>
                    <p className='config-txt'><b>Request Type:</b> GET</p>
                </div>

                <h2>Mandatory Params</h2>
                <DataTable value={paramsData} responsiveLayout="scroll">
                    <Column field="param" header="Parameter"></Column>
                    <Column field="paramName" header="Name"></Column>
                    <Column field="description" header="Description"></Column>
                </DataTable>


                {/* <h2>Optional Params</h2>
                <DataTable value={optionalParamsData} responsiveLayout="scroll">
                    <Column field="param" header="Parameter"></Column>
                    <Column field="paramName" header="Name"></Column>
                    <Column field="description" header="Description"></Column>
                </DataTable> */}
            </div>
        )
    }
}
