import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import Authentication from '../session/index'
import { withRouter } from 'react-router-dom';
import { saveUserSession } from 'src/store/actions';
import ServerlessService from 'src/Service/ServerlessService';
import { SERVERLESS_URL } from 'src/Service/config';
import LoadingComponent from 'src/LoadingComponent';

class UserCreditsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            credits: null
        }
        this.serverlessService = new ServerlessService();

    }


    initiateGetSession = () => {

        let userType = localStorage.getItem('userType');


        if (userType) {
            this.setState({
                isLoading: true
            })

            const url = `${SERVERLESS_URL}/admin/session`;

            let config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
                }
            };
            axios.get(url, config)
                .then(res => {
                    if (res && res.data) {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.saveUserSession(res.data);
                        })

                    } else {
                        //   this.props.sessionsChecked();
                        this.setState({
                            isLoading: false
                        })
                    }
                }).catch((err) => {
                    this.setState({
                        isLoading: false
                    })


                });
        } else {
            //    this.props.sessionsChecked();
        }
    }
    componentDidMount() {
        this.initiateGetSession()


    }


    componentDidMount() {
        this.initiateGetSession()
    }
    // getCredits = () => {
    //     this.setState({
    //         isLoading: true,
    //     });
    //     const url = `/admin/get-credits`;
    //     this.serverlessService.get(url, true).then((res) => {


    //         if (res && res.status && res.res.status) {
    //             this.setState({
    //                 isLoading: false,
    //                 credits: res.res.data
    //             })

    //             // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates fetched successfully.', life: 3000 });
    //         } else {

    //             this.setState({
    //                 isLoading: false
    //             });
    //             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
    //         }

    //     }).catch(e => {
    //         console.log(e);
    //         this.setState({
    //             isLoading: false
    //         });
    //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
    //     })


    // }


    render() {
        return (<div className='sp-card credit-panel'>
            <h3>Available Credits to Logged In User</h3>

            <p>SMS Credits:  <b>{this.props?.user?.user?.smsLimit} </b> </p>
            <p>Email Credits:  <b>{this.props?.user?.user?.emailLimit}</b> </p>
            <p>.</p>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />

            {
                this.state.isLoading && <LoadingComponent />
            }
        </div>
        )
    }
}



const mapStateToProps = (state) => ({
    user: state.user,

});


export default connect(mapStateToProps, {
    saveUserSession
})(Authentication(withRouter(UserCreditsPanel)));



