import React, { Component } from 'react'
import LoadingComponent from 'src/LoadingComponent'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import creditsFields from './credits.json';
import Service from 'src/Service';
import ServerlessService from 'src/Service/ServerlessService';
import { InputText } from 'primereact/inputtext';
import { trimObj } from 'src/Utils';
import CreditPanel from 'src/HomePage/creditPanel';

export default class CreditManagement extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(creditsFields, { smsLimit: "0", emailLimit: "0" });
        this.state = {
            isLoading: false,
            credits: this.formFields.data,
            formValidations: this.formFields.formValidations,
        }

        this.serverlessService = new ServerlessService();
    }




    footer = () => {
        return (<div>
            <Button label={'Add Credits'} className='sp-m-lr10'
                onClick={this.addCredits}
            />
        </div>)
    }


    addCredits = async () => {
        let credits = trimObj(this.state.credits);
        const formStatus = isFormValid(creditsFields, this.formFields.formValidations, credits);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            let url = `/admin/add-credits`
            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, {
                ...credits,
                userId: this.props.editAdminUser._id
            }, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.onCreditsAdd();
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    render() {
        const { credits, formValidations } = this.state;
        return (
            <Dialog
                header={`Credit Mangement`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={false}
                dismissableMask={false}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '500px', }}
                onHide={this.props.onHide}>
                <CreditPanel />
                <p className='sp-label-s1 sp-mt10 sp-no-pm'>SMS Limit<span className='sp-required'>*</span></p>
                <InputText className='sp-w100p p-inputtext-style1'
                    autoComplete="off" value={credits.smsLimit}
                    onChange={(e) => { onNumberChange(e.target.value, 'smsLimit', this, creditsFields, credits, formValidations, 'credits', 'formValidations') }} />
                {formValidations && !formValidations.fields['smsLimit'].isValid && <p className="p-error">{formValidations.fields['smsLimit'].errorMsg}</p>}
                <p className='sp-label-s1 sp-mt10 sp-no-pm'>Email<span className='sp-required'>*</span></p>
                <InputText value={credits.emailLimit}
                    className='sp-w100p  p-inputtext-style1'
                    onChange={(e) => { onNumberChange(e.target.value, 'emailLimit', this, creditsFields, credits, formValidations, 'credits', 'formValidations') }} />
                {formValidations && !formValidations.fields['emailLimit'].isValid && <p className="p-error">{formValidations.fields['emailLimit'].errorMsg}</p>}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        )
    }
}
