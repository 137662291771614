export const ACTIONS = {
    SESSION_CHECKED: "SESSION_CHECKED",
    SAVE_USER_SESSION: "SAVE_USER_SESSION",
    GET_PERMISSIONS_REQUESTED: 'GET_PERMISSIONS_REQUESTED',
    GET_PERMISSIONS_SUCCEEDED: 'GET_PERMISSIONS_SUCCEEDED',
    GET_PERMISSIONS_FAILED: 'GET_PERMISSIONS_FAILED',




    REQUEST_ORG: "REQUEST_ORG",
    RECEIVED_ORG: "RECEIVED_ORG",
    ERROR_ORG: "ERROR_ORG",
    CLEAR_ORG_DATA: 'CLEAR_ORG_DATA',


    REQUEST_SAVE_PRICING: "REQUEST_SAVE_PRICING",
    RECEIVED_SAVE_PRICING: "RECEIVED_SAVE_PRICING",
    ERROR_SAVE_PRICING: "ERROR_SAVE_PRICING",

    REQUEST_UPDATE_ORG: "REQUEST_UPDATE_ORG",
    RECEIVED_UPDATE_ORG: "RECEIVED_UPDATE_ORG",
    ERROR_UPDATE_ORG: "ERROR_UPDATE_ORG",


    // Get Roles
}