import React, { Component } from 'react'
import ClientHeader from './clientHeader'

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ContactUs extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <ClientHeader >
                    <div class="sp-container container">
                        <div class="sp-container-fluid  ">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-8">
                                    <p class="sp-text1">We will help you to start your journey smoothly, don't hesitate to contact us
                                    </p>
                                    <div class="contact-us-w">

                                        <p class="sp-text2 sp-c-address"><i class="fa fa-home me-3"></i>
                                            {' ' + this.props.address + ', ' + this.props.city + ', ' + this.props.state + ', ' + this.props.pincode + ", India"}


                                        </p>
                                        <p class="sp-text2">
                                            <i class="fa fa-envelope me-3"></i>
                                            <a class="sp-text2" href={ ` mailto:${this.props.contactEmail}`}>
                                                { ' ' + this.props.contactEmail}</a>
                                        </p>
                                        <p class="sp-text2"><i class="fa fa-phone me-3"></i> 
                              <a class="sp-text2" href={`tel:+91-${this.props.contactNumber1}`}>
                                    +91-{ this.props.contactNumber1}</a>
</p>

                                        <p class="sp-text2"><i class="fa fa-phone me-3"></i>
                                            <a class="sp-text2" href={`tel:+91-${this.props.contactNumber2}`}>
                                                +91-{this.props.contactNumber2}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ClientHeader>
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    contactNumber1: state?.orgData?.contactNumber1,
    contactNumber2: state?.orgData?.contactNumber2,
    contactEmail: state?.orgData?.contactEmail,
    address: state?.orgData?.address,
    city: state?.orgData?.city,
    state: state?.orgData?.state,
    pincode: state?.orgData?.pincode,

});


export default connect(mapStateToProps, {
})(withRouter(ContactUs));

