import React, { Component } from 'react'
import ClientHeader from './clientHeader'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';


class CaseStudies extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <ClientHeader >
                    <div class="sp-container container case-studies ">
                        <div class="sp-container-fluid">
                            <h1 class="title-s1">Case Studies</h1>
                            <p>
                                There are many examples of how our customers use Our Services in their daily practice. There are many
                                different
                                companies
                                and departments that use Our Services to assist their business communication and grow their business.

                                We served the needs of nationally recognized clients.
                                There are many different companies/sector/organization using messages to aid and enhance their business
                                advertisement, marketing and communications. If you would like to know how some of these
                                companies/sector/organizations are using our services, then please do take a look at the case studies
                                below by clicking on.
                            </p>
                            <div>

                                <div class="case-study">
                                    <h4>E-Commerce Website or Business</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>E-commerce companies are preferring to use bulk SMS and email to
                                            deliver valuable information and order updates to their clients.</li>
                                        <li>Purchase confirmation can be sent via Bulk SMS </li>
                                        <li>Notify latest product offerings / promotional items</li>
                                        <li>SMS is sent related to product details, invoice details, payment confirmation,
                                            special offers, delivery status of their order and many more </li>
                                        <li>Update customers on the purchase status</li>
                                        <li>. Bulk SMS has become a priority for e-commerce industry and help
                                            them to manage their clients in a far better way.</li>
                                    </ul>
                                </div>

                                <div class="case-study">
                                    <h4>Medical Store / Healthcare /Hospital </h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>patients can alert by SMS reminder regarding their Appointments</li>
                                        <li> Get quik notice in Internal staff communication</li>
                                        <li>Can get informational status of medical reports for collection</li>
                                    </ul>
                                </div>

                                <div class="case-study">
                                    <h4>Automobiles Industries</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>A reminder can be send to customer regarding their vechicle service
                                        </li>
                                        <li>Test drive invitation can be sent to potential and existing buyers to create awareness</li>
                                        <li>Free service SMS vouchers can also be given to customers to show appreciate on their loyalty
                                            to be sent.</li>
                                    </ul>
                                </div>

                                <div class="case-study">
                                    <h4>School, College, University and Educational Institute</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>used to get instantly conveyable information among parents,teachers and students</li>
                                        <li>To confirm the schedule and registration of teachers and classes</li>
                                        <li>To notice change of exam results, time or schedule</li>
                                        <li>To inform students to any cultural ,co-curriculum activities, or upcoming events</li>
                                        <li>Used to alert Fee defaulters of students</li>
                                        <li>To contact students and staff in case of an emergency</li>
                                    </ul>
                                </div>


                                <div class="case-study">
                                    <h4>Banking, Credit/Debit Card, Finances and Credit Society</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li> Get alerted Payment transaction information, authentication and access control</li>
                                        <li>Stay updated to know new or changed conditions, statement reminders, loan application
                                            approved/declined
                                        </li>
                                        <li>Bulk SMS is extensively used in the banking sector for conveying transaction related
                                            information
                                            that when amount was debited or credited, account balance inquiries, etc.</li>
                                        <li>Security: send automate message whenever the card is used</li>
                                        <li>Due and late payment reminder or over credit limit alert.</li>
                                    </ul>

                                </div>
                                <div class="case-study">
                                    <h4>Hotels, Restaurant and Resorts industry </h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>serves as an awesome tool for taking care of visitors from the time of check in to check
                                            out.</li>
                                        <li>promote your business by making your customers know exciting discounts through Promotinal
                                            SMS</li>
                                        <li>It works wonders in customer service and helps in the smooth functioning of the business.
                                        </li>
                                        <li>updating the clients about room availability, room booking, special festive discounts,
                                            famous dish of the day, etc.</li>
                                        <li>To also send room vouchers or rebate vouchers through our voucher campaign</li>
                                        <li>To collect customer feedback through the survey campaign as well.</li>
                                    </ul>
                                </div>



                                <div class="case-study">
                                    <h4>Entertainments / Cinema Hall</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>Updates of all forthcoming shows</li>
                                        <li>Booking confirmation, Send latest news and updates </li>
                                        <li>Send weekly / monthly promotion update.</li>
                                    </ul>
                                </div>
                                <div class="case-study">
                                    <h4>Event Management industry</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>Tickets for the event can also be sent via an SMS</li>
                                        <li>intimate public about any upcoming events in order to create awareness</li>
                                        <li>{this.props.orgName} is the most affordable medium for promoting an event.
                                            A gentle SMS reminder can be sent to event attendees to confirm attendance and arrangement
                                            in advance</li>
                                        <li>An immediate and accurate message can be sent to attendees in the case of changes in time
                                            and
                                            locations</li>
                                        <li>No need to waste money on calls emails to your list of invitees</li>
                                        <li>Simply by signing <b>{this.props.orgName}</b> you can remind your guests regarding the time,
                                            date and venue of the event.</li>
                                    </ul>
                                </div>
                                <div class="case-study">
                                    <h4>Fitness Centers / Gyms / Health Care</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>used to remind the customer about their regular activity according to the days</li>
                                        <li>Purchase confirmation can be sent via Bulk SMS/email</li>
                                        <li>used to maintain puncuality through SMS reminder</li>
                                        <li>Notify latest product offerings / promotional items</li>
                                        <li>Update customers on the purchase status</li>
                                        <li>Aslo assist you to follow up the diet at regular intervals</li>
                                    </ul>
                                </div>
                                <div class="case-study">
                                    <h4> Real Estate and Builders,Property Sales </h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>Buying, selling, leasing, renting of property has become a lot easier than before.</li>
                                        <li>To send instructions to prospective or ready buyers</li>
                                        <li>It has become easy to stay in touch with our customers and connect to prospective clients.
                                        </li>
                                        <li>Appointment reminders to send 1 day before viewing the house</li>
                                        <li>Easy marketing for your builders and plan property offers.</li>
                                    </ul>
                                </div>






                                <div class="case-study">
                                    <h4>NGO / Charity / Organization's</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>To contact members about upcoming events, meetings, tasks and news</li>
                                        <li>Announcement of important meeting for prompt notification to key individuals at the right
                                            time</li>
                                        <li>To encourage members and send them daily with noble words</li>
                                        <li>To try to get more people to join the organization</li>
                                    </ul>
                                </div>


                                <div class="case-study">
                                    <h4>Transportation Logistics and Courier</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>Useful for communicating with recipients in terms of delivery time</li>
                                        <li>To update customers about real time information about delivery status</li>
                                        <li>Team members to get their latest information about shifts and routines</li>
                                        <li>To indicate where and when to find a Courier</li>
                                    </ul>
                                </div>
                                <div class="case-study">
                                    <h4>Travel, Tour and Aviation industry </h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>order to update customers about flight timings, schedule, bulk SMS proves to be the best way
                                            to communicate with them. </li>
                                        <li>Promo Holiday Package / Super Deal, Right To Travel Invitations, To Know Location, Date and
                                            Booth
                                            Number</li>
                                        <li>When traveling to a booth, travel itineraries for flight, flight times</li>
                                        <li>For alerts about delays, changes or cancellations, for ticket number reporting</li>
                                        <li>Flight crew and staff to update information on time</li>
                                        <li>Airlines these days are making use of this cost effective tool for sending flight reminders,
                                            offers, discounts, etc.</li>
                                    </ul>
                                </div>



                                <div class="case-study">
                                    <h4>Direct Multi Level Marketing</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>To receive bonuses and also send bonus notifications to each member of the group</li>
                                        <li>For communication with all groups at seminars or new product launches</li>
                                        <li>To direct your local people to mobile numbers.</li>
                                    </ul>
                                </div>



                                <div class="case-study">
                                    <h4>Insurance Sector</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>To send notifications on premium renewal / new policy</li>
                                        <li>To remind customers on premium renewal and policy expiration dates</li>
                                        <li>For information on new plans, greetings and payment of premiums</li>
                                    </ul>
                                </div>


                                <div class="case-study">
                                    <h4>Shopping Center, Mall, and Retail Shop</h4>
                                    <ul class="sp-points sp-mt10">
                                        <li>can promote exciting new arrivals and Brand</li>
                                        <li>For rental deadline reminder, maintenance and emergency work notification</li>
                                        <li>For sale or lease instruction on retail premises</li>
                                        <li>To update any ongoing sales / permanent members / permanent customers</li>
                                        <li>Members' Day for sale / special offer or promotion notification.</li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>

                </ClientHeader>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});


export default connect(mapStateToProps, {
})(withRouter(CaseStudies));


