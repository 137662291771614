import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import ServerlessService from 'src/Service/ServerlessService';
import { connect } from 'react-redux';


class CreditPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            credits: null
        }
        this.serverlessService = new ServerlessService();

    }


    componentDidMount() {
        this.getCredits()
    }
    getCredits = () => {
        this.setState({
            isLoading: true,
        });
        const url = `/admin/get-credits`;
        this.serverlessService.get(url, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    credits: res.res.data
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }


    render() {
        return (<div className='sp-card credit-panel'>
            <h3>Available Credits for your Organization</h3>
            <h3><b>{this.props?.user?.user?.orgName}</b></h3>
            <p>SMS Credits:  <b>{this.state.credits && this.state.credits.smsLimit}</b> </p>
            <p>Email Credits:  <b>{this.state.credits && this.state.credits.emailLimit}</b> </p>
            {/* <p>Unassigned credits to user (avaliable to use by API or can assign to user.)</p> */}
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </div>
        )
    }
}



const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
    user: state.user,
});

export default connect(mapStateToProps, {})(CreditPanel);

