import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from 'src/Header';
import { savePricing } from 'src/store/actions';
import Authentication from './../../session';



let cPricings = {
    p2000: '0.30',
    p5000: '0.27',
    p10000: '0.25',
    p100000: '0.20',
    p200000: '0.18',
    p1000000: '0.17'
};


let emailPricingsStatic = {
    p2000: '0.20',
    p5000: '0.15',
    p10000: '0.13',
    p100000: '0.10',
    p200000: '0.09',
    p1000000: '0.06'
}

let voicePricingsStatic = {
    p2000: '0.35',
    p5000: '0.32',
    p10000: '0.30',
    p100000: '0.28',
    p200000: '0.26',
    p1000000: '0.22'
}

let cPricingsErr1 = {
    p2000: '',
    p5000: '',
    p10000: '',
    p100000: '',
    p200000: '',
    p1000000: ''
}


class CustomPricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smsPricings: this.props.smsPricings ? this.props.smsPricings : cPricings,
            smsPricingsErr: cPricingsErr1,
            emailPricings: this.props.emailPricings ? this.props.emailPricings : emailPricingsStatic,
            emailPricingsErr: cPricingsErr1,
            voicePricings: this.props.voicePricings ? this.props.voicePricings : voicePricingsStatic,
            voicePricingsErr: cPricingsErr1,

        }

    }



    componentDidUpdate(prevProps) {
        if (prevProps.smsPricings != this.props.smsPricings) {
            this.setState({
                smsPricings: this.props.smsPricings,
                emailPricings: this.props.emailPricings
            });
        }
    }


    onPriceChange = (value, key, field) => {

        if (!isNaN(value)) {
            this.setState((prevState) => {
                return {
                    [field]: {
                        ...prevState[field],
                        [key]: value
                    }
                };
            });

        }


    }


    onSubmit = () => {

        const { emailPricings, smsPricings, voicePricings } = this.state;
        this.props.savePricing({ emailPricings, smsPricings, voicePricings })
    }



    render() {

        // console.log(this.props, 'sss')

        const { smsPricings, smsPricingsErr, emailPricings, emailPricingsErr, voicePricings, voicePricingsErr, } = this.state;
        return (
            <Header >
                <div className='sp-main'>
                    <div className='sp-card sp-p20'>
                        <div>
                            <h2 class='h2-defult'>Pricing Management</h2>


                            <h3>SMS Pricing</h3>
                            {smsPricings && Object.keys(smsPricings).length ? Object.keys(smsPricings).map(key => {
                                return <div className='p-grid'>
                                    <div className='p-md-2'>
                                        {key.substring(1)}
                                    </div>
                                    <div className='p-md-6'>
                                        <InputText value={smsPricings[key]}
                                            type="text"
                                            className='sp-w200  p-inputtext-style1'
                                            onChange={(e) => { this.onPriceChange(e.target.value, key, 'smsPricings') }} />
                                        {<p className="p-error">{smsPricingsErr[key]}</p>}
                                    </div>
                                </div>


                            }) : null}
                            <hr />

                            <h3>Voice Pricing</h3>
                            {voicePricings && Object.keys(voicePricings).length ? Object.keys(voicePricings).map(key => {
                                return <div className='p-grid'>
                                    <div className='p-md-2'>
                                        {key.substring(1)}
                                    </div>
                                    <div className='p-md-6'>
                                        <InputText value={voicePricings[key]}
                                            type="text"
                                            className='sp-w200  p-inputtext-style1'
                                            onChange={(e) => { this.onPriceChange(e.target.value, key, 'voicePricings') }} />
                                        {<p className="p-error">{voicePricingsErr[key]}</p>}
                                    </div>
                                </div>
                            }) : null}
                            <hr />
                            <h3>Email Pricing</h3>
                            {emailPricings && Object.keys(emailPricings).length ? Object.keys(emailPricings).map(key => {
                                return <div className='p-grid'>
                                    <div className='p-md-2'>
                                        {key.substring(1)}
                                    </div>
                                    <div className='p-md-6'>
                                        <InputText value={emailPricings[key]}
                                            type="text"
                                            className='sp-w200  p-inputtext-style1'
                                            onChange={(e) => { this.onPriceChange(e.target.value, key, 'emailPricings') }} />
                                        {<p className="p-error">{emailPricingsErr[key]}</p>}
                                    </div>
                                </div>
                            }) : null}

                            <Button label={`Save`} className='sp-mt20'
                                onClick={this.onSubmit}
                            />
                        </div>
                    </div>
                </div>
            </Header>
        )
    }
}




const mapStateToProps = (state) => ({
    isLoading: state?.orgData?.isLoading,
    smsPricings: state?.orgData?.smsPricings,
    emailPricings: state?.orgData?.emailPricings,
    voicePricings: state?.orgData?.voicePricings,
});



export default connect(mapStateToProps, {
    savePricing
})(Authentication(CustomPricing));

