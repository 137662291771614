import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { v4 as uuidv4 } from 'uuid';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import entitiesFeilds from './entity.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, isFieldValid, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { bytesToSize, isValidFileSize, trimObj } from 'src/Utils';
import FileUpload from 'src/components/common/fileUpload';
import axios from 'axios';
import ServerlessService from 'src/Service/ServerlessService';
import DirectFileUpload from '../common/directFileUpload';
import { CLOUDFRONT_ENDPOINT } from 'src/Service/config';


class CreateClient extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(entitiesFeilds, this.props.entity ? this.props.entity : {});
        this.state = {
            entity: this.formFields.data,
            formValidations: this.formFields.formValidations,
            uploadedFilesInfo: this.props.entity ? this.props.entity.attachments || [] : []
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    isAdditionalValid = () => {

        let entity = JSON.parse(JSON.stringify(this.state.entity));
        let { formValidations } = this.state;
        // password and confirm password 
        let isValid = true;
        if (entity.password != entity.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }


    createReseller = async () => {
        let entityData = trimObj(this.state.entity);
        const formStatus = isFormValid(entitiesFeilds, this.formFields.formValidations, entityData);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }




        if (isCompleteFormValid) {


            if (this.state.uploadedFilesInfo && this.state.uploadedFilesInfo.length) {
                if (this.state.uploadedFilesInfo.some(a => !isValidFileSize(a.size))) {
                    this.toast.show({ severity: 'error', summary: 'Invalid Attachments', detail: 'Attachments can not be more than 20MB. Please remove', life: 3000 });
                    return;
                } else {

                }

            }


            let url = `/organization/create-client`
            if (this.props.entity) {
                url = `/organization/update`
            }

            this.setState({
                isLoading: true,
            });


            this.serverlessService.post(url, entityData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.entity ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.entity ? 'Update' : 'Save'} Client`} className='sp-m-lr10'
                onClick={this.createReseller}
            />
        </div>)
    }



    _removefile = (fileInf) => {
        let uploadedFiles = [];
        this.state.uploadedFilesInfo.forEach((fileInfo) => {
            if (fileInfo.name !== fileInf.name) {
                uploadedFiles = [...uploadedFiles, fileInfo];
            }
        });

        this.setState({
            uploadedFilesInfo: uploadedFiles
        });
    }

    showAttachment = (attachment) => {
        if (attachment.path) {
            window.open(attachment.path);
        } else {
            let src = URL.createObjectURL(attachment);
            window.open(src);
        }
    }

    _onLogoRectSuccess = (fileName, data) => {
        this.addAttachment('logoRectangle', fileName, data);
        return
    }

    _onLogoSquareSuccess = (fileName, data) => {
        this.addAttachment('logoSquare', fileName, data);
        return;

    }
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    addAttachment = (fieldName, fileName, data) => {
        let entity = {
            ...this.state.entity,
            [fieldName]: CLOUDFRONT_ENDPOINT + data.Key,
        }
        let formValidations = isFieldValid(entitiesFeilds.find(fm => fm.FieldName == fieldName), this.state.formValidations, entity);


        this.setState((prevState) => {
            return {
                entity,
                formValidations
            }
        });
    }

    filesUploadedBindingInfo = (filesInfo = []) => {
        let temp;
        temp = filesInfo.map((fileInfo) => {
            return <div key={fileInfo.name}>
                <div className="p-grid upload" dir="ltr">

                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <div style={{ width: '30px', float: 'right' }}>
                            <span className="btn-remove-file sp-float-right"
                                onClick={() => this._removefile(fileInfo)}><i className='pi pi-times' /></span>

                        </div>
                        <div style={{ marginRight: '30px' }} >
                            <p onClick={() => { this.showAttachment(fileInfo) }} className={`filename ${isValidFileSize(fileInfo.size) ? 'valid-file' : "invalid-file"}`}>{fileInfo.name} <span className='sp-right'> {bytesToSize(fileInfo.size)}</span></p>
                        </div>
                    </div>


                </div>
            </div>
        });
        return temp;
    }

    _afterFilesUploaded = (files) => {
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
            }));
        }
    }



    render() {
        const { entity, formValidations } = this.state;

        // console.log(this.props, 'state-appointment')
        return (<>
            <Dialog
                header={`${this.props.entity ? 'Edit' : 'Create'} Client`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={false}
                dismissableMask={false}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Organization Name<span className='sp-required'>*</span></p>
                    <InputText className='sp-w100p p-inputtext-style1'
                        autoComplete="off" value={entity.orgName}
                        onChange={(e) => { onTextChange(e.target.value, 'orgName', this, entitiesFeilds, entity, formValidations, 'entity', 'formValidations') }} />
                    {formValidations && !formValidations.fields['orgName'].isValid && <p className="p-error">{formValidations.fields['orgName'].errorMsg}</p>}
                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Email<span className='sp-required'>*</span></p>
                    <InputText value={entity.email}
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onEmailChange(e.target.value, 'email', this, entitiesFeilds, entity, formValidations, 'entity', 'formValidations') }} />
                    {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Password<span className='sp-required'>*</span></p>
                    <InputText value={entity.password}
                        type="password"
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'password', this, entitiesFeilds, entity, formValidations, 'entity', 'formValidations') }} />
                    {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Confirm Password<span className='sp-required'>*</span></p>
                    <InputText value={entity.passwordConfirm}
                        type="password"
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'passwordConfirm', this, entitiesFeilds, entity, formValidations, 'entity', 'formValidations') }} />
                    {formValidations && !formValidations.fields['passwordConfirm'].isValid && <p className="p-error">{formValidations.fields['passwordConfirm'].errorMsg}</p>}
                    <p className='sp-label-s1 sp-mt10 sp-no-pm'>Your name<span className='sp-required'>*</span></p>
                    <InputText value={entity.name}
                        autoComplete="new-password"
                        className='sp-w100p  p-inputtext-style1'
                        onChange={(e) => { onTextChange(e.target.value, 'name', this, entitiesFeilds, entity, formValidations, 'entity', 'formValidations') }} />
                    {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    ///  entity: state.entity,
    // entityType: state?.entity?.entityType,
    authenticated: state?.entity?.authenticated,
    isSessionChecked: state?.entity?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateClient)));
