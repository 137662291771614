import React, { Component } from 'react'
import ClientHeader from './clientHeader'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';



class PrivacyPolicy extends Component {


    componentDidMount() {
        window.scrollTo(0, 0)
    }



    render() {
        return (
            <>
                <ClientHeader >
                    <div class="sp-container container ">
                        <div class="sp-container-fluid ">
                            <div class="p-grid">
                                <div class="col-md-12">
                                    <div class="privacy-c">
                                        <h2 class="tile-s1">Privacy policy</h2>
                                        <p>We are the team of <b>“{this.props.orgName}”</b> hereby mentioning the following conditions and
                                            policies related to Bulk SMS/EMAIL or it’s related services provided by
                                            <b> {this.props.orgName}</b>.
                                        </p>
                                        <hr />
                                        <div>
                                            <h4> SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h4>
                                            <p>When you register to our site, we collect the personal information you give us such
                                                as your name, address and email address, phone number to identify you. We will store
                                                your details in our secure server. When you browse our store, we also automatically
                                                receive your computer’s internet protocol (IP) address in order to provide us with
                                                information that helps us learn about your browser and operating system. Email
                                                marketing (if applicable): With your permission, we may send you emails about our
                                                store, new products and other updates.</p>
                                            <p>Once you registered to our site means you are giving the permissions to access your
                                                basic information like phone number, name, gender, address. We may contact you to
                                                verify your identity.</p>
                                            <h4> SECTION 2 - SECURITY </h4>
                                            <p>To protect your personal information, we take reasonable precautions and follow
                                                industry best practices to make sure it is not inappropriately lost, misused,
                                                accessed, disclosed, altered or destroyed.</p>
                                            <h4>Information sharing and disclosure</h4>
                                            <ul>
                                                <li><b>{this.props.orgName}</b> do not share or rent user email addresses or mobile number
                                                    with third parties except
                                                    our alliance partners.</li>
                                                <li><b>{this.props.orgName}</b> respond to requests from any Indian or foreign government,
                                                    security,
                                                    defence, revenue, regulatory or other authority, agency or officer.</li>
                                                <li><b>{this.props.orgName}</b> reserve the right to disclose user personally identifiable
                                                    and email address
                                                    information as required by law and when <b>{this.props.orgName}</b> believe that
                                                    disclosure is necessary to protect
                                                    our rights and/or comply with a judicial proceeding, court order, or legal
                                                    process served on our Website/App.
                                                </li>
                                            </ul>
                                            <h4>Links to other sites</h4>
                                            <ul>
                                                <li>our Service may contain links to other sites that are not operated by us. If you
                                                    click a third party link, you will be
                                                    directed to that third party's site.
                                                    We strongly advise you to review the Privacy Policy of every site you visit.
                                                </li>
                                                <li>We have no control over and assume no responsibility for the content, privacy
                                                    policies or practices of any third
                                                    party sites or services.</li>
                                            </ul>
                                            <h4> SECTION 3 - COOKIES </h4>
                                            <p>Generally we store some cookies in your browser for unique identification of the
                                                user, and to maintan cart.</p>
                                            <p>This website uses cookies and similar technologies to collect data from your device
                                                (mobile, tablet or personal computer). We need to
                                                collect this data for the website to operate, but we also use it to provide the best
                                                experience, understand how you use our website, personalise
                                                content, and deliver client communications. When you visit our website, we will
                                                deploy these technologies to provide an online service more
                                                suited to the device you connect from, as well as to prevent and detect fraud to
                                                keep you secure.</p>
                                            <h4>SECTION 4 - AGE OF CONSENT</h4>
                                            <p>By using this site, you represent that you are at least the age of majority in your
                                                state or province of residence, or that you are the age of majority in your state or
                                                province of residence and you have given us your consent to allow any of your minor
                                                dependents to use this site.</p>
                                            <h4> SECTION 5 - CHANGES TO THIS PRIVACY POLICY </h4>
                                            <p>We reserve the right to modify this privacy policy at any time, so please review it
                                                frequently. Changes and clarifications will take effect immediately upon their
                                                posting on the website. If we make material changes to this policy, we will notify
                                                you here that it has been updated, so that you are aware of what information we
                                                collect, how we use it, and under what circumstances, if any, we use and/or disclose
                                                it. If our store is acquired or merged with another company, your information may be
                                                transferred to the new owners so that we may continue to sell products to you.</p>
                                            <h4> QUESTIONS AND CONTACT INFORMATION </h4>
                                            <p>If you would like to: access, correct, amend or delete any personal information we
                                                have about you, register a complaint, or simply want more information contact our
                                                Privacy Compliance Officer at {this.props.contactEmail}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6"></div>
                        </div>
                    </div>
                </ClientHeader>
            </>
        )
    }
}






const mapStateToProps = (state) => ({
    contactEmail: state?.orgData?.contactEmail,
    orgName: state?.orgData?.orgName,
});


export default connect(mapStateToProps, {
})(withRouter(PrivacyPolicy));

