import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print';

import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PieChart, Pie, Sector, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import LoadingComponent from 'src/LoadingComponent';
import APIService from 'src/Service/apiService';
import { MATRIMONY_CLOUDFRONT, LONG_RUNNING_PRESIGNED_URL } from 'src/Service/config';

import { Toolbar } from 'primereact/toolbar';
import ServerlessService from 'src/Service/ServerlessService';


var timer;
const COLORS = ['#028020', '#D32F2F'];

const COLORS_FOR__DTMF = ['#028020', '#e6981c', '#0088FE', '#5c23cf', '#e01272', '#92d624', '#28edaf'];

const COLORS_FOR__PARTY = { "A1": '#028020', "A2": '#e6981c', "A3": '#0088FE', "A4": '#5c23cf', "Ainvalid": '#FFBA00' };


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.28;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);


    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {/* {`${(percent * 100).toFixed(0)}%`} */}
            {payload?.payload?.value}%
        </text>
    );
};

const renderCustomizedLabelForDTMF = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);


    return (
        <text x={x + 5} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {/* {`${(percent * 100).toFixed(0)}%`} */}
            {payload?.payload?.name != 'invalid' ? payload?.payload?.name : '*'} -  {payload?.payload?.value}%
        </text>
    );
};


export default class CampaignReports extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reloadStarted: false,
            dtmfValusMappingData: [],
            selectedCampaign: null,
            "answerUnanswerReport": [

            ],
            "dtmfReport": [

            ],
            "pulseStats": {

            },
            "pulseSlots": {

                "60-70": 0,
                "70-80": 0,
                "80-90": 0,
                "90-100": 0
            },
            apexDonut: {
                series: [44, 55, 41, 17, 15],
                options: {
                    chart: {
                        width: 380,
                        type: 'donut',
                    },
                    plotOptions: {
                        pie: {
                            startAngle: -90,
                            endAngle: 270
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        type: 'gradient',
                    },
                    legend: {
                        formatter: function (val, opts) {
                            return val + " - " + opts.w.globals.series[opts.seriesIndex]
                        }
                    },
                    title: {
                        text: 'Gradient Donut with custom Start-angle'
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },


            }

        }
        this.apiService = new APIService();
        this.serverlessService = new ServerlessService();
    }


    getCampaignInfoById = () => {
        this.setState({
            isLoading: true,
            campaignIds: []
        });
        const url = `/voice-bulk/id/${this.props.campaignId}`;
        this.serverlessService.get(url, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    selectedCampaign: res.res.data,
                }, () => {
                    const { campaignId } = this.state.selectedCampaign

                    this.getReportByCampaignId(campaignId)
                })
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            // this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }


    getReportByCampaignId = (campaignId) => {

        const { ivrValues, campaignType, voiceLength, ivrMappings, rawValue } = this.state.selectedCampaign;


        let colors = []
        this.setState({
            isLoading: true,

        });
        const url = `${LONG_RUNNING_PRESIGNED_URL}/report/getCampaignReport-v1/${campaignId}`;
        this.apiService.post(url, { ivrValues, campaignType, campaignId, ivrMappings, voiceLength, rawValue }).then((res) => {
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.report && res.res.data.report.total) {
                    this.setState({
                        isLoading: false,
                        noReports: false,
                        ...res.res.data,
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        noReports: true
                    })
                    //   this.toast.show({ severity: 'error', summary: 'Not started', detail: 'Campaign was not started', life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }


    componentWillUnmount() {
        clearInterval(timer)

    }


    componentWillReceiveProps(newProps) {
        if (this.props.refreshCount !== newProps.refreshCount) {
            this.getReportByCampaignId(this.props.campaignId)
        }
    }


    componentDidMount() {
        this.getCampaignInfoById()

    }

    renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };


    getWinnerClassName = (ranking) => {
        switch (ranking) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return `c${ranking}-winner`
            default:
                return ''
        }


    }


    render() {
        console.log(this.state, 'selectedCampaign')
        const {
            selectedCampaign
        } = this.state;
        const {
            // pieChart,

            // barChart,
            dtmfReport,
            answerUnanswerReport,
            report
        } = this.state;
        return (
            <div >
                {

                    <>

                        <div ref={el => (this.componentRef = el)} className='report-wrapper' >
                            {
                                this.state.noReports ? <div className=' p-card campagin-details-answered' >
                                    <h3 className=''>{selectedCampaign?.campaignName}</h3>
                                    <h4 className='no-campaign sp-center sp-m20'>Campaign not started. Try again after sometimes</h4>
                                </div> :

                                    <>
                                        <div className=' campagin-details-answered campagin1' >
                                            <h3 className=''>{selectedCampaign?.campaignName}</h3>
                                            <div className="p-grid">
                                                <div className="p-col-4 p-md-4 p-lg-4" style={{ height: '230px' }} >
                                                    <PieChart width={800} height={400}>
                                                        <Pie
                                                            data={answerUnanswerReport}
                                                            cx="50%"
                                                            cy="50%"
                                                            innerRadius={10}
                                                            labelLine={false}
                                                            label={renderCustomizedLabel}
                                                            outerRadius={90}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {answerUnanswerReport.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                                {
                                                    report && <div className="p-col-8 p-md-8 p-lg-8" >
                                                        <div className="p-grid" style={{ marginTop: '90px' }}>
                                                            <div className="p-col-4 p-md-4 p-lg-4" >
                                                                <Button label={report?.total || '0'} className="p-button-secondary" />
                                                                <p className='sp-label-s1 sp-mb0'>Total</p>
                                                            </div>
                                                            <div className="p-col-4 p-md-4 p-lg-4" >
                                                                <Button label={report?.answered || '0'} className="p-button-success" />
                                                                <p className='sp-label-s1 sp-mb0'>Answered</p>
                                                            </div>

                                                            <div className="p-col-4 p-md-4 p-lg-4" >
                                                                <Button label={report?.unAnswered || '0'} className="p-button-danger" />
                                                                <p className='sp-label-s1 sp-mb0'>Unanswered</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        {
                                            this.state.dtmfReport && this.state.dtmfReport.length ? <>
                                                <div className='campagin-dtmf-report1 campagin1' >
                                                    {/* <h3 className=''>DTMF Report</h3> */}
                                                    <div className="p-grid">
                                                        <div className="p-col-6 p-md-6 p-lg-6"  >

                                                            <DataTable
                                                                className='dtmf-report-table'
                                                                value={this.state.dtmfValusMappingData}
                                                                dataKey="_id"
                                                                rows={100}
                                                            >
                                                                <Column field="dtmfValue" header="Response" />
                                                                <Column style={{ width: '240px' }} field="dtmfName" sty header="Voting" body={(rowData) => {
                                                                    return <div className={`c${rowData.ranking}-winner`} style={{ color: `${COLORS_FOR__PARTY[`A${rowData?.dtmfValue}`]}` }}>
                                                                        {rowData?.dtmfName}
                                                                    </div>
                                                                }} />
                                                                <Column field="count" header="Votes Count" />
                                                                <Column field="percentage" header="Percentage" />
                                                            </DataTable>

                                                        </div>
                                                        <div className="p-col-6 p-md-6 p-lg-6" style={{ height: '530px' }} >
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <PieChart width={500} height={500}>
                                                                    <Pie
                                                                        data={dtmfReport}
                                                                        cx="50%"
                                                                        cy="50%"
                                                                        labelLine={false}
                                                                        label={renderCustomizedLabelForDTMF}
                                                                        outerRadius={160}
                                                                        fill="#8884d8"
                                                                        dataKey="value"
                                                                    >
                                                                        {dtmfReport.map((entry, index) => (
                                                                            <Cell key={`cell-${index}`} fill={COLORS_FOR__PARTY[`A${entry?.name}`]} />
                                                                        ))}
                                                                    </Pie>
                                                                </PieChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                    </div>
                                                    <p class="footer-p printable" >Powered by SendShortly</p>
                                                </div>
                                            </> : <>

                                            </>
                                        }
                                    </>
                            }
                        </div>

                    </>
                }
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                <hr />
            </div >
        )
    }
}