import React, { Component } from "react";
import ClientHeader from "./clientHeader";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { formatter } from "src/Utils";

class Pricing extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { smsPricings, voicePricings, emailPricings } = this.props;

    return (
      <>
        <ClientHeader>
          <div className="sp-container container pricing-contianer-w">
            <div className="sp-container-fluid ">
              <div className="p-grid ">
                <div className="p-md-12">
                  <p>
                    We are offering affordable price with best class delivery
                    and we came up with many plans which are suitable to you.
                  </p>
                  <p>
                    kindly choose the best plan from the below for your needs.
                  </p>

                  <h2 className="sp-container-fluid">SMS Plans</h2>
                  <p className="plans-txt">
                    Pay only for SMS and get at no
                    cost
                  </p>

                  {smsPricings && (
                    <div className="container pricing-contianer">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-3 p-sm-6">
                          <div className="pricingTable">
                            <div className="pricingTable-header">
                              <i className="fa fa-adjust"></i>
                              <div className="price-value">
                                {" "}
                                {formatter.format(
                                  (2000 * smsPricings["p2000"]).toFixed(0)
                                )}{" "}
                                <span className="quantity">+ GST 18%</span>
                              </div>
                            </div>
                            {/* <!-- <h3 className="heading">Welcome</h3> --> */}
                            <div className="pricing-content">
                              <ul>
                                <li>
                                  <b>2,000</b> SMS
                                </li>
                                <li>
                                  <b>{smsPricings["p2000"]}</b> per 1 SMS
                                </li>
                                <li>
                                  <b>Unlimited</b> Validity
                                </li>
                                <li>
                                  <b>24/7</b> Support
                                </li>
                                <li>
                                  <b>Free</b> SMS APIs
                                </li>
                                <li>
                                  <b>Personalize</b> Bulk Sends
                                </li>
                                <li>
                                  <b>Hight priority</b> Routes
                                </li>

                              </ul>
                            </div>
                            <div className="pricingTable-signup">
                              <Link to="/login">sign up</Link>
                            </div>
                          </div>
                        </div>
                        <div className="p-col-12 p-md-3 p-sm-6">
                          <div className="pricingTable blue">
                            <div className="pricingTable-header">
                              <i className="fa fa-cube"></i>
                              <div className="price-value">
                                {" "}
                                {formatter.format(
                                  (5000 * smsPricings["p5000"]).toFixed(0)
                                )}{" "}
                                <span className="quantity">+ GST 18%</span>
                              </div>
                            </div>
                            {/* <!-- <h3 className="heading">₹ 1298 Total</h3> --> */}
                            <div className="pricing-content">
                              <ul>
                                <li>
                                  <b>5,000</b> SMS
                                </li>
                                <li>
                                  <b>{smsPricings["p5000"]}</b> per 1 SMS
                                </li>
                                <li>
                                  <b>Unlimited</b> Validity
                                </li>
                                <li>
                                  <b>24/7</b> Support
                                </li>
                                <li>
                                  <b>Free</b> SMS APIs
                                </li>
                                <li>
                                  <b>Personalize</b> Bulk Sends
                                </li>
                                <li>
                                  <b>Hight priority</b> Routes
                                </li>
                              </ul>
                            </div>
                            <div className="pricingTable-signup">
                              <Link to="/login">sign up</Link>
                            </div>
                          </div>
                        </div>
                        <div className="p-col-12 p-md-3 p-sm-6">
                          <div className="pricingTable green">
                            <div className="pricingTable-header">
                              <i className="fa fa-briefcase"></i>
                              <div className="price-value">
                                {" "}
                                {formatter.format(
                                  (10000 * smsPricings["p10000"]).toFixed(0)
                                )}{" "}
                                <span className="quantity">+ GST 18%</span>
                              </div>
                            </div>
                            {/* <!-- <h3 className="heading">Business</h3> --> */}
                            <div className="pricing-content">
                              <ul>
                                <li>
                                  <b>10,000</b> SMS
                                </li>
                                <li>
                                  <b>{smsPricings["p10000"]}</b> per 1 SMS
                                </li>
                                <li>
                                  <b>Unlimited</b> Validity
                                </li>
                                <li>
                                  <b>24/7</b> Support
                                </li>
                                <li>
                                  <b>Free</b> SMS APIs
                                </li>
                                <li>
                                  <b>Personalize</b> Bulk Sends
                                </li>
                                <li>
                                  <b>Hight priority</b> Routes
                                </li>
                              </ul>
                            </div>
                            <div className="pricingTable-signup">
                              <Link to="/login">sign up</Link>
                            </div>
                          </div>
                        </div>

                        <div className="p-col-12 p-md-3 p-sm-6">
                          <div className="pricingTable red">
                            <div className="pricingTable-header">
                              <i className="fa fa-diamond"></i>
                              <div className="price-value">
                                {" "}
                                {formatter.format(
                                  (100000 * smsPricings["p100000"]).toFixed(0)
                                )}{" "}
                                <span className="quantity">+ GST 18%</span>
                              </div>
                            </div>
                            {/* <!-- <h3 className="heading">Extra</h3> --> */}
                            <div className="pricing-content">
                              <ul>
                                <li>
                                  <b>1,00,000</b> SMS
                                </li>
                                <li>
                                  <b>{smsPricings["p100000"]}</b> per 1 SMS
                                </li>
                                <li>
                                  <b>Unlimited</b> Validity
                                </li>
                                <li>
                                  <b>24/7</b> Support
                                </li>
                                <li>
                                  <b>Free</b> SMS  APIs
                                </li>
                                <li>
                                  <b>Personalize</b> Bulk Sends
                                </li>
                                <li>
                                  <b>Hight priority</b> Routes
                                </li>
                              </ul>
                            </div>
                            <div className="pricingTable-signup">
                              <Link to="/login">sign up</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-grid sp-container-fluid">
                        <div className="p-col-12 p-md-3 p-sm-6">
                          <div className="pricingTable lgreen">
                            <div className="pricingTable-header">
                              <i className="fa fa-briefcase"></i>
                              <div className="price-value">
                                {" "}
                                {formatter.format(
                                  (200000 * smsPricings["p200000"]).toFixed(0)
                                )}{" "}
                                <span className="quantity">+ GST 18%</span>
                              </div>
                            </div>
                            {/* <!-- <h3 className="heading">Business</h3> --> */}
                            <div className="pricing-content">
                              <ul>
                                <li>
                                  <b>2,00,000</b> SMS
                                </li>
                                <li>
                                  <b>{smsPricings["p200000"]}</b> per 1 SMS
                                </li>
                                <li>
                                  <b>Unlimited</b> Validity
                                </li>
                                <li>
                                  <b>24/7</b> Support
                                </li>
                                <li>
                                  <b>Free</b> SMS  APIs
                                </li>
                                <li>
                                  <b>Personalize</b> Bulk Sends
                                </li>
                                <li>
                                  <b>Hight priority</b> Routes
                                </li>
                              </ul>
                            </div>
                            <div className="pricingTable-signup">
                              <Link to="/login">sign up</Link>
                            </div>
                          </div>
                        </div>
                        <div className="p-col-12 p-md-3 p-sm-6">
                          <div className="pricingTable pink">
                            <div className="pricingTable-header">
                              <i className="fa fa-cube"></i>
                              <div className="price-value">
                                {" "}
                                {formatter.format(
                                  (1000000 * smsPricings["p1000000"]).toFixed(0)
                                )}{" "}
                                <span className="quantity">+ GST 18%</span>
                              </div>
                            </div>
                            {/* <!-- <h3 className="heading">₹ 1298 Total</h3> --> */}
                            <div className="pricing-content">
                              <ul>
                                <li>
                                  <b>10,00,000</b> SMS
                                </li>
                                <li>
                                  <b>{smsPricings["p1000000"]}</b> per 1 SMS
                                </li>
                                <li>
                                  <b>Unlimited</b> Validity
                                </li>
                                <li>
                                  <b>24/7</b> Support
                                </li>
                                <li>
                                  <b>Free</b> SMS APIs
                                </li>
                                <li>
                                  <b>Personalize</b> Bulk Sends
                                </li>
                                <li>
                                  <b>Hight priority</b> Routes
                                </li>
                              </ul>
                            </div>
                            <div className="pricingTable-signup">
                              <Link to="/login">sign up</Link>
                            </div>
                          </div>
                        </div>
                        <div className="p-md-6 p-sm-6"></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <h2 className="sp-container-fluid">Voice Plans</h2>

                {voicePricings && (
                  <div className="container pricing-contianer">
                    <div className="p-grid">
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable">
                          <div className="pricingTable-header">
                            <i className="fa fa-adjust"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (2000 * voicePricings["p2000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <!-- <h3 className="heading">Welcome</h3> --> */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>2,000</b> voice calls
                              </li>
                              <li>
                                <b>{voicePricings["p2000"]}</b> per 1 sec per call
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Voice APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                              <li>
                                <b>Time </b>9 AM to 9 PM
                              </li>
                              <li>
                                <b>Type</b> Outbound,Inbound
                              </li>
                              <li><b>Missed call Services</b></li>
                              <li><b>IVR Survey</b></li>
                              <li><b>DTMF Capturing</b></li>

                              <li>
                                <b>NON DND Promotional</b>
                              </li>

                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable blue">
                          <div className="pricingTable-header">
                            <i className="fa fa-cube"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (5000 * voicePricings["p5000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <!-- <h3 className="heading">₹ 1298 Total</h3> --> */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>5,000</b> voice calls
                              </li>
                              <li>
                                <b>{voicePricings["p5000"]}</b> per 1 sec per call
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Voice APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                              <li>
                                <b>Time </b>9 AM to 9 PM
                              </li>
                              <li>
                                <b>Type</b> Outbound,Inbound
                              </li>
                              <li><b>Missed call Services</b></li>
                              <li><b>IVR Survey</b></li>
                              <li><b>DTMF Capturing</b></li>

                              <li>
                                <b>NON DND Promotional</b>
                              </li>

                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable green">
                          <div className="pricingTable-header">
                            <i className="fa fa-briefcase"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (10000 * voicePricings["p10000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <h3 className="heading">Business</h3>  */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>10,000</b> voice calls
                              </li>
                              <li>
                                <b>{voicePricings["p10000"]}</b> per 1 sec per call
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Voice APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                              <li>
                                <b>Time </b>9 AM to 9 PM
                              </li>
                              <li>
                                <b>Type</b> Outbound,Inbound
                              </li>
                              <li><b>Missed call Services</b></li>
                              <li><b>IVR Survey</b></li>
                              <li><b>DTMF Capturing</b></li>

                              <li>
                                <b>NON DND Promotional</b>
                              </li>

                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>

                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable red">
                          <div className="pricingTable-header">
                            <i className="fa fa-diamond"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (100000 * voicePricings["p100000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <h3 className="heading">Extra</h3>  */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>1,00,000</b> voice calls
                              </li>
                              <li>
                                <b>{voicePricings["p100000"]}</b> per 1 sec per call
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> voice APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                              <li>
                                <b>Time </b>9 AM to 9 PM
                              </li>
                              <li>
                                <b>Type</b> Outbound,Inbound
                              </li>
                              <li><b>Missed call Services</b></li>
                              <li><b>IVR Survey</b></li>
                              <li><b>DTMF Capturing</b></li>

                              <li>
                                <b>NON DND Promotional</b>
                              </li>

                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-grid sp-container-fluid">
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable lgreen">
                          <div className="pricingTable-header">
                            <i className="fa fa-briefcase"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (200000 * voicePricings["p200000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <h3 className="heading">Business</h3> */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>2,00,000</b> voice calls
                              </li>
                              <li>
                                <b>{voicePricings["p200000"]}</b> per 1 sec per call
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> voice APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                              <li>
                                <b>Time </b>9 AM to 9 PM
                              </li>
                              <li>
                                <b>Type</b> Outbound,Inbound
                              </li>
                              <li><b>Missed call Services</b></li>
                              <li><b>IVR Survey</b></li>
                              <li><b>DTMF Capturing</b></li>

                              <li>
                                <b>NON DND Promotional</b>
                              </li>

                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable pink">
                          <div className="pricingTable-header">
                            <i className="fa fa-cube"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (1000000 * voicePricings["p1000000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <h3 className="heading">₹ 1298 Total</h3> */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>10,00,000</b> voice calls
                              </li>
                              <li>
                                <b>{voicePricings["p1000000"]}</b> per 1 sec per call
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> voice APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                              <li>
                                <b>Time </b>9 AM to 9 PM
                              </li>
                              <li>
                                <b>Type</b> Outbound,Inbound
                              </li>
                              <li><b>Missed call Services</b></li>
                              <li><b>IVR Survey</b></li>
                              <li><b>DTMF Capturing</b></li>

                              <li>
                                <b>NON DND Promotional</b>
                              </li>

                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <h2 className="sp-container-fluid">Email Plans</h2>

                {emailPricings && (
                  <div className="container pricing-contianer">
                    <div className="p-grid">
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable">
                          <div className="pricingTable-header">
                            <i className="fa fa-adjust"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (2000 * emailPricings["p2000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <!-- <h3 className="heading">Welcome</h3> --> */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>2,000</b> Emails
                              </li>
                              <li>
                                <b>{emailPricings["p2000"]}</b> per 1 Email
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Email APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable blue">
                          <div className="pricingTable-header">
                            <i className="fa fa-cube"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (5000 * emailPricings["p5000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <!-- <h3 className="heading">₹ 1298 Total</h3> --> */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>5,000</b> Emails
                              </li>
                              <li>
                                <b>{emailPricings["p5000"]}</b> per 1 Email
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Email APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable green">
                          <div className="pricingTable-header">
                            <i className="fa fa-briefcase"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (10000 * emailPricings["p10000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <h3 className="heading">Business</h3>  */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>10,000</b> Emails
                              </li>
                              <li>
                                <b>{emailPricings["p10000"]}</b> per 1 Email
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Email APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>

                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable red">
                          <div className="pricingTable-header">
                            <i className="fa fa-diamond"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (100000 * emailPricings["p100000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <h3 className="heading">Extra</h3>  */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>1,00,000</b> Emails
                              </li>
                              <li>
                                <b>{emailPricings["p100000"]}</b> per 1 Email
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Email APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-grid sp-container-fluid">
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable lgreen">
                          <div className="pricingTable-header">
                            <i className="fa fa-briefcase"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (200000 * emailPricings["p200000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <h3 className="heading">Business</h3> */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>2,00,000</b> Emails
                              </li>
                              <li>
                                <b>{emailPricings["p200000"]}</b> per 1 Email
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Email APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-3 p-sm-6">
                        <div className="pricingTable pink">
                          <div className="pricingTable-header">
                            <i className="fa fa-cube"></i>
                            <div className="price-value">
                              {" "}
                              {formatter.format(
                                (1000000 * emailPricings["p1000000"]).toFixed(0)
                              )}{" "}
                              <span className="quantity">+ GST 18%</span>
                            </div>
                          </div>
                          {/* <h3 className="heading">₹ 1298 Total</h3> */}
                          <div className="pricing-content">
                            <ul>
                              <li>
                                <b>10,00,000</b> Emails
                              </li>
                              <li>
                                <b>{emailPricings["p1000000"]}</b> per 1 Email
                              </li>
                              <li>
                                <b>Unlimited</b> Validity
                              </li>
                              <li>
                                <b>24/7</b> Support
                              </li>
                              <li>
                                <b>Free</b> Email APIs
                              </li>
                              <li>
                                <b>Personalize</b> Bulk Sends
                              </li>
                              <li>
                                <b>Hight priority</b> Routes
                              </li>
                            </ul>
                          </div>
                          <div className="pricingTable-signup">
                            <Link to="/login">sign up</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="more-price">
                <h4>Are you not satisfied with the plans</h4>
                <p>
                  Do you want more plans, contact support to disclose the best
                  deal for your requirement.
                </p>
                <div className="read-more">
                  <Link to="/contact" className="">
                    Know More
                  </Link>
                </div>
              </div>
            </div>

            <div className="p-md-6"></div>
          </div>
        </ClientHeader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  smsPricings: state?.orgData?.smsPricings,
  emailPricings: state?.orgData?.emailPricings,
  voicePricings: state?.orgData?.voicePricings,
});

export default connect(mapStateToProps, {})(Pricing);
