import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import moment from "moment";
import { Toast } from 'primereact/toast';
import LoadingComponent from './../../../LoadingComponent';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { BasicLazyParams } from './../../../Utils/constants';
import Service from './../../../Service';
import { Toolbar } from 'primereact/toolbar';
import ServerlessService from './../../../Service/ServerlessService';
import { exportCSVFile } from 'src/Utils';
import axios from 'axios';
import { CONTABO_60GB_ENDPOINT, EMAIL_SERVER_URL } from 'src/Service/config';
import APIService from 'src/Service/apiService';

export default class BulkEmailSent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            globalFilter: '',
            columns: [
                //    { columnName: 'Password', isVisible: false, id: 'password' }
            ],
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            globalSearch: '',
            totalRecords: 0,
            data: [],
            emailStatus: null,
            filter: {}
        }

        this.service = new Service();
        this.serverlessService = new ServerlessService();

        this.apiService = new APIService();
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    componentDidMount() {
        debugger
        this.getTransactionDetails(this.props.transactionId);

    }

    componentDidUpdate(prevProps) {
        if (prevProps.transactionId != this.props.transactionId) {
            this.getTransactionDetails(this.props.transactionId);
        }


    }


    handleDownload = (url, filenameWithExtension) => {
        this.setState({
            isLoading: true
        })
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    filenameWithExtension
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                this.setState({
                    isLoading: false
                })
            }).catch(e => {
                this.setState({
                    isLoading: false
                })
            })
    }


    downloadReport = () => {
        let { selectedCampaign } = this.props;
        const url = `${selectedCampaign?.route && selectedCampaign.route != '1' ? CONTABO_60GB_ENDPOINT : EMAIL_SERVER_URL}/sms-bulk/allsent/${this.props.transactionId}`;
        this.handleDownload(url, 'report.xlsx');
    }


    refresh = () => {
        this.setState({
            lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
        }, () => { this.getTransactionDetails(this.props.transactionId) })
    }

    setAndApplyFiler = (key) => {


        if (key == 'All') {
            this.setState({
                filter: {

                },
                lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            }, () => this.getTransactionDetails(this.props.transactionId))
        } else {
            this.setState({
                filter: {
                    status: key
                },
                lazyParams: { ...BasicLazyParams, sortField: 'createdAt', sortOrder: 1 },
            }, () => this.getTransactionDetails(this.props.transactionId))
        }


    }


    getTransactionDetails = (transactionId) => {
        let { lazyParams, globalSearch } = this.state;
        let { selectedCampaign } = this.props;

        debugger
        this.setState({
            isLoading: true,
            data: [],
            transactionId
        });
        const url = `${selectedCampaign?.route && selectedCampaign.route != '1' ? CONTABO_60GB_ENDPOINT : EMAIL_SERVER_URL}/sms-bulk/${transactionId}?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.apiService.post(url, this.state.filter, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    data: res.res.data || [],
                    totalRecords: res.res.totalRecords,
                    emailStatus: res.res.emailStatus,
                })

                // this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates fetched successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    };

    onPage = (event) => {
        this.setState({ lazyParams: event },
            () => { this.getTransactionDetails(this.props.transactionId) });
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => { this.getTransactionDetails(this.props.transactionId) });
    }

    downloadCSV = () => {

        const { emailStatus } = this.state;

        let headers = [];

        Object.keys(emailStatus).forEach(k => {
            headers.push(k);
        })

        exportCSVFile(headers, [emailStatus], this.props.transactionId)



    }

    render() {
        // console.log(this.state.data, 'details');
        const { emailStatus } = this.state
        const header = (
            <div className="table-header sp-tbl-header">
                <div className="sp-tbl-left">
                    <h2 >Transactions Details - {this.props.transactionId}</h2>
                    {this.state.showSearch && <InputText placeholder="Search"
                        className="sp-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left sp-tbl-filter">
                    <ul className="sp-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search sp-tbl-icon sp-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".sp-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter sp-tbl-icon sp-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-fi" /> */}
                        {/* <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt sp-tbl-icon sp-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-sort" /> */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download sp-tbl-icon sp-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".sp-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div key={column.id} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );


        return <div className="p-grid sp-toolbar">
            <Toolbar className="sp-toolbar"
                right={() => {
                    return (<>
                        <Button label="Refresh" className='sp-mlr30'
                            icon="pi pi-refresh"
                            disabled={this.state.isLoading}
                            onClick={() => {
                                this.refresh()
                            }} />

                        <Button label="Download Full Report" className=''
                            icon="pi pi-download"
                            disabled={this.state.isLoading}
                            onClick={() => {
                                this.downloadReport()
                            }} />
                    </>
                    )
                }}



            ></Toolbar>
            <div className='sp-clearFix'></div>
            <div className='email-comaign-insights'>
                <h2 className=''>Insights <span onClick={() => { this.downloadCSV() }} className='sp-right'><i className='pi pi-download'></i></span></h2>

                {
                    emailStatus && Object.keys(emailStatus).map(key => {
                        return <span onClick={() => { this.setAndApplyFiler(key) }} >{key}  : {emailStatus[key]}  </span>

                    })
                }
            </div>


            <div className="card sp-datatable sp-m30">
                <DataTable ref={(el) => this.dt = el}
                    lazy
                    value={this.state.data}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="_id"
                    paginator
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    header={header}>

                    <Column sortable field="phoneNumber" header="Phone Number" />
                    <Column sortable field="messageId" header="Message Id" />
                    <Column sortable field="message" header="Message" />
                    <Column sortable field="status" header="Status" />
                    <Column sortable field="statusMessage" header="Status Message" />
                </DataTable>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />

            {this.state.isLoading && <LoadingComponent />}
        </div>;
    }
}
