import {
    combineReducers
} from 'redux';
import user from "./userDataReducer";
import permissions from './permissionsReducer';
import orgData from './orgReducer';

const rootReducer = combineReducers({
    user,
    permissions,
    orgData

});


export default rootReducer;