import React, { Component } from 'react'
import { OPEN_API_ENDPOINT } from 'src/Service/config'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


const paramsData = [
    {
        param: 'transactionId',
        paramName: 'transaction Id',
        description: 'transactionId is reiceved in "Send SMS To Multiple Numbers"'
    },
]



export default class GetTransactionByRef extends Component {


    render() {
        return (
            <div className='sp-m20 api-details'>
                <h2>API Details (http request)</h2>

                <div className='sp-m10 p-card sp-p20'>
                    <p className='config-txt config-url sp-txt-overflow'><b>URL:</b> <span>{OPEN_API_ENDPOINT + '/sms/transaction?transactionId=0998877665544332211'}</span> </p>
                    <p className='config-txt'><b>Request Type:</b> GET</p>
                </div>
                <h2>Mandatory Params</h2>
                <DataTable value={paramsData} responsiveLayout="scroll">
                    <Column field="param" header="Parameter"></Column>
                    <Column field="paramName" header="Name"></Column>
                    <Column field="description" header="Description"></Column>
                </DataTable>

            </div>
        )
    }
}
