import React, { Component } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toolbar } from 'primereact/toolbar';
import { connect } from 'react-redux';
import { selectPermissions } from '../../store/selectors/authorize';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { getPermissionList } from '../../store/actions/permissions';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import _, { trimEnd } from 'lodash';
import PermissionsTab from './permissionsTab';
import { getFormFields, isFormValid } from './../../Utils/formHelper';
import { trimObj } from './../../Utils';
import Service from './../../Service';
import ServerlessService from 'src/Service/ServerlessService';
const formFields = [
    {
        "Type": "Text",
        "Label": "Role Name",
        "FieldName": "roleName",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Role Description",
        "FieldName": "description",
        "Required": true
    },

    {
        "Type": "Object",
        "Label": "Permissions",
        "FieldName": "permissions",
        "Required": true,
        'errMsgCustom': true,
        'errMsg': 'Please select atleast one permission'
    }

]

const parsePermissionsData = (permissions) => {

    let selectedPermissions = {};

    Object.keys(permissions).forEach((permissionGroupId) => {
        selectedPermissions[permissionGroupId] = [];
        permissions[permissionGroupId].permissions.forEach(selectedPermissionObj => {
            selectedPermissions[permissionGroupId].push(selectedPermissionObj.id);
        })
    });

    return selectedPermissions;
};


class CreateRole extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editRoleData || {});
        this.state = {
            role: this.formFields.data,
            formValidations: this.formFields.formValidations,
            permissions: parsePermissionsData((this.props.editRoleData && this.props.editRoleData.permissions) ? this.props.editRoleData.permissions : {}),
            errors: {},
            permissionsMasterList1: [],
            // isShowCreate:false
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    componentDidMount() {
        if (!this.props.permissionsMasterList.length) {
            this.props.getPermissions();
        }


        // if (this.props.permissionsMasterList.length) {

        //     if (!this.props.isAdd && this.props.editRoleData) {
        //         let permissionLO = _.cloneDeep(this.props.permissionsMasterList);
        //         let permissionsMasterList1 = [];
        //         if (this.props.editRoleData.roleType == 'academic') {
        //             permissionsMasterList1 = permissionLO;
        //         } else {
        //             permissionsMasterList1 = permissionLO.filter((role) => {
        //                 let isNonAcademicGroup = false;
        //                 if (role.permissions && role.permissions) {
        //                     role.permissions = role.permissions.filter((permission) => {
        //                         if (permission.isNonAcademic) {
        //                             isNonAcademicGroup = true;
        //                             return permission;
        //                         }
        //                     });
        //                 }
        //                 if (isNonAcademicGroup) {
        //                     return role
        //                 }
        //             });
        //         }

        //         this.setState({
        //             permissionsMasterList1: permissionsMasterList1
        //         });

        //     }

        // }

    }

    componentWillReceiveProps(newProps) {
        if (this.props.permissionsMasterList !== newProps.permissionsMasterList) {

            // if (!this.props.isAdd && this.props.editRoleData) {
            //     let permissionLO = _.cloneDeep(newProps.permissionsMasterList);
            //     let permissionsMasterList1 = [];
            //     if (this.props.editRoleData.roleType == 'academic') {
            //         permissionsMasterList1 = permissionLO;
            //     } else {
            //         permissionsMasterList1 = permissionLO.filter((role) => {
            //             let isNonAcademicGroup = false;
            //             if (role.permissions && role.permissions) {
            //                 role.permissions = role.permissions.filter((permission) => {
            //                     if (permission.isNonAcademic) {
            //                         isNonAcademicGroup = true;
            //                         return permission;
            //                     }
            //                 });
            //             }
            //             if (isNonAcademicGroup) {
            //                 return role
            //             }
            //         });
            //     }

            //     this.setState({
            //         permissionsMasterList1: permissionsMasterList1
            //     });

            // }

        }
    }

    handleCheckBoxClick = (groupId, permissionId, checked) => {

        const permissionList = this.state.permissions;
        if (Object.keys(permissionList).includes(groupId)) {
            if (checked) {
                permissionList[groupId].push(permissionId);
            } else {
                permissionList[groupId] = permissionList[groupId].filter((item) => item !== permissionId);
                if (permissionList[groupId].length === 0) {
                    delete permissionList[groupId];
                }
            }
        } else {
            permissionList[groupId] = [permissionId];
        }

        this.setState((prevState) => {

            return {
                permissions: permissionList,
                errors: {
                    ...prevState.errors,
                    permissions: Object.keys(permissionList).length === 0
                }
            };
        });
    };

    onTextChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.role));
        let formValidations = this.state.formValidations;
        data[field] = e.target.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            role: data,
            formValidations
        });
    }

    getPermissionListByGroup = (groupId) => {
        const { permissionsMasterList } = this.props;
        const groupedPermissions = permissionsMasterList.find((item) => item.id === groupId);
        return groupedPermissions;
    };

    // onChoiceChange(value, field) {
    //     if (value) {
    //         let data = JSON.parse(JSON.stringify(this.state.role));
    //         let formValidations = this.state.formValidations;
    //         data[field] = value;
    //         formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }

    //         let permissionLO = _.cloneDeep(this.props.permissionsMasterList);
    //         let permissionsMasterList1 = [];
    //         if (value == 'academic') {
    //             permissionsMasterList1 = permissionLO;
    //         } else {
    //             permissionsMasterList1 = permissionLO.filter((role) => {
    //                 let isNonAcademicGroup = false;
    //                 if (role.permissions && role.permissions) {
    //                     role.permissions = role.permissions.filter((permission) => {
    //                         if (permission.isNonAcademic) {
    //                             isNonAcademicGroup = true;
    //                             return permission;
    //                         }
    //                     });
    //                 }
    //                 if (isNonAcademicGroup) {
    //                     return role
    //                 }
    //             });
    //         }

    //         this.setState({
    //             role: data,
    //             formValidations,
    //             permissions: {},
    //             permissionsMasterList1
    //         });
    //     }


    // }

    createRoleDialogFooter = () => {
        return (<div className='sp-mt20'>
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.hideRoleCreateDialog} />
            <Button label={`${this.props.isAdd ? 'Add' : 'Update'}`} className='sp-m-lr10' onClick={this.onSubmitClick} />

        </div>)
    };



    onSubmitClick = () => {
        let { permissions } = this.state;

        let role = this.state.role;
        role.permissions = permissions;
        const { roleName, description, roleType } = role;

        // const errorState = {
        //     roleName: roleName === '',
        //     description: description === '',
        //     roleType: roleType == null,
        //     permissions: JSON.stringify(permissions) === JSON.stringify({})
        // };
        // if (Object.values(errorState).some((item) => item === true)) {
        //     this.setState({ errors: errorState });

        //     setTimeout(() => {
        //         this.setState({ errors: {} });
        //     }, 3000);
        // }

        role = trimObj(role);
        const formStatus = isFormValid(formFields, this.formFields.formValidations, role);

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {

            const permissionOrderedByGroups = Object.keys(permissions).reduce((acc, curr) => {
                const groupedPermissions = this.getPermissionListByGroup(curr);
                if (groupedPermissions) {
                    const perms = permissions[curr].map((permId) => {
                        return groupedPermissions.permissions.find((permObject) => {
                            if (permObject.id === permId) {
                                return permObject;
                            }
                        });
                    });

                    acc[curr] = {
                        groupName: groupedPermissions.groupName,
                        permissions: perms.filter((item) => item !== undefined)
                    };
                }
                return acc;
            }, {});

            role = trimObj(role);
            const payload = {
                ...role,
                permissions: permissionOrderedByGroups
            };


            if (this.props.isAdd) {


                const url = `/authorize/addRole`;
                this.serverlessService.post(url, payload, true).then(res => {

                    if (res && res.status) {

                        this.props.onRoleCreate(true, payload);
                    } else {

                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    console.log(e);

                })
            } else {

                const url = `/authorize/updateRole`;
                this.serverlessService.put(url, { ...payload }, true).then((res) => {
                    if (res && res.status) {
                        // this.toast.show({ severity: 'success', summary: 'Role Updated', detail: res.res.message, life: 3000 });
                        this.props.onRoleCreate(false);
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }


                }).catch(e => {

                });

            }
            //  this.props.createRole(payload);


            // this.setState({
            //     formValidations: formStatus.formValidations,
            // });
        }





        // if (Object.values(errorState).every((item) => item === false)) {

        // }
    };

    render() {
        const { role, formValidations } = this.state;
        const { permissionsMasterList } = this.props;
        return (<>

            <Dialog
                //visible={this.state.isShowCreate}
                style={{ width: '80%' }}
                header={this.props.isAdd ? "Create Role" : "Edit Role"}
                modal
                footer={this.createRoleDialogFooter}
                draggable={false}
                // footer={this.footer}
                blockScroll={true}
                closeOnEscape={false}
                dismissableMask={false}
                visible={true}
                closable={false}
                onHide={this.props.hideRoleCreateDialog}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid sp-box-style1">
                        <div className="p-col-12 p-md-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <p className='sp-label-s1'>Role Name<span className='sp-required'>*</span></p>
                                </div>
                                <div className="p-col-12 p-md-10">
                                    <InputText value={role.roleName} onChange={(e) => { this.onTextChange(e, 'roleName') }} className='p-inputtext-style1' />
                                    {formValidations && !formValidations.fields['roleName'].isValid && <p className="p-error">{formValidations.fields['roleName'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <p className='sp-label-s1'>Role Descripiton<span className='sp-required'>*</span></p>
                                </div>
                                <div className="p-col-12 p-md-10">
                                    <InputTextarea value={role.description} onChange={(e) => { this.onTextChange(e, 'description') }} className='p-inputtext-style1 sp-w60' rows={5} cols={30} />
                                    {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                                </div>
                            </div>

                        </div>
                    </div>
                    {permissionsMasterList && permissionsMasterList.length ? <>
                        <Accordion className='sp-m16'>
                            {
                                permissionsMasterList.map((item) => {
                                    return <AccordionTab id={item.id} key={item.id} header={item.groupName}>
                                        <PermissionsTab
                                            id={item.id}
                                            details={item.permissions}
                                            selectedPermissions={this.state.permissions}
                                            handleCheckBoxClick={this.handleCheckBoxClick}
                                        />
                                    </AccordionTab>
                                })
                            }

                        </Accordion>
                        {formValidations && !formValidations.fields['permissions'].isValid && <p className="p-error">{formValidations.fields['permissions'].errorMsg}</p>}

                    </> : <></>}


                    {/* <Toolbar className="sp-toolbar" right={() => { return (<Button label={`Save`} className='' onClick={this.onSubmitClick} />) }}></Toolbar> */}
                </div>
            </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>
        )
    }
}



const mapStateToProps = (state) => ({
    permissionsMasterList: selectPermissions(state),
    isLoading: state.permissions.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    getPermissions: () => dispatch(getPermissionList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);